import Grid from '@mui/material/Grid';
import { Flows } from 'api/generated/enums';
import { ViewTeamSharedReimbursementReports } from 'api/generated/permissions';
import LaunchDetailsCard from 'components/launchDetails/LaunchDetailsCard';
import useTeamProps from 'hooks/useTeamProps';
import {
    getShouldRenderTeamStatuses,
    isTeamAdvisorSelector,
} from 'pages/dashboard/dashboardSelectors';
import MemberContent from 'pages/dashboard/MemberContent';
import MostRecentPayrollReportCard from 'pages/dashboard/MostRecentPayrollReportCard';
import MostRecentReimbursementReportCardCopy from 'pages/dashboard/MostRecentReimbursementReportCard';
import MemberTermsFlowTaskList from 'pages/dashboard/taskLists/member/MemberTermsFlowTaskList';
import TeamAdminContent from 'pages/dashboard/TeamAdminContent';
import TeamManagementContent from 'pages/dashboard/TeamManagementContent';
import TeamStatuses from 'pages/dashboard/TeamStatuses';
import VerificationDocumentsNeeded from 'pages/dashboard/verificationDocuments/VerificationDocumentsNeeded';
import FavoriteTeamsWidget from 'pages/dashboard/widgets/FavoriteTeamsWidget';
import MyTeamWidget from 'pages/dashboard/widgets/MyTeamWidget';
import ReimbursementWidget from 'pages/dashboard/widgets/ReimbursementWidget';
import ResourcesWidget from 'pages/dashboard/widgets/ResourcesWidget';
import TeamMembersWidget from 'pages/dashboard/widgets/TeamMembersWidget';
import React from 'react';
import Alert from 'react-bootstrap/Alert';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasSomePermissions, ITeamProps } from 'selectors';
import {
    isMemberSelector,
    isRhSelector,
    isTeamAdminSelector,
    isTeamPayrollAdminSelector,
} from 'selectors/role';
import { hasValue } from 'utilities';
import { formatDateForDisplay } from 'utilities/format';
import { isTodayAfter } from 'utilities/moment';

const getDashboardContentSelector = (teamProps: ITeamProps) => (state: AppStore) => {
    const {
        hasTeamIdUrlParam,
        payrollReportSharingEnabled,
        useReimbursementProgramManagement,
    } = teamProps;
    const isTeamAdmin = isTeamAdminSelector(state);
    const isTeamPayrollAdmin = isTeamPayrollAdminSelector(state);
    const isLoggedInUsersDashboard = !hasTeamIdUrlParam;
    const isRh = isRhSelector(state);
    return {
        isTeamAdmin,
        favoriteTeams: state.favoriteTeams,
        shouldRenderFavoriteTeams: isRh && isLoggedInUsersDashboard,
        shouldRenderMemberContent: isLoggedInUsersDashboard && isMemberSelector(state),
        shouldRenderMemberTermsFlow:
            state.userFlows?.filter(
                (uf) => uf.flowId === Flows.SignUpdatedMemberTerms && uf.isVisible
            ).length > 0 &&
            isLoggedInUsersDashboard &&
            isMemberSelector(state),
        shouldRenderMostRecentPayrollReport:
            (isTeamAdmin || isTeamPayrollAdmin) && payrollReportSharingEnabled,
        shouldRenderMostRecentReimbursementReport:
            hasSomePermissions(state, ViewTeamSharedReimbursementReports) &&
            useReimbursementProgramManagement,
        shouldRenderResourcesWidget: (isTeamAdmin || isMemberSelector(state)) && !hasTeamIdUrlParam,
        shouldRenderTeamAdminContent: isLoggedInUsersDashboard && isTeamAdmin,
        shouldRenderTeamManagementContent:
            !isLoggedInUsersDashboard && (isRh || isTeamAdvisorSelector(state)),
        shouldRenderTeamStatuses: getShouldRenderTeamStatuses(state, teamProps),
    };
};

const DashboardContent = () => {
    const teamProps = useTeamProps();
    const { hasTeamIdUrlParam } = teamProps;
    const isLoggedInUsersDashboard = !hasTeamIdUrlParam;
    const {
        isTeamAdmin,
        favoriteTeams,
        shouldRenderFavoriteTeams,
        shouldRenderMemberContent,
        shouldRenderMemberTermsFlow,
        shouldRenderMostRecentPayrollReport,
        shouldRenderMostRecentReimbursementReport,
        shouldRenderResourcesWidget,
        shouldRenderTeamAdminContent,
        shouldRenderTeamManagementContent,
        shouldRenderTeamStatuses,
    } = useSelector(getDashboardContentSelector(teamProps));
    const { enrollmentDeadline } = teamProps;

    return (
        <Grid columnSpacing={4} container rowSpacing={3}>
            {hasValue(enrollmentDeadline) && !isTodayAfter(enrollmentDeadline, 'day') && (
                <Grid item xs={12}>
                    <Alert variant="info">
                        The deadline to make your benefits selection is{' '}
                        {formatDateForDisplay(enrollmentDeadline)}.
                    </Alert>
                </Grid>
            )}
            <Grid item xl={6} xs={12}>
                {shouldRenderMemberTermsFlow && <MemberTermsFlowTaskList />}
                {isLoggedInUsersDashboard && <VerificationDocumentsNeeded />}
                {shouldRenderTeamAdminContent && <TeamAdminContent />}
                {shouldRenderTeamManagementContent && <TeamManagementContent />}
                {shouldRenderMemberContent && <MemberContent />}
                {shouldRenderMostRecentPayrollReport && <MostRecentPayrollReportCard />}
                {shouldRenderMostRecentReimbursementReport && (
                    <MostRecentReimbursementReportCardCopy />
                )}
                {shouldRenderFavoriteTeams && favoriteTeams.length > 0 && <FavoriteTeamsWidget />}
                {shouldRenderTeamStatuses && <TeamStatuses />}
                {(isTeamAdmin || shouldRenderTeamManagementContent) && <TeamMembersWidget />}
            </Grid>
            <Grid item xl={6} xs={12}>
                <LaunchDetailsCard />
                {isTeamAdmin && <MyTeamWidget />}
                <ReimbursementWidget />
                {shouldRenderResourcesWidget && <ResourcesWidget />}
            </Grid>
        </Grid>
    );
};

export default hot(module)(DashboardContent);
