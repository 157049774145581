import Grid from '@mui/material/Grid';
import { TaskDto } from 'api/generated/models';
import UnderConstructionModal from 'components/underConstructionModal/UnderConstructionModal';
import { openUnderConstructionModal } from 'components/underConstructionModal/underConstructionAction';
import DesktopContext from 'contexts/DesktopContext';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import ShopContent from 'pages/shop/ShopContent';
import React, { useContext, useEffect } from 'react';
import { hot } from 'react-hot-loader';

const ShopForPlansPage = ({ currentTask }: { currentTask: TaskDto }) => {
    const dispatch = useThunkDispatch();
    const { user } = useUserProps();
    const taskId = currentTask.globalId;
    const isDesktop = useContext(DesktopContext);
    useEffect(() => {
        if (!isDesktop) {
            dispatch(openUnderConstructionModal());
        }
    }, [dispatch, isDesktop]);

    return (
        <Grid item margin={0} width="100%" xs={12}>
            <UnderConstructionModal />
            <ShopContent
                chunkPlan={currentTask.response}
                flowTaskId={taskId}
                isIchraFlow
                year={user?.activeDate?.getYear() as number}
            />
        </Grid>
    );
};

export default hot(module)(ShopForPlansPage);
