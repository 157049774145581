import {
    BinderStatuses,
    PayTypeIds,
    PlanStateIds,
    PlanTypeIds,
    ReimbursementDisplayLevels,
} from 'api/generated/enums';
import { ISelectedPlan } from 'api/generated/models';
import { ViewSelectedPlans } from 'api/generated/permissions';
import MoopReimbursementFooter from 'components/MoopReimbursementFooter';
import { HEALTHCARE_TYPE_NAMES } from 'constants/healthCareTypes';
import { ACA_MARKETPLACE_PLAN_TYPE_NAME, PLAN_TYPE_ITEMS, PREMIUM } from 'constants/selectedPlans';
import isUndefined from 'lodash/isUndefined';
import startCase from 'lodash/startCase';
import { ColumnRowNameValue, IColumnRowNameValue } from 'pages/benefits/ColumnRowNameValue';
import { buildCommonRhOrAdvisorFields } from 'pages/benefits/MediShareBenefitCardContent';
import React from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { isAdvisorSelector, isRhSelector } from 'selectors/role';
import { hasValue } from 'utilities';
import { formatCurrency, formatDateForDisplay, formatYesOrNo } from 'utilities/format';
import { shouldIncludePremiumWithCredits } from 'utilities/plans';

const YEAR_2019 = 2019;
const ALWAYS_SHOW_TERMINATION_DATE_PLANS = [
    PlanTypeIds.TermMedical,
    PlanTypeIds.CustomStateBasedExchange,
    PlanTypeIds.CustomOffExchange,
    PlanTypeIds.CustomMajorMedical,
    PlanTypeIds.Medicare,
    PlanTypeIds.CustomAncillary,
];
const RH_ADVISOR_SHOW_TERMINATION_DATE_PLANS = [
    PlanTypeIds.Marketplace,
    PlanTypeIds.MediShare,
    PlanTypeIds.Medicaid,
    PlanTypeIds.Medicare,
    PlanTypeIds.CHIP,
    PlanTypeIds.Ancillary,
    PlanTypeIds.VeteransAffairs,
    PlanTypeIds.OffExchange,
];

const getStringOrCurrency = (cost: number | string) =>
    Number.isInteger(cost as number) ? formatCurrency(cost) : cost;

const buildCost = (
    familyCost: number | undefined,
    individualCost: number | undefined,
    individualOnly: boolean
) => {
    if (individualOnly && individualCost) {
        return formatCurrency(individualCost, { emptyIfNaN: true });
    }
    if (familyCost && individualCost) {
        return `${formatCurrency(individualCost, {
            emptyIfNaN: true,
        })} per member, ${formatCurrency(familyCost, { emptyIfNaN: true })} per family`;
    }
    return formatCurrency(familyCost || individualCost, { emptyIfNaN: true });
};

const getTruePlanTypeName = (planType: PlanTypeIds): string => {
    if (planType === PlanTypeIds.Marketplace) {
        return ACA_MARKETPLACE_PLAN_TYPE_NAME;
    }
    return PLAN_TYPE_ITEMS.find((p) => p.value === planType)?.['name']?.toString() ?? '';
};

const buildLeftColumnFields = (plan: ISelectedPlan, isRh: boolean, isAdvisor: boolean) => {
    const {
        householdMembersCovered,
        isPrimaryCovered,
        familyDeductibleCost,
        familyMoopCost,
        genericDrugCost,
        individualDeductibleCost,
        individualMoopCost,
        isCustom,
        networkType,
        primaryCareCost,
        reimbursementOverlayDto,
        specialistCost,
        planTypeId,
        year,
    } = plan;
    const fields: IColumnRowNameValue[] = [];
    const numberOfHouseholdMembersCovered = householdMembersCovered?.length ?? 0;
    const individualOnly =
        (isPrimaryCovered && numberOfHouseholdMembersCovered === 0) ||
        (!isPrimaryCovered && numberOfHouseholdMembersCovered === 1);
    if (isRh || isAdvisor) {
        fields.push({
            'data-cy': 'plan-type',
            name: 'Plan Type',
            value: getTruePlanTypeName(planTypeId),
        });
    }
    if (
        !isCustom ||
        (isCustom &&
            [PlanTypeIds.Marketplace, PlanTypeIds.OffExchange].includes(planTypeId) &&
            year > YEAR_2019)
    ) {
        let moopFooter = <React.Fragment />;
        const showMoopFooter =
            hasValue(reimbursementOverlayDto) &&
            reimbursementOverlayDto.displayLevelId !== ReimbursementDisplayLevels.None;
        if (showMoopFooter) {
            moopFooter = (
                <MoopReimbursementFooter
                    familyMoopCost={familyMoopCost}
                    individualMoopCost={individualMoopCost}
                    planName={plan.planName}
                    reimbursementOverlayDto={reimbursementOverlayDto}
                />
            );
        }
        fields.push(
            {
                'data-cy': 'deductible',
                name: 'Deductible',
                value: buildCost(familyDeductibleCost, individualDeductibleCost, individualOnly),
            },
            {
                'data-cy': 'max-out-of-pocket',
                footer: moopFooter,
                name: 'Max Out of Pocket',
                strikeThrough: showMoopFooter,
                value: buildCost(familyMoopCost, individualMoopCost, individualOnly),
                valueClassName: 'd-inline-block',
            },
            { 'data-cy': 'network-type', name: 'Network Type', value: networkType },
            {
                'data-cy': 'primary-care',
                name: 'Primary Care',
                value: getStringOrCurrency(primaryCareCost ?? ''),
            },
            {
                'data-cy': 'specialist-visit',
                name: 'Specialist Visit',
                value: getStringOrCurrency(specialistCost ?? ''),
            },
            {
                'data-cy': 'generic-drugs',
                name: 'Generic Drugs',
                value: getStringOrCurrency(genericDrugCost ?? ''),
            }
        );
    }
    return fields;
};

const showEndDate = (
    coverageEndDate: string | undefined,
    planTypeId: PlanTypeIds,
    isRh: boolean,
    isAdvisor: boolean
) => {
    const canAlwaysSeeEndDate = isRh || isAdvisor;
    return (
        coverageEndDate &&
        (ALWAYS_SHOW_TERMINATION_DATE_PLANS.includes(planTypeId) ||
            (canAlwaysSeeEndDate && RH_ADVISOR_SHOW_TERMINATION_DATE_PLANS.includes(planTypeId)))
    );
};

const buildRightColumnFields = (
    {
        coverageEndDate,
        coverageStartDate,
        healthCareTypeId,
        isHsaEligible,
        memberId,
        planStateId,
        planTypeId,
    }: ISelectedPlan,
    isRh: boolean,
    isAdvisor: boolean
) => {
    const fields: IColumnRowNameValue[] = [];
    if (healthCareTypeId && planTypeId === PlanTypeIds.Ancillary) {
        fields.push({
            'data-cy': 'healthcare-type',
            name: 'Healthcare Type',
            value: HEALTHCARE_TYPE_NAMES[healthCareTypeId],
        });
    }
    if (!isUndefined(isHsaEligible)) {
        fields.push({
            name: 'Is HSA Eligible',
            value: formatYesOrNo(isHsaEligible),
        });
    }
    if (coverageStartDate) {
        fields.push({
            'data-cy': 'effective-date',
            name: 'Effective Date',
            value: formatDateForDisplay(coverageStartDate),
        });
    }
    if (showEndDate(coverageEndDate, planTypeId, isRh, isAdvisor)) {
        fields.push({
            'data-cy': 'termination-date',
            name: 'Termination Date',
            value: formatDateForDisplay(coverageEndDate),
        });
    }
    if ([PlanStateIds.Enrolled, PlanStateIds.Effective].includes(planStateId) && memberId) {
        fields.push({
            'data-cy': 'member-id',
            name: 'Member ID',
            value: memberId,
        });
    }
    return fields;
};

const buildRHFields = ({ binderStatus, isPassiveRenewal, payTypeId }: ISelectedPlan) => {
    const fields: IColumnRowNameValue[] = [];
    if (!isUndefined(binderStatus)) {
        fields.push({ name: 'Binder Status', value: startCase(BinderStatuses[binderStatus]) });
    }
    if (!isUndefined(payTypeId)) {
        fields.push({ name: 'Pay Type', value: startCase(PayTypeIds[payTypeId]) });
    }
    if (isPassiveRenewal) {
        fields.push({ name: 'Is Passive Renewal?', value: 'Yes' });
    }
    return fields;
};

const buildRHOrAdvisorFields = (plan: ISelectedPlan) => {
    const { exchange } = plan;
    const fields = [];
    if (!isUndefined(exchange)) {
        fields.push({ name: 'Exchange', value: `${exchange} Exchange` });
    }

    return fields.concat(buildCommonRhOrAdvisorFields(plan));
};

const buildViewSelectedPlansFields = ({ applicationDate }: ISelectedPlan) => {
    const fields: IColumnRowNameValue[] = [];
    if (!isUndefined(applicationDate)) {
        fields.push({
            name: 'Application Date',
            value: formatDateForDisplay(applicationDate),
        });
    }
    return fields;
};

const CommonBenefitCardContent = ({ benefit }: { benefit: ISelectedPlan }) => {
    const { canViewSelectedPlans, isAdvisor, isRh } = useSelector((state: AppStore) => ({
        canViewSelectedPlans: state.login.up.includes(ViewSelectedPlans),
        isAdvisor: isAdvisorSelector(state),
        isRh: isRhSelector(state),
    }));
    const leftColumn = buildLeftColumnFields(benefit, isRh, isAdvisor);

    const rightColumn = buildRightColumnFields(benefit, isRh, isAdvisor);

    if (isRh) {
        rightColumn.push(...buildRHFields(benefit));
    }
    if (isRh || isAdvisor) {
        rightColumn.push(...buildRHOrAdvisorFields(benefit));
    }
    if (canViewSelectedPlans) {
        rightColumn.push(...buildViewSelectedPlansFields(benefit));
    }
    if (hasValue(benefit.planPremium)) {
        rightColumn.push({
            name: PREMIUM,
            value: formatCurrency(benefit.planPremium, { preserveDecimal: true }),
        });
    }
    if (
        hasValue(benefit.planPremiumWithCredits) &&
        shouldIncludePremiumWithCredits(benefit.planTypeId)
    ) {
        rightColumn.push({
            name: 'Premium after Tax Credits',
            value: formatCurrency(benefit.planPremiumWithCredits, { preserveDecimal: true }),
        });
    }
    const data = [];
    let haveContent = false;
    if (leftColumn.length > 0) {
        data.push(leftColumn);
        haveContent = true;
    }
    if (rightColumn.length > 0) {
        data.push(rightColumn);
        haveContent = true;
    }
    return (
        <React.Fragment>
            <ColumnRowNameValue data={data} />
            {haveContent && <hr />}
        </React.Fragment>
    );
};

export default hot(module)(CommonBenefitCardContent);
