import Stack from '@mui/material/Stack';
import { ADD_PATHWAY_BLUEPRINT_ACTION } from 'actions/pathwayBlueprint/addPathwayBlueprint';
import { clearPathwayMarketplacePlanMigrations } from 'actions/pathwayBlueprint/clearPathwayMarketplacePlanMigrations';
import { EDIT_PATHWAY_BLUEPRINT_ACTION } from 'actions/pathwayBlueprint/editPathwayBlueprint';
import {
    GET_PATHWAY_BLUEPRINT_ACTION,
    getPathwayBlueprint,
} from 'actions/pathwayBlueprint/getPathwayBlueprint';
import {
    GET_PATHWAY_MARKETPLACE_PLAN_MIGRATIONS_ACTION,
    getPlansForNextYear,
} from 'actions/pathwayBlueprint/getPlansForNextYearAsync';
import Button from 'components/Button';
import CurrencyTextField from 'components/CurrencyTextField';
import Form from 'components/Form';
import Select from 'components/Select';
import TextField from 'components/TextField';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import {
    clearPathwayBlueprintModalState,
    savePathwayBlueprint,
    setPathwayBlueprintInput,
} from 'pages/pathwayBlueprints/pathwayBlueprintModalActions';
import PathwayMigrationsContent from 'pages/pathwayBlueprints/PathwayMigrationsContent';
import React, { useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { hasValue } from 'utilities';
import { getYears } from 'utilities/year';

type IPathwayBlueprintModalProps = {
    initialYear?: number;
    isDuplicate?: boolean;
    isUpdateLiveRates?: boolean;
    onClose: () => void;
    pathwayBlueprintId?: string;
};

const PathwayBlueprintModal = ({
    pathwayBlueprintId: initialPathwayBlueprintId,
    initialYear,
    isDuplicate,
    isUpdateLiveRates,
    onClose,
}: IPathwayBlueprintModalProps) => {
    const dispatch = useThunkDispatch();
    const {
        name,
        year,
        errors,
        isLoading,
        latestYearForRates,
        pathwayBlueprint,
        pathwayMarketplacePlanMigrations,
        wageUpFloor,
    } = useSelector((state: AppStore) => ({
        errors: state.pathwayBlueprintModalState.errors,
        isLoading: hasApiActivity(
            state,
            ADD_PATHWAY_BLUEPRINT_ACTION,
            EDIT_PATHWAY_BLUEPRINT_ACTION,
            GET_PATHWAY_BLUEPRINT_ACTION,
            GET_PATHWAY_MARKETPLACE_PLAN_MIGRATIONS_ACTION
        ),
        latestYearForRates: state.marketplaceConfig.latestYearForRates ?? 0,
        name: state.pathwayBlueprintModalState?.name ?? '',
        pathwayBlueprint: state.pathwayBlueprintModalState,
        pathwayMarketplacePlanMigrations: state.pathwayMarketplacePlanMigrations,
        wageUpFloor: state.pathwayBlueprintModalState?.wageUpFloor,
        year: state.pathwayBlueprintModalState?.year ?? 0,
    }));
    const { teamId } = useTeamProps();
    const [showPlanMigrationInputs, setShowPlanMigrationInputs] = useState(false);

    const isEdit = hasValue(initialPathwayBlueprintId);
    const isProjection =
        isUpdateLiveRates || !pathwayBlueprint?.isProjection
            ? Number(year) > latestYearForRates
            : pathwayBlueprint?.isProjection;
    const disableYearDropdown =
        isEdit && (pathwayBlueprint?.isActive || isDuplicate || isUpdateLiveRates);

    useEffect(() => {
        dispatch(clearPathwayBlueprintModalState());
        if (hasValue(initialPathwayBlueprintId)) {
            dispatch(getPathwayBlueprint(initialPathwayBlueprintId));
        }
        return () => {
            dispatch(clearPathwayBlueprintModalState());
            dispatch(clearPathwayMarketplacePlanMigrations());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    useEffect(() => {
        if (
            (isEdit &&
                hasValue(initialYear) &&
                initialYear + 1 === Number(year) &&
                !isProjection) ||
            isUpdateLiveRates
        ) {
            dispatch(getPlansForNextYear(initialPathwayBlueprintId));
            setShowPlanMigrationInputs(true);
        } else {
            setShowPlanMigrationInputs(false);
            dispatch(clearPathwayMarketplacePlanMigrations());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, isEdit, pathwayBlueprint.id, year, isProjection]);

    const years = useMemo(
        () =>
            isEdit
                ? [{ name: `${initialYear}` }, { name: `${(initialYear as number) + 1}` }]
                : getYears(() => 1).map((x) => ({ name: `${x}` })),
        [initialYear, isEdit]
    );

    const onChange: React.ChangeEventHandler<HTMLInputElement> = ({
        target: { name: propName, value },
    }) => dispatch(setPathwayBlueprintInput({ [propName]: value }));

    const onSubmit = async () => {
        const shouldClose = await dispatch(
            savePathwayBlueprint(teamId, isDuplicate, isEdit, isProjection)
        );
        if (shouldClose) {
            onClose();
        }
    };

    const getModalTitle = () => {
        if (isUpdateLiveRates) {
            return 'Update Pathway Blueprint to Live Rates';
        } else if (isDuplicate) {
            return 'Duplicate Pathway Blueprint';
        } else if (isEdit) {
            return 'Edit Pathway Blueprint';
        }
        return 'Add Pathway Blueprint';
    };

    return (
        <Modal onHide={onClose} scrollable show size={showPlanMigrationInputs ? 'lg' : undefined}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {getModalTitle()}
                    {isProjection ? <em> (Projection)</em> : ''}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form id="add-edit-pathway-blueprint-modal-form" onSubmit={onSubmit}>
                    <Stack gap={2}>
                        <TextField
                            autoFocus
                            data-cy="name"
                            errors={errors?.name}
                            label="Name"
                            name="name"
                            onChange={onChange}
                            placeholder="Enter name"
                            value={name}
                        />
                        <Select
                            data-cy="year"
                            defaultText="Choose a Year"
                            defaultValue=""
                            disabled={disableYearDropdown}
                            errors={errors?.year}
                            items={years}
                            label={
                                <span>
                                    Year
                                    {isProjection && <span className="text-danger">*</span>}
                                </span>
                            }
                            name="year"
                            onChange={onChange}
                            optionText="name"
                            optionValue="name"
                            value={year}
                        />
                        <CurrencyTextField
                            data-cy="wageUpFloor"
                            decimalScale={0}
                            errors={errors?.wageUpFloor}
                            label="Wage+ Floor"
                            name="wageUpFloor"
                            onChange={onChange}
                            placeholder="Enter Wage+ Floor"
                            value={wageUpFloor}
                        />
                        {isProjection && (
                            <span className="font-italic text-danger">
                                *Will use {(pathwayBlueprint.year as number) - 1} Marketplace rates
                            </span>
                        )}
                        {showPlanMigrationInputs && (
                            <PathwayMigrationsContent
                                migrations={pathwayMarketplacePlanMigrations}
                            />
                        )}
                    </Stack>
                </Form>
            </Modal.Body>
            <Modal.Footer className="centered">
                <Button onClick={onClose}>Cancel</Button>
                <Button
                    form="add-edit-pathway-blueprint-modal-form"
                    isLoading={isLoading}
                    type="submit"
                >
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default hot(module)(PathwayBlueprintModal);
