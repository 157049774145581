import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { PathwayInputTypes, PathwayTypes } from 'api/generated/enums';
import { IBasicMarketplacePlanDto, IPathwayMarketplacePlanMigration } from 'api/generated/models';
import IconTooltip from 'components/IconTooltip';
import Select from 'components/Select';
import TextField from 'components/TextField';
import { FEDERAL_EXCHANGE } from 'constants/selectedPlans';
import useThunkDispatch from 'hooks/useThunkDispatch';
import startCase from 'lodash/startCase';
import {
    addPathwayToPathwayBlueprintModalState,
    setPathwayBlueprintInput,
} from 'pages/pathwayBlueprints/pathwayBlueprintModalActions';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { getEnumNames, hasContents, hasValue } from 'utilities';
import { getPlansByCarrier, getUniqueMarketplaceCarriers } from 'utilities/marketplacePlan';

const PATHWAY_TYPE_NAMES = getEnumNames(PathwayTypes, (x) =>
    startCase(x.replace('Ichra', 'ICHRA'))
);

const CrosswalkIcon = ({
    crosswalkPlanName,
    planName,
    year,
    federalExchange,
}: {
    crosswalkPlanName: string | undefined;
    federalExchange: boolean;
    planName: string | undefined;
    year: number | undefined;
}) => {
    const crossWalkText = hasValue(crosswalkPlanName)
        ? `Crosswalk plan: ${crosswalkPlanName}.`
        : `${planName} does not have a crosswalk plan.`;
    return (
        <IconTooltip
            className="ml-1"
            icon="alert"
            prefix="mdi"
            title={
                <span>
                    {planName} is not available in {year}.
                    <br />
                    {federalExchange && crossWalkText}
                </span>
            }
            variant="danger"
        />
    );
};

const getPlanNameFromPlanId = (
    planId: string | undefined,
    plans: IBasicMarketplacePlanDto[] | undefined
) => (hasContents(plans) ? plans.find((x) => x.id === planId)?.name : '');

export const PathwayMarketplacePlanMigrationInputs = ({
    crosswalk,
    currentPlan,
    ichraClass,
    index,
    nextYearPlans,
    pathway,
}: IPathwayMarketplacePlanMigration & { index: number }) => {
    const dispatch = useThunkDispatch();

    const { year, errors, pathwayBlueprint } = useSelector((state: AppStore) => ({
        errors: state.pathwayBlueprintModalState.errors,
        pathwayBlueprint: state.pathwayBlueprintModalState,
        year: state.pathwayBlueprintModalState.year,
    }));

    const [marketplaceCarrier, setMarketplaceCarrier] = useState(currentPlan?.issuer?.name);
    const [marketplacePlanId, setMarketplacePlanId] = useState(crosswalk?.newPlanId);

    const showPickNewPlanTooltip = crosswalk?.isDiscontinued || crosswalk?.reason !== '0';

    const setPathwayInput = useCallback(
        (pathwayInputType: PathwayInputTypes, value: string | undefined) => {
            const pathwayInput = pathwayBlueprint?.pathways
                ?.find((p) => p.id === pathway?.id)
                ?.pathwayInputs?.find((x) => x.pathwayInputTypeId === pathwayInputType);
            if (hasValue(pathwayInput)) {
                pathwayInput.value = value;
            } else if (
                hasValue(ichraClass) &&
                hasValue(pathwayBlueprint.ichraClasses?.find((ic) => ic.id === ichraClass.id))
            ) {
                switch (pathwayInputType) {
                    case PathwayInputTypes.MarketplaceCarrier: {
                        const newIchraClasses = pathwayBlueprint.ichraClasses?.map((ic) => {
                            if (ic.id === ichraClass.id) {
                                const newClass = ic;
                                newClass.benchmarkCarrier = value;
                                return newClass;
                            }
                            return ic;
                        });
                        dispatch(
                            setPathwayBlueprintInput({
                                ...pathwayBlueprint,
                                ichraClasses: newIchraClasses,
                            })
                        );
                        break;
                    }
                    case PathwayInputTypes.MarketplacePlanId: {
                        const newIchraClasses = pathwayBlueprint.ichraClasses?.map((ic) => {
                            if (ic.id === ichraClass.id) {
                                const newClass = ic;
                                newClass.benchmarkPlanId = value;
                                return newClass;
                            }
                            return ic;
                        });
                        dispatch(
                            setPathwayBlueprintInput({
                                ...pathwayBlueprint,
                                ichraClasses: newIchraClasses,
                            })
                        );
                        break;
                    }
                    default:
                        break;
                }
            }
        },
        [dispatch, ichraClass, pathway?.id, pathwayBlueprint]
    );
    useEffect(() => {
        if (crosswalk?.newPlanId !== currentPlan?.id) {
            if (
                pathwayBlueprint.pathways?.some((p) => p.id === pathway?.id) ||
                hasValue(ichraClass)
            ) {
                setPathwayInput(PathwayInputTypes.MarketplacePlanId, crosswalk?.newPlanId);
                if (!hasValue(ichraClass)) {
                    dispatch(setPathwayBlueprintInput(pathwayBlueprint));
                }
            } else {
                const pathwayInput = pathway?.pathwayInputs?.find(
                    (x) => x.pathwayInputTypeId === PathwayInputTypes.MarketplacePlanId
                );
                if (hasValue(pathwayInput)) {
                    pathwayInput.value = crosswalk?.newPlanId;
                }
                if (!hasValue(ichraClass)) {
                    dispatch(addPathwayToPathwayBlueprintModalState(pathway));
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [crosswalk?.newPlanId, currentPlan?.id, dispatch, pathway]);

    const carriers = getUniqueMarketplaceCarriers(nextYearPlans);
    const plansByCarrier = useMemo(() => getPlansByCarrier(nextYearPlans, marketplaceCarrier), [
        marketplaceCarrier,
        nextYearPlans,
    ]);
    const crosswalkPlanName = useMemo(
        () => getPlanNameFromPlanId(crosswalk?.newPlanId, nextYearPlans),
        [crosswalk, nextYearPlans]
    );

    const onCarrierChange: React.ChangeEventHandler<HTMLInputElement> = ({ target: { value } }) => {
        setMarketplaceCarrier(value);
        setMarketplacePlanId('');
        setPathwayInput(PathwayInputTypes.MarketplaceCarrier, value);
        setPathwayInput(PathwayInputTypes.MarketplacePlanId, '');
        if (!hasValue(ichraClass)) {
            dispatch(setPathwayBlueprintInput(pathwayBlueprint));
        }
    };
    const onPlanIdChange: React.ChangeEventHandler<HTMLInputElement> = ({ target: { value } }) => {
        setMarketplacePlanId(value);
        setPathwayInput(PathwayInputTypes.MarketplacePlanId, value);
        if (!hasValue(ichraClass)) {
            dispatch(setPathwayBlueprintInput(pathwayBlueprint));
        }
    };

    const name = ichraClass?.name ?? (
        <Typography>
            {pathway?.name}
            {' - '}
            <span style={{ fontStyle: 'italic' }}>
                {PATHWAY_TYPE_NAMES?.[pathway?.pathwayTypeId as number]}
            </span>
        </Typography>
    );

    const planMigrationLabel = (
        <Typography
            color={(theme) => (showPickNewPlanTooltip ? theme.palette.error.main : '')}
            variant="h5"
        >
            {name}
            {showPickNewPlanTooltip && (
                <CrosswalkIcon
                    crosswalkPlanName={crosswalkPlanName}
                    federalExchange={currentPlan?.exchange === FEDERAL_EXCHANGE}
                    planName={currentPlan?.name}
                    year={year}
                />
            )}
        </Typography>
    );

    return (
        <Grid container direction="column" gap={2} mt={1.5}>
            {planMigrationLabel}
            <Grid container direction="row" item spacing={1}>
                <Grid item xs={6}>
                    <TextField
                        disabled
                        label={`${(year as number) - 1} Marketplace Carrier`}
                        select
                        value={currentPlan?.issuer?.name}
                    >
                        <option value={currentPlan?.issuer?.name}>
                            {currentPlan?.issuer?.name}
                        </option>
                    </TextField>
                </Grid>
                <Grid item xs={6}>
                    <Select
                        defaultText="Choose a Carrier"
                        defaultValue=""
                        errors={
                            errors?.[
                                (`${
                                    hasValue(ichraClass) ? 'ichraClassPlans' : 'plans'
                                }[${index}].marketplaceCarrier` as unknown) as keyof typeof errors
                            ]
                        }
                        items={carriers}
                        label={`${year} Marketplace Carrier`}
                        name={`plans[${index}].marketplaceCarrier`}
                        onChange={onCarrierChange}
                        optionText="name"
                        optionValue="name"
                        value={marketplaceCarrier}
                    />
                </Grid>
            </Grid>
            <Grid container direction="row" item spacing={1}>
                <Grid item xs={6}>
                    <TextField
                        disabled
                        label={`${(year as number) - 1} Benchmark Plan`}
                        select
                        value={currentPlan?.name}
                    >
                        <option value={currentPlan?.name}>{currentPlan?.name}</option>
                    </TextField>
                </Grid>
                <Grid item xs={6}>
                    <Select
                        defaultText="Choose a Plan"
                        defaultValue=""
                        errors={
                            errors?.[
                                (`${
                                    hasValue(ichraClass) ? 'ichraClassPlans' : 'plans'
                                }[${index}].planId` as unknown) as keyof typeof errors
                            ]
                        }
                        items={plansByCarrier}
                        label={`${year} Benchmark Plan`}
                        name={`plans[${index}].planId`}
                        onChange={onPlanIdChange}
                        optionText="name"
                        optionValue="id"
                        value={marketplacePlanId}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default hot(module)(PathwayMarketplacePlanMigrationInputs);
