import { LIST_VISIBLE_FLOWS_ACTION } from 'actions/taskFlows/listVisibleFlows';
import { Flows, TaskStatuses, Tasks } from 'api/generated/enums';
import Skeleton from 'components/Skeleton';
import { push } from 'connected-react-router';
import { taskListTypes } from 'constants/tasks';
import useThunkDispatch from 'hooks/useThunkDispatch';
import { getSupportedEnrollmentUserFlow } from 'pages/dashboard/ichraTaskFlow/ichraFlowUtilities';
import TaskListContainer from 'pages/dashboard/taskLists/TaskListContainer';
import TaskListItem from 'pages/dashboard/taskLists/TaskListItem';
import React, { useCallback } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router';
import { AppStore } from 'reducers/appReducer';
import { ICHRA_ENROLLMENT_FLOW_PATH } from 'routers/routes';
import { hasApiActivity } from 'selectors/activity';
import { enumToString, hasValue } from 'utilities/index';

export const ICHRA_LAUNCH_FLOW_INDEX = 0;
export const TASK_FLOW_ENUM_EXCEPTIONS = (isIchraLaunch: boolean) =>
    isIchraLaunch
        ? [
              {
                  name: 'Agree to Member Terms',
                  taskId: Tasks.MemberTerms,
              },
              {
                  name: 'Submit Benefits Selections',
                  taskId: Tasks.SubmitBenefitSelections,
              },
          ]
        : [
              {
                  name: 'Agree to Member Terms',
                  taskId: Tasks.MemberTerms,
              },
              {
                  name: 'Verify Household Info',
                  taskId: Tasks.EnterHouseholdInfo,
              },
              {
                  name: 'Submit Benefits Selections',
                  taskId: Tasks.SubmitBenefitSelections,
              },
          ];

type IIchraEnrollmentTaskList = {
    teamId: string;
    userId: string;
};

const IchraEnrollmentTaskList = ({ teamId, userId }: IIchraEnrollmentTaskList) => {
    const dispatch = useThunkDispatch();
    const { ichraFlow, ichraTasks, isLoading } = useSelector((state: AppStore) => ({
        ichraFlow: getSupportedEnrollmentUserFlow(state.userFlows)?.flowId,
        ichraTasks: getSupportedEnrollmentUserFlow(state.userFlows)?.tasks,
        isLoading: hasApiActivity(state, LIST_VISIBLE_FLOWS_ACTION),
    }));

    const navigateToIchraEnrollmentFlow = useCallback(
        (path) => {
            dispatch(push(generatePath(path, { userId })));
        },
        [dispatch, userId]
    );

    const parentOnlyTaskList = hasValue(ichraTasks)
        ? ichraTasks.filter((task) => task.taskStatusId !== TaskStatuses.Skipped)
        : [];

    const indexOfNextTask = parentOnlyTaskList.findIndex((task) =>
        [TaskStatuses.Incomplete, TaskStatuses.Acknowledged].includes(task.taskStatusId)
    );
    const getButtonText = (taskStatusId: number) => {
        if ([TaskStatuses.Completed, TaskStatuses.Overridden].includes(taskStatusId)) {
            return 'View';
        } else if (taskStatusId === TaskStatuses.Acknowledged) {
            return 'Continue';
        } else {
            return 'Start';
        }
    };

    return (
        <Skeleton
            isEnabled={isLoading}
            rowProps={{
                alignItems: 'center',
                display: 'flex',
                gap: 3,
                justifyContent: 'center',
            }}
            structure={[
                [
                    {
                        columnProps: { flex: 1 },
                        count: 1,
                        height: 527,
                        sx: { mb: 2 },
                        width: '100%',
                    },
                ],
            ]}
        >
            <TaskListContainer
                completeMessage="You've completed your ICHRA Enrollment Flow."
                description="Let's get started..."
                taskListType={taskListTypes.User}
                teamId={teamId}
                title="Enrollment Tasks"
            >
                {parentOnlyTaskList.map((task, index) => (
                    <TaskListItem
                        action={() => navigateToIchraEnrollmentFlow(ICHRA_ENROLLMENT_FLOW_PATH)}
                        buttonText={getButtonText(task.taskStatusId)}
                        complete={[TaskStatuses.Completed, TaskStatuses.Overridden].contains(
                            task.taskStatusId
                        )}
                        enabled={
                            index <= indexOfNextTask &&
                            [TaskStatuses.Incomplete, TaskStatuses.Acknowledged].includes(
                                task.taskStatusId
                            )
                        }
                        key={task.taskId}
                        showButton={
                            index <= indexOfNextTask &&
                            [TaskStatuses.Incomplete, TaskStatuses.Acknowledged].includes(
                                task.taskStatusId
                            )
                        }
                        showButtonWhenComplete
                        title={
                            enumToString(
                                Tasks,
                                task.taskId,
                                TASK_FLOW_ENUM_EXCEPTIONS(ichraFlow === Flows.IchraLaunch)
                            ) as string
                        }
                    />
                ))}
            </TaskListContainer>
        </Skeleton>
    );
};

export default hot(module)(IchraEnrollmentTaskList);
