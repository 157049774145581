import { Grid } from '@mui/material';
import { completeTask } from 'actions/taskFlows/completeTask';
import { LIST_VISIBLE_FLOWS_ACTION } from 'actions/taskFlows/listVisibleFlows';
import {
    LIST_ANCILLARY_BENEFITS_ACTION,
    listAncillaryBenefits,
} from 'actions/teamBenefit/listAncillaryBenefits';
import { GET_USER_PROFILE_ACTION } from 'actions/user/getUserProfile';
import { TeamBenefitTypes, UserTeamBenefitTermDetailStatuses } from 'api/generated/enums';
import {
    IAncillaryBenefitsDto,
    ISelectAncillaryBenefitRequest,
    SelectAncillaryBenefitRequest,
    TaskDto,
} from 'api/generated/models';
import Form from 'components/Form';
import Skeleton from 'components/Skeleton';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import { getHouseholdMemberDataForAncillarySelection } from 'pages/dashboard/ancillaryBenefitSelection/AncillaryDisplayItem';
import {
    ANCILLARY_TYPES,
    areAllSelectionsMade,
    descriptionText,
    getAncillaryDisplayItems,
} from 'pages/dashboard/ancillaryBenefitSelection/AncillarySelectionUtilities';
import {
    SLIM_MAX_WIDTH,
    handleAncillaryValues,
    ichraFlowSkeletonRowProps,
} from 'pages/dashboard/ichraTaskFlow/ichraFlowUtilities';
import IchraFlowButtons from 'pages/dashboard/ichraTaskFlow/taskFlowPages/components/IchraFlowButtons';
import IchraFlowTitle from 'pages/dashboard/ichraTaskFlow/taskFlowPages/components/IchraFlowTitle';
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { arrayHasValue, hasValue } from 'utilities/index';

const SelectAncillaryBenefitsPage = ({ currentTask }: { currentTask: TaskDto }) => {
    const dispatch = useThunkDispatch();
    const { userId } = useUserProps();
    const { ancillariesFromState, householdMembers, isLoading } = useSelector(
        (state: AppStore) => ({
            ancillariesFromState: state.ancillaryBenefits,
            householdMembers: state.householdMembers,
            isLoading: hasApiActivity(
                state,
                GET_USER_PROFILE_ACTION,
                LIST_VISIBLE_FLOWS_ACTION,
                LIST_ANCILLARY_BENEFITS_ACTION
            ),
        })
    );
    const isReadonly = false; // This is necessary to work with existing ancillary logic
    const [ancillaries, setAncillaries] = useState(ancillariesFromState);
    const [ancillaryDecisions, setAncillaryDecisions] = useState<
        ISelectAncillaryBenefitRequest[]
    >();
    const [canSave, setCanSave] = useState(
        (hasValue(currentTask) && arrayHasValue(currentTask.response)) ||
            areAllSelectionsMade(ancillaryDecisions as ISelectAncillaryBenefitRequest[])
    );

    const handleAncillaryDecisionsChange = (
        ancillaryDecisionsToChange: ISelectAncillaryBenefitRequest[],
        ancillaryId: string,
        type: TeamBenefitTypes,
        newStatus: UserTeamBenefitTermDetailStatuses
    ) => {
        const newAncillaryDecisions = ancillaryDecisionsToChange.map((a) => {
            if (
                newStatus !== UserTeamBenefitTermDetailStatuses.Waived &&
                a.userTermDetailId !== ancillaryId &&
                a.teamBenefitType === type
            ) {
                return {
                    ...a,
                    statusId: UserTeamBenefitTermDetailStatuses.Waived,
                };
            } else {
                return a;
            }
        });
        setCanSave(areAllSelectionsMade(ancillaryDecisionsToChange));
        setAncillaryDecisions(newAncillaryDecisions);
    };

    const getAncillaryDisplayItemsProps = {
        ancillaries,
        handleAncillaryDecisionsChange,
        isReadonly,
        ancillaryDecisions: ancillaryDecisions as ISelectAncillaryBenefitRequest[],
    };

    const handleSubmit = async () => {
        const ancillaryBenefits = ancillaryDecisions?.map(
            (ancillaryDecision) => new SelectAncillaryBenefitRequest(ancillaryDecision)
        );
        const ancillaryDecisionsToSend: IAncillaryBenefitsDto = { ancillaryBenefits };

        await dispatch(completeTask(currentTask.globalId, ancillaryDecisionsToSend));
    };

    useEffect(() => {
        dispatch(listAncillaryBenefits(userId, true));
    }, [dispatch, userId]);

    useEffect(() => {
        let ancillaryDecisionsToAdd: ISelectAncillaryBenefitRequest[] = [];
        if (hasValue(ancillaries)) {
            ancillaryDecisionsToAdd = ancillaries.map((ancillary) => {
                const {
                    initiallyCoveredForOfferLevelIds,
                } = getHouseholdMemberDataForAncillarySelection(householdMembers, ancillary);
                return {
                    householdMemberIds: initiallyCoveredForOfferLevelIds,
                    statusId: ancillary.statusId,
                    teamBenefitType: ancillary.teamBenefitTypeId,
                    userTermDetailId: ancillary.userTermDetailId,
                };
            }) as ISelectAncillaryBenefitRequest[];
        }

        setAncillaryDecisions(ancillaryDecisionsToAdd);
    }, [ancillaries, householdMembers]);

    useEffect(() => {
        const updatedAncillaries = handleAncillaryValues(currentTask, ancillariesFromState, userId);
        setAncillaries(updatedAncillaries);
    }, [ancillariesFromState, currentTask, userId]);

    return (
        <Grid container direction="column" maxWidth={SLIM_MAX_WIDTH} width="100%">
            <IchraFlowTitle title="Select Your Ancillary Benefits" />
            {descriptionText(isReadonly)}
            <Skeleton
                isEnabled={isLoading}
                rowProps={ichraFlowSkeletonRowProps}
                structure={[
                    [
                        {
                            columnProps: { flex: 1 },
                            count: 2,
                            height: 250,
                            sx: { mb: 3 },
                        },
                    ],
                ]}
            >
                <Form>
                    <Grid item>
                        {ANCILLARY_TYPES.map((type) =>
                            getAncillaryDisplayItems(getAncillaryDisplayItemsProps, type)
                        )}
                    </Grid>
                </Form>
            </Skeleton>
            <Grid item>
                <IchraFlowButtons
                    currentTaskId={currentTask.globalId}
                    handleNext={handleSubmit}
                    isDisabled={!canSave}
                    isLoading={isLoading}
                    showPreviousButton
                />
            </Grid>
        </Grid>
    );
};

export default hot(module)(SelectAncillaryBenefitsPage);
