import { getPathwayBlueprintDataForUser } from 'actions/pathwayBlueprint/getPathwayBlueprintDataForUser';
import { getTaxData } from 'actions/taxData/getTaxData';
import { getTeamAdvisors } from 'actions/team/getTeamAdvisor';
import { addOrEditUserAddress } from 'actions/user/addOrEditUserAddress';
import { deleteUserMailingAddress } from 'actions/user/deleteUserMailingAddress';
import { deleteUserRelationship } from 'actions/user/deleteUserRelationship';
import { getTeamRepresentativeOptions } from 'actions/user/getTeamRepresentativeOptions';
import { PATCH_USER_ACTION } from 'actions/user/patchUser';
import { setUserRelationship } from 'actions/user/setUserRelationship';
import {
    AddressTypes,
    AdpIntegrationStatuses,
    EnrollmentStatuses,
    RenewalDecisions,
    ShoppingConfigurationIds,
    SurveyTypes,
    TaskItems,
    TeamStateIds,
    UserRelationshipTypeIds,
    UserStatus,
} from 'api/generated/enums';
import {
    IAddress,
    IBaseUser,
    IMemberQuoteInfo,
    IMemberVerifiedInfo,
    IUser,
    YearlyUserInfoDto,
} from 'api/generated/models';
import {
    EditSubmittedExpense,
    EditSurveyTypeToSend,
    EditUser,
    EditUserStatus,
    EditUserVerifiedInfo,
    ResetAccountLockout,
    SetPayrollSystemDeduction,
    ViewHrsAssociateId,
    ViewPathwayBlueprints,
    ViewPayrollSystemId,
    ViewSurveyTypeToSend,
    ViewTaxData,
    ViewUsers,
} from 'api/generated/permissions';
import ConfirmationModal from 'components/ConfirmationModal';
import { IEditableAttributeFormatter, IValueType } from 'components/EditableAttribute';
import EditableDateAttribute from 'components/EditableDateAttribute';
import EditablePhoneNumberAttribute from 'components/EditablePhoneNumberAttribute';
import EditableSelectAttribute from 'components/EditableSelectAttribute';
import EditableTextAttribute from 'components/EditableTextAttribute';
import { ISaveEditableTextField } from 'components/EditableTextField';
import EditableYesNoSelectAttribute from 'components/EditableYesNoSelectAttribute';
import PageHeader from 'components/PageHeader';
import ProfileAttribute from 'components/ProfileAttribute';
import SubtextAttribute from 'components/SubtextAttribute';
import TextAreaModal from 'components/TextAreaModal';
import {
    ICHRA_SHOPPING_CONFIGURATION_ITEMS,
    WAGE_PLUS_SHOPPING_CONFIGURATION_ITEMS,
    shoppingConfigurationIdNames,
} from 'constants/shoppingConfigurations';
import useContributionProps from 'hooks/useContributionProps';
import useModalState from 'hooks/useModalState';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import sortBy from 'lodash/sortBy';
import startCase from 'lodash/startCase';
import moment from 'moment';
import HrsIntegrationStatus from 'pages/profile/HrsIntegrationStatus';
import AncillarySelectionStatus from 'pages/profile/AncillarySelectionStatus';
import BasicVerifiedInfo from 'pages/profile/BasicVerifiedInfo';
import EditableAddressAttribute from 'pages/profile/EditableAddressAttribute';
import EditableProfileAttribute from 'pages/profile/EditableProfileAttribute';
import { ICommonProps, ISaveYearlyUserInfo } from 'pages/profile/ProfilePage';
import SpouseDateOfBirth from 'pages/profile/SpouseDateOfBirth';
import { wagePlusShoppingConfigurationValidationSchema } from 'pages/teamProfile/SecondSection';
import React, { useCallback, useEffect, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { IUserProps, hasSomePermissions } from 'selectors';
import { hasApiActivity } from 'selectors/activity';
import {
    isAdvisorSelector,
    isRhSelector,
    isTeamAdminSelector,
    isTeamPayrollAdminSelector,
} from 'selectors/role';
import { enumToNameValueArray, flagsToValuesArray, hasValue, nameof } from 'utilities';
import {
    formatCoverageLevel,
    formatDateAtTime,
    formatDateForDisplay,
    formatUserOnDateAtTime,
    formatYesOrNo,
} from 'utilities/format';
import { onChange } from 'utilities/forms';
import { isAnyWageUpPathwayType, isIchraPathwayType } from 'utilities/pathways';
import { getYears } from 'utilities/year';
import { array, string } from 'yup';

const MAX_FOLLOW_UP_NOTE_CHARS = 500;
const renewalDecisionsFormatter = (x: string | undefined) => {
    if (
        x?.toLowerCase() === RenewalDecisions[RenewalDecisions.ChoseNonPathwayPlan]?.toLowerCase()
    ) {
        return 'Chose Non-Pathway Plan';
    }
    return startCase(x);
};

const surveyTypeFormatter = (x: string | undefined) => startCase(x);

const RENEWAL_DECISION_ITEMS = enumToNameValueArray(RenewalDecisions, {
    formatName: renewalDecisionsFormatter,
    nameKey: 'text',
});

const SURVEY_TYPE_OPTIONS = (enumToNameValueArray(SurveyTypes, {
    formatName: surveyTypeFormatter,
    nameKey: 'label',
}) as unknown) as { label: string; value: SurveyTypes }[];

const formatSentByName = (name: string | undefined) => (name ? `by ${name}` : '');

const getStatusSubtext = (
    canViewSubtext: boolean,
    user: IBaseUser | IUser | undefined,
    date: string | undefined
) => {
    if (!canViewSubtext || !hasValue(user) || !hasValue(date)) {
        return '';
    }

    return `by ${formatUserOnDateAtTime(user, date)}`;
};

const statusFormatter = <
    T extends {
        value: K;
        [x: string]: K | string;
    },
    K extends number
>(
    options: T[]
): IEditableAttributeFormatter => (x) =>
    (options.find((m) => m.value.toString() === x?.toString())?.['name'] as string) ?? '';

const basicInfoSelector = (
    user: IUser | undefined,
    { userRelationships, userProfile }: IUserProps
) => (state: AppStore) => {
    const selectedYear = +state.profileState.selectedYear;
    const advisorRelationship = userRelationships?.find(
        (x) =>
            x.year === selectedYear && x.userRelationshipTypeId === UserRelationshipTypeIds.Advisor
    );
    const isRh = isRhSelector(state);
    const isRhOrAdvisor = isRh || isAdvisorSelector(state);
    let teamAdvisors: IUser[] = [];
    if (isRhOrAdvisor) {
        teamAdvisors = sortBy(state.teamAdvisors, (a) => a.lastName);
    } else if (advisorRelationship?.representative) {
        teamAdvisors = [advisorRelationship.representative];
    }
    const rhMembersAndAdvisors: IUser[] = [];
    rhMembersAndAdvisors.push(...teamAdvisors);
    state.teamRepresentativeOptions.forEach((option) => {
        if (!rhMembersAndAdvisors.some((x) => x.userId === option.userId)) {
            rhMembersAndAdvisors.push({ displayName: option.name, userId: option.userId } as IUser);
        }
    });
    rhMembersAndAdvisors.sort((a, b) => a.displayName?.localeCompare(b.displayName ?? '') ?? 0);
    const isSameUser = user?.userId === state.login.userId;
    const isLockedOut = userProfile?.isLockedOut;
    const ancillaryTaskId =
        user?.status === UserStatus.Launching
            ? TaskItems.AncillaryBenefitsSelection
            : TaskItems.AncillaryBenefitsSelection_Renewing;
    return {
        advisorRelationship,
        isLockedOut,
        isRh,
        isRhOrAdvisor,
        rhMembersAndAdvisors,
        selectedYear,
        teamAdvisors,
        activePathwayType: state.userPathwayBlueprint.pathwayUserDto?.pathwayType,
        canEditAdvisor: isRhOrAdvisor,
        canEditIsMinister: !isSameUser && isRhOrAdvisor,
        canEditStatus: hasSomePermissions(state, EditUserStatus),
        canEditSubmittedExpenses: hasSomePermissions(state, EditSubmittedExpense),
        canEditSurveyTypeToSend: hasSomePermissions(state, EditSurveyTypeToSend),
        canEditUser: hasSomePermissions(state, EditUser),
        canEditVerifiedInfo: !isSameUser && hasSomePermissions(state, EditUserVerifiedInfo),
        canEditVip:
            !isSameUser &&
            (isRhOrAdvisor || isTeamAdminSelector(state) || isTeamPayrollAdminSelector(state)),
        canSetPayrollSystemDeduction: hasSomePermissions(state, SetPayrollSystemDeduction),
        canViewAdvisementCompleted:
            isRhOrAdvisor || isTeamAdminSelector(state) || isTeamPayrollAdminSelector(state),
        canViewHrsAssociateOid: hasSomePermissions(state, ViewHrsAssociateId),
        canViewPathwayBlueprints: hasSomePermissions(state, ViewPathwayBlueprints),
        canViewPayrollSystemId: hasSomePermissions(state, ViewPayrollSystemId),
        canViewSurveyTypeToSend: hasSomePermissions(state, ViewSurveyTypeToSend),
        canViewTaxData: hasSomePermissions(state, ViewTaxData),
        canViewUsers: hasSomePermissions(state, ViewUsers),
        groupLevels: state.options.groupLevels,
        hasAncillaryBenefits: state.ancillaryBenefits.length > 0,
        hasCompletedAncillaryTask: state.userTasks.completedTasks.some(
            (x) => x.taskItemId === ancillaryTaskId
        ),
        hasLoginInfo: userProfile?.hasLoginInfo,
        isPatchingUser: hasApiActivity(state, PATCH_USER_ACTION),
        loginCreatedDate: userProfile?.loginCreatedDate,
        showAccountLockout: hasSomePermissions(state, ResetAccountLockout) && isLockedOut,
    };
};

type IBasicInfoProps = {
    basicSave: ISaveEditableTextField<IUser>;
    canViewVerifiedInfo: boolean;
    hasMemberQuoteInfo: boolean | undefined;
    hasMemberVerifiedInfo: boolean | undefined;
    isCurrent: boolean;
    memberQuoteInfo: IMemberQuoteInfo | undefined;
    memberVerifiedInfo: IMemberVerifiedInfo | undefined;
    quoteInfoCommonProps: ICommonProps<IMemberQuoteInfo>;
    saveYearlyUserInfo: ISaveYearlyUserInfo;
    user: IUser | undefined;
    verifiedInfoCommonProps: ICommonProps<IMemberVerifiedInfo>;
};
const MAX_SUFFIX_LENGTH = 10;
const BasicInfo = ({
    basicSave,
    canViewVerifiedInfo,
    hasMemberQuoteInfo,
    hasMemberVerifiedInfo,
    isCurrent,
    memberQuoteInfo,
    memberVerifiedInfo,
    quoteInfoCommonProps,
    saveYearlyUserInfo,
    user,
    verifiedInfoCommonProps,
}: IBasicInfoProps) => {
    const dispatch = useThunkDispatch();
    const { userId } = user ?? {};
    const { team, teamId, teamStateId } = useTeamProps();
    const isInProspect = teamStateId === TeamStateIds.Prospect;
    const userProps = useUserProps();
    const { hasAnyContributionInfo } = useContributionProps();
    const { address, mailingAddress, userProfile, yearlyUserInfo } = userProps;
    const {
        activePathwayType,
        advisorRelationship,
        canEditAdvisor,
        canEditIsMinister,
        canEditStatus,
        canEditSubmittedExpenses,
        canEditSurveyTypeToSend,
        canEditVerifiedInfo,
        canEditVip,
        canSetPayrollSystemDeduction,
        canViewHrsAssociateOid,
        canViewAdvisementCompleted,
        canViewPayrollSystemId,
        canViewSurveyTypeToSend,
        canViewTaxData,
        canViewUsers,
        groupLevels,
        hasAncillaryBenefits,
        hasCompletedAncillaryTask,
        hasLoginInfo,
        isLockedOut,
        isPatchingUser,
        isRh,
        isRhOrAdvisor,
        loginCreatedDate,
        rhMembersAndAdvisors,
        selectedYear,
        showAccountLockout,
        teamAdvisors,
    } = useSelector(basicInfoSelector(user, userProps));
    useEffect(() => {
        if (canEditAdvisor) {
            dispatch(getTeamAdvisors(teamId));
            dispatch(getTeamRepresentativeOptions(teamId));
        }
    }, [dispatch, canEditAdvisor, teamId]);
    const [email, setEmail] = useState(user?.email);
    const [firstName, setFirstName] = useState(user?.firstName);
    const [middleName, setMiddleName] = useState(user?.middleName);
    const [lastName, setLastName] = useState(user?.lastName);
    const [preferredName, setPreferredName] = useState(user?.preferredName);
    const [suffix, setSuffix] = useState(user?.suffix);
    const [activeDate, setActiveDate] = useState(formatDateForDisplay(user?.activeDate));
    const [previousActiveDate, setPreviousActiveDate] = useState(
        formatDateForDisplay(user?.activeDate)
    );
    const [terminationDate, setTerminationDate] = useState(
        formatDateForDisplay(user?.terminationDate)
    );
    const [advisorUserId, setAdvisorUserId] = useState(
        advisorRelationship?.representative?.userId?.toString()
    );
    const [renewalDecision, setRenewalDecision] = useState(
        yearlyUserInfo?.renewalDecision?.toString()
    );
    const [acceptedPathway, setAcceptedPathway] = useState(
        yearlyUserInfo?.acceptedPathway?.toString() || ''
    );
    const [allowRecurringExpenses, setAllowRecurringExpenses] = useState(
        yearlyUserInfo?.allowRecurringExpenses.toString()
    );
    const [allowShopping, setAllowShopping] = useState(yearlyUserInfo?.allowShopping?.toString());
    const [
        wagePlusShoppingConfigurationOverride,
        setWagePlusShoppingConfigurationOverride,
    ] = useState<ShoppingConfigurationIds[]>(
        flagsToValuesArray(
            user?.wagePlusShoppingConfigurationOverride,
            shoppingConfigurationIdNames,
            'value'
        ).map((x) => x['value'] as ShoppingConfigurationIds)
    );
    const [ichraShoppingConfigurationOverride, setIchraShoppingConfigurationOverride] = useState<
        ShoppingConfigurationIds[]
    >(
        flagsToValuesArray(
            user?.ichraShoppingConfigurationOverride,
            shoppingConfigurationIdNames,
            'value'
        ).map((x) => x['value'] as ShoppingConfigurationIds)
    );
    const [phone, setPhone] = useState(user?.phone);
    const [jobTitle, setJobTitle] = useState(user?.jobTitle?.toString());
    const [dateOfBirth, setDateOfBirth] = useState(formatDateForDisplay(user?.dateOfBirth));
    const [hireDate, setHireDate] = useState(formatDateForDisplay(user?.hireDate));
    const [payrollSystemId, setPayrollSystemId] = useState(user?.payrollSystemId);
    const [isAdpSetWithholdingAndWageUp, setIsAdpSetWithholdingAndWageUp] = useState(
        user?.isAdpSetWithholdingAndWageUp.toString() ?? ''
    );
    const [isVip, setIsVip] = useState(user?.isVip?.toString() || '');
    const [isMinister, setIsMinister] = useState(yearlyUserInfo?.isMinister?.toString());
    const initialAdvisementCompleted =
        userProfile?.yearlyUserInfo?.advisementCompleted?.value?.toString() || '';
    const [advisementCompleted, setAdvisementCompleted] = useState(initialAdvisementCompleted);
    const [memberStatus, setMemberStatus] = useState(user?.status);
    const initialEnrollmentStatus = userProfile?.yearlyUserInfo?.enrollmentStatus?.value;
    const [enrollmentStatus, setEnrollmentStatus] = useState(initialEnrollmentStatus);
    const [finalReviewCompleted, setFinalReviewCompleted] = useState(
        userProfile?.yearlyUserInfo?.finalReviewCompleted?.value?.toString() || ''
    );
    const [originalStartDate, setOriginalStartDate] = useState(
        formatDateForDisplay(user?.originalStartDate)
    );
    const [needsFollowUp, setNeedsFollowUp] = useState(user?.needsFollowUp?.toString() || '');
    const [applicationSubmittedBy, setApplicationSubmittedBy] = useState(
        yearlyUserInfo?.applicationSubmittedBy
    );
    const [usePremiumTaxCredits, setUsePremiumTaxCredits] = useState(
        yearlyUserInfo?.usePremiumTaxCredits.toString()
    );
    const [previousGroupLevelId, setPreviousGroupLevelId] = useState(
        user?.previousGroupLevelId.toString()
    );
    const [surveyTypeToSend, setSurveyTypeToSend] = useState(user?.surveyTypeToSend);

    const {
        openModal: openFollowUpNoteModal,
        closeModal: closeFollowUpNoteModal,
        isVisible: isFollowUpNoteModalVisible,
    } = useModalState();
    const {
        openModal: openActiveDateModal,
        closeModal: closeActiveDateModal,
        isVisible: isActiveDateModalVisible,
    } = useModalState();

    useEffect(() => {
        setMemberStatus(user?.status);
    }, [user?.status]);

    useEffect(() => {
        setEnrollmentStatus(initialEnrollmentStatus);
    }, [initialEnrollmentStatus]);

    useEffect(() => {
        setAdvisementCompleted(initialAdvisementCompleted);
    }, [initialAdvisementCompleted]);

    useEffect(() => {
        if (hasValue(user?.terminationDate)) {
            setTerminationDate(formatDateForDisplay(user?.terminationDate));
        }
    }, [user?.terminationDate]);
    useEffect(() => {
        setAllowRecurringExpenses(yearlyUserInfo?.allowRecurringExpenses.toString());
    }, [yearlyUserInfo?.allowRecurringExpenses]);
    const readonly = hasMemberQuoteInfo && quoteInfoCommonProps.readonly;
    const getAdvisorName: IEditableAttributeFormatter = useCallback(
        (advisorId) => teamAdvisors.find((u) => u.userId === advisorId)?.displayName,
        [teamAdvisors]
    );
    const getRhMembersAndAdvisorsName: IEditableAttributeFormatter = useCallback(
        (rhMemberOrAdvisorId) =>
            rhMembersAndAdvisors.find((u) => u.userId === rhMemberOrAdvisorId)?.displayName,
        [rhMembersAndAdvisors]
    );
    const commonProps = useCallback(
        <T,>(setOnChange: React.Dispatch<React.SetStateAction<T>>) => ({
            readonly,
            onChange: onChange(setOnChange),
            save: basicSave,
        }),
        [basicSave, readonly]
    );
    const saveAddress = useCallback(
        async (addressToSave: IAddress, type: AddressTypes) => {
            await dispatch(
                addOrEditUserAddress(userId, +selectedYear, type, isCurrent, addressToSave)
            );
            if (hasAnyContributionInfo) {
                await dispatch(getPathwayBlueprintDataForUser(userId, selectedYear));
            }
            if (type === AddressTypes.Home && canViewTaxData) {
                dispatch(getTaxData(userId, selectedYear));
            }
        },
        [canViewTaxData, dispatch, hasAnyContributionInfo, isCurrent, selectedYear, userId]
    );
    const deleteAddress = useCallback(
        async (addressId: string) => {
            await dispatch(deleteUserMailingAddress(addressId, userId));
        },
        [dispatch, userId]
    );
    const saveAdvisor = useCallback(
        async (_name, value) => {
            if (!value) {
                await dispatch(deleteUserRelationship(advisorRelationship?.id, isCurrent));
            } else {
                await dispatch(
                    setUserRelationship(
                        {
                            userId,
                            id: advisorRelationship?.id,
                            representativeId: value,
                            userRelationshipTypeId: UserRelationshipTypeIds.Advisor,
                            year: selectedYear,
                        },
                        isCurrent,
                        {
                            toastErrorMessage: 'Could not update advisor',
                            toastSuccessMessage: 'Successfully updated advisor',
                        }
                    )
                );
            }
        },
        [advisorRelationship, dispatch, isCurrent, selectedYear, userId]
    );

    const saveMemberStatus = useCallback(
        async (name, value) => {
            await basicSave(name, value);
            if (hasAnyContributionInfo) {
                await dispatch(getPathwayBlueprintDataForUser(userId, selectedYear));
            }
        },
        [basicSave, dispatch, hasAnyContributionInfo, selectedYear, userId]
    );

    const saveSurveyType = useCallback(
        async (name, value) => {
            await basicSave(name, value);
        },
        [basicSave]
    );

    const handleSetEnrollmentStatus = (enrollmentStatus: EnrollmentStatuses) => {
        if (
            !(
                [EnrollmentStatuses.PendingApplication, EnrollmentStatuses.Enrolled].includes(
                    Number(enrollmentStatus)
                ) &&
                !hasCompletedAncillaryTask &&
                hasAncillaryBenefits
            )
        ) {
            setEnrollmentStatus(enrollmentStatus);
        }
    };

    const saveEnrollmentStatusOrAdvisementCompleted: ISaveEditableTextField<YearlyUserInfoDto> = async (
        name,
        value
    ) => {
        const newValue = { value };
        await saveYearlyUserInfo(name, newValue);
        if (hasAnyContributionInfo) {
            await dispatch(getPathwayBlueprintDataForUser(userId, selectedYear));
        }
    };

    const saveFollowUpNote = async (value: string | undefined) => {
        await basicSave('followUpNote', value);
        closeFollowUpNoteModal();
    };

    const saveActiveDate = useCallback(async () => {
        setPreviousActiveDate(activeDate);
        await basicSave('activeDate', activeDate);
        closeActiveDateModal();
    }, [activeDate, basicSave, closeActiveDateModal, setPreviousActiveDate]);

    const handleActiveDateChange = useCallback(async () => {
        openActiveDateModal();
    }, [openActiveDateModal]);

    const onActiveDateModalNoClick = () => {
        setActiveDate(previousActiveDate);
        closeActiveDateModal();
    };
    const saveShoppingConfigurationOverride: ISaveEditableTextField<IUser> = async (
        name,
        valueAsArray
    ) => {
        const values = valueAsArray as string[];
        const value =
            values.length > 0
                ? values.reduce(
                      (previousValue, currentValue) => Number(currentValue) | previousValue,
                      0
                  )
                : undefined;
        await basicSave(name, value);
    };

    const memberStatusOptions = enumToNameValueArray(UserStatus, {
        nameMap: { OffBoarding: 'Off-Boarding' },
    }).filter(
        (option) =>
            [UserStatus.Active, UserStatus.Launching, UserStatus.Renewing].includes(option.value) ||
            option.value === memberStatus
    );

    const memberStatusSubtext = getStatusSubtext(
        canEditStatus,
        user?.statusLastModifiedByUser,
        user?.statusLastModifiedDate
    );

    const enrollmentStatusOptions = enumToNameValueArray(EnrollmentStatuses, {
        formatName: startCase,
    })
        .filter(
            (option) =>
                option.value !== EnrollmentStatuses.Waived || option.value === enrollmentStatus
        )
        .map((x) => {
            if (x?.['name']?.toString().includes('Wage Up')) {
                return { name: x?.['name'].toString().replace('Wage Up', 'Wage+'), value: x.value };
            }
            return x;
        });

    const enrollmentStatusSubtext = getStatusSubtext(
        canEditStatus,
        userProfile?.yearlyUserInfo?.enrollmentStatus?.lastModifiedByUser,
        userProfile?.yearlyUserInfo?.enrollmentStatus?.lastModifiedDate
    );

    const finalReviewCompletedSubtext = getStatusSubtext(
        isRh,
        userProfile?.yearlyUserInfo?.finalReviewCompleted?.lastModifiedByUser,
        userProfile?.yearlyUserInfo?.finalReviewCompleted?.lastModifiedDate
    );

    const advisementCompletedSubtext = getStatusSubtext(
        canViewAdvisementCompleted,
        userProfile?.yearlyUserInfo?.advisementCompleted?.lastModifiedByUser,
        userProfile?.yearlyUserInfo?.advisementCompleted?.lastModifiedDate
    );

    const followUpNoteSubtext = getStatusSubtext(
        canEditVerifiedInfo,
        user?.followUpLastModifiedByUser,
        user?.followUpLastModifiedDate
    );

    const showAdpConfigurations =
        team?.adpIntegrationStatus === AdpIntegrationStatuses.Connected &&
        canViewHrsAssociateOid &&
        user?.isHrsAssociateIdSet;

    const showAdpIntegrationStatus =
        team?.adpIntegrationStatus === AdpIntegrationStatuses.Connected && canViewHrsAssociateOid;
    const showAncillarySelectionStatus =
        hasAncillaryBenefits &&
        [UserStatus.Launching, UserStatus.Renewing].includes(memberStatus as UserStatus);
    const maxActiveDate = `01/01/${Math.max(...getYears(() => 2)).toString()}`;
    const minActiveDate = `12/31/${(Math.min(...getYears()) - 1).toString()}`;

    const showWagePlusShoppingConfig = !isIchraPathwayType(activePathwayType);
    const showIchraShoppingConfig = !isAnyWageUpPathwayType(activePathwayType);

    return (
        <React.Fragment>
            <PageHeader variant="h4">Basic</PageHeader>
            <hr />
            <EditableTextAttribute
                {...commonProps(setEmail)}
                label="Email"
                name="email"
                placeholder="Enter an email"
                validationSchema={string()
                    .trim()
                    .required()
                    .email()
                    .label('Email')}
                value={email}
            />
            <EditableSelectAttribute
                data-cy="member-status-input"
                formatter={statusFormatter(memberStatusOptions)}
                items={memberStatusOptions}
                label="Member Status"
                name="status"
                onChange={onChange(setMemberStatus)}
                optionText="name"
                optionValue="value"
                readonly={!canEditStatus}
                save={saveMemberStatus}
                subtext={memberStatusSubtext}
                validationSchema={string()
                    .required()
                    .label('Member Status')}
                value={memberStatus}
            />

            <EditableSelectAttribute
                data-cy="enrollment-status-input"
                formatter={statusFormatter(enrollmentStatusOptions)}
                items={enrollmentStatusOptions}
                label="Enrollment Status"
                name="enrollmentStatus"
                onChange={onChange(handleSetEnrollmentStatus)}
                optionText="name"
                optionValue="value"
                readonly={!canEditStatus}
                save={saveEnrollmentStatusOrAdvisementCompleted}
                subtext={enrollmentStatusSubtext}
                validationSchema={string()
                    .required()
                    .test(
                        'enrollment status validation',
                        () => true,
                        (enrollmentStatus, testContext) => {
                            if (
                                [
                                    EnrollmentStatuses.PendingApplication,
                                    EnrollmentStatuses.Enrolled,
                                ].includes(Number(enrollmentStatus)) &&
                                !hasCompletedAncillaryTask &&
                                hasAncillaryBenefits &&
                                selectedYear === new Date(activeDate ?? '').getFullYear()
                            ) {
                                return testContext.createError({
                                    message: `Cannot set member Enrollment Status to ${startCase(
                                        EnrollmentStatuses[Number(enrollmentStatus ?? 0)]
                                    )} until Ancillary Benefit Selection task is complete`,
                                });
                            }
                            return true;
                        }
                    )
                    .label('Enrollment Status')}
                value={enrollmentStatus}
            />
            {isRh && (
                <EditableYesNoSelectAttribute
                    label="Final Review Completed"
                    name="finalReviewCompleted"
                    onChange={onChange(setFinalReviewCompleted)}
                    save={saveEnrollmentStatusOrAdvisementCompleted}
                    subtext={finalReviewCompletedSubtext}
                    value={finalReviewCompleted}
                />
            )}
            {showAncillarySelectionStatus && (
                <ProfileAttribute label="Ancillary Selection Completed">
                    <AncillarySelectionStatus
                        hasCompletedAncillaryTask={hasCompletedAncillaryTask}
                    />
                </ProfileAttribute>
            )}
            <EditableTextAttribute
                {...commonProps(setFirstName)}
                label="Legal First Name"
                name="firstName"
                placeholder="Enter a first name"
                validationSchema={string()
                    .trim()
                    .required()
                    .label('Legal First Name')}
                value={firstName}
            />

            <EditableTextAttribute
                {...commonProps(setMiddleName)}
                isOptional
                label="Middle Name"
                name="middleName"
                onChange={onChange(setMiddleName)}
                placeholder="Enter a middle name"
                validationSchema={string()
                    .trim()
                    .label('Middle Name')}
                value={middleName}
            />

            <EditableTextAttribute
                {...commonProps(setLastName)}
                label="Last Name"
                name="lastName"
                placeholder="Enter a last name"
                validationSchema={string()
                    .trim()
                    .required()
                    .label('Last Name')}
                value={lastName}
            />

            <EditableTextAttribute
                {...commonProps(setPreferredName)}
                isOptional
                label="Preferred Name"
                name="preferredName"
                placeholder="Enter a preferred first name"
                validationSchema={string()
                    .trim()
                    .label('Preferred Name')}
                value={preferredName}
            />

            <EditableTextAttribute
                {...commonProps(setSuffix)}
                inputProps={{
                    maxLength: MAX_SUFFIX_LENGTH,
                }}
                isOptional
                label="Suffix"
                name="suffix"
                placeholder="Enter a suffix"
                validationSchema={string()
                    .trim()
                    .max(MAX_SUFFIX_LENGTH)
                    .label('Suffix')}
                value={suffix}
            />

            <ProfileAttribute
                infoTooltip={
                    'This is your Remodel Health User ID, to view your health plan member ID(s) go to "My Benefits" page.'
                }
                label="User ID"
            >
                {user?.memberId}
            </ProfileAttribute>

            {canViewVerifiedInfo && (
                <React.Fragment>
                    <SubtextAttribute
                        label="Has Login"
                        subtext={formatDateForDisplay(loginCreatedDate)}
                        value={formatYesOrNo(hasLoginInfo)}
                    />
                    {showAccountLockout && (
                        <ProfileAttribute label="Account Locked">
                            {formatYesOrNo(isLockedOut)}
                        </ProfileAttribute>
                    )}
                </React.Fragment>
            )}

            {canViewSurveyTypeToSend && (
                <EditableSelectAttribute
                    formatter={(x: IValueType) => surveyTypeFormatter(SurveyTypes[x as number])}
                    items={SURVEY_TYPE_OPTIONS}
                    label="Survey Type to Send"
                    name="surveyTypeToSend"
                    onChange={onChange(setSurveyTypeToSend)}
                    optionText="label"
                    optionValue="value"
                    readonly={!canEditSurveyTypeToSend}
                    save={saveSurveyType}
                    validationSchema={string()
                        .label('Survey Type to Send')
                        .required()}
                    value={surveyTypeToSend}
                />
            )}
            {canViewSurveyTypeToSend && (
                <ProfileAttribute
                    label="Survey Type Completed"
                    textToCopy={
                        SURVEY_TYPE_OPTIONS.find(
                            (status) => status.value === memberVerifiedInfo?.surveyTypeCompleted
                        )?.label
                    }
                >
                    {SURVEY_TYPE_OPTIONS.find(
                        (status) => status.value === memberVerifiedInfo?.surveyTypeCompleted
                    )?.label ?? ''}
                </ProfileAttribute>
            )}

            {canViewUsers && (
                <React.Fragment>
                    <SubtextAttribute
                        label="Survey Last Sent"
                        subtext={formatSentByName(user?.surveyLastSentByUser?.displayName)}
                        value={formatDateAtTime(user?.surveyLastSentDate)}
                    />

                    <SubtextAttribute
                        label="Invite Last Sent"
                        subtext={formatSentByName(user?.inviteLastSentByUser?.displayName)}
                        value={formatDateAtTime(user?.inviteLastSentDate)}
                    />
                </React.Fragment>
            )}

            {canViewAdvisementCompleted && (
                <EditableYesNoSelectAttribute
                    isOptional
                    label="Advisement Completed"
                    name="advisementCompleted"
                    onChange={onChange(setAdvisementCompleted)}
                    readonly={!isRhOrAdvisor}
                    save={saveEnrollmentStatusOrAdvisementCompleted}
                    subtext={advisementCompletedSubtext}
                    validationSchema={string().label('Advisement Completed')}
                    value={advisementCompleted}
                />
            )}

            {canViewVerifiedInfo && (
                <React.Fragment>
                    <EditableSelectAttribute
                        formatter={getAdvisorName}
                        isOptional
                        items={teamAdvisors}
                        label="Advisor"
                        name="advisorUserId"
                        onChange={onChange(setAdvisorUserId)}
                        optionText="displayName"
                        optionValue="userId"
                        readonly={!canEditAdvisor}
                        save={saveAdvisor}
                        validationSchema={string().label('Advisor')}
                        value={advisorUserId}
                    />
                    {isRhOrAdvisor && (
                        <React.Fragment>
                            <EditableSelectAttribute
                                formatter={getRhMembersAndAdvisorsName}
                                isOptional
                                items={rhMembersAndAdvisors}
                                label="Application Submitted By"
                                name="applicationSubmittedBy"
                                onChange={onChange(setApplicationSubmittedBy)}
                                optionText="displayName"
                                optionValue="userId"
                                readonly={!canEditAdvisor}
                                save={saveYearlyUserInfo}
                                validationSchema={string().label('Application Submitted By')}
                                value={applicationSubmittedBy}
                            />
                            <EditableSelectAttribute
                                formatter={(x: IValueType) =>
                                    renewalDecisionsFormatter(RenewalDecisions[x as number])
                                }
                                isOptional
                                items={RENEWAL_DECISION_ITEMS}
                                label="Renewal Decision"
                                name="renewalDecision"
                                onChange={onChange(setRenewalDecision)}
                                optionText="text"
                                optionValue="value"
                                readonly={!canEditVerifiedInfo}
                                save={saveYearlyUserInfo}
                                validationSchema={string().label('Renewal Decision')}
                                value={renewalDecision}
                            />
                            <EditableYesNoSelectAttribute
                                label="Allow Shopping"
                                name="allowShopping"
                                onChange={onChange(setAllowShopping)}
                                save={saveYearlyUserInfo}
                                value={allowShopping}
                            />
                            {showWagePlusShoppingConfig && (
                                <EditableSelectAttribute
                                    formatter={() =>
                                        wagePlusShoppingConfigurationOverride
                                            .map(
                                                (x) =>
                                                    shoppingConfigurationIdNames[
                                                        Number(x) as ShoppingConfigurationIds
                                                    ]
                                            )
                                            .joinSerialComma()
                                    }
                                    infoTooltip="Overrides team-level Wage+ Shopping Configuration value for determining what plans/programs this member will be able to see in the Shopping experience."
                                    isOptional
                                    items={WAGE_PLUS_SHOPPING_CONFIGURATION_ITEMS}
                                    label="Wage+ Shopping Configuration Override"
                                    name="wagePlusShoppingConfigurationOverride"
                                    onChange={onChange(setWagePlusShoppingConfigurationOverride)}
                                    optionText="text"
                                    optionValue="value"
                                    save={saveShoppingConfigurationOverride}
                                    SelectProps={{
                                        multiple: true,
                                        native: false,
                                    }}
                                    validationSchema={wagePlusShoppingConfigurationValidationSchema.label(
                                        'Wage+ Shopping Configuration Override'
                                    )}
                                    value={
                                        (wagePlusShoppingConfigurationOverride as unknown) as string[]
                                    }
                                />
                            )}
                            {showIchraShoppingConfig && (
                                <EditableSelectAttribute
                                    formatter={() =>
                                        ichraShoppingConfigurationOverride
                                            .map(
                                                (x) =>
                                                    shoppingConfigurationIdNames[
                                                        Number(x) as ShoppingConfigurationIds
                                                    ]
                                            )
                                            .joinSerialComma()
                                    }
                                    infoTooltip="Overrides team-level ICHRA Shopping Configuration value for determining what plans/programs this member will be able to see in the Shopping experience."
                                    isOptional
                                    items={ICHRA_SHOPPING_CONFIGURATION_ITEMS}
                                    label="ICHRA Shopping Configuration Override"
                                    name="ichraShoppingConfigurationOverride"
                                    onChange={onChange(setIchraShoppingConfigurationOverride)}
                                    optionText="text"
                                    optionValue="value"
                                    save={saveShoppingConfigurationOverride}
                                    SelectProps={{
                                        multiple: true,
                                        native: false,
                                    }}
                                    validationSchema={array().label('ICHRA Shopping Configuration')}
                                    value={
                                        (ichraShoppingConfigurationOverride as unknown) as string[]
                                    }
                                />
                            )}
                            <EditableYesNoSelectAttribute
                                label="Use Premium Tax Credits"
                                name={nameof<YearlyUserInfoDto>('usePremiumTaxCredits')}
                                onChange={onChange(setUsePremiumTaxCredits)}
                                save={saveYearlyUserInfo}
                                value={usePremiumTaxCredits}
                            />
                            {canEditSubmittedExpenses && (
                                <EditableYesNoSelectAttribute
                                    infoTooltip="If the member is enrolled in a Reimbursement Program, they will see the option to designate an expense they submit as recurring."
                                    label="Allow Recurring Expenses"
                                    name="allowRecurringExpenses"
                                    onChange={onChange(setAllowRecurringExpenses)}
                                    readonly={verifiedInfoCommonProps.readonly}
                                    save={saveYearlyUserInfo}
                                    validationSchema={string()
                                        .required()
                                        .label('Allow Recurring Expenses')}
                                    value={allowRecurringExpenses}
                                />
                            )}
                            <EditableYesNoSelectAttribute
                                isOptional
                                label="Accepted Pathway"
                                name="acceptedPathway"
                                onChange={onChange(setAcceptedPathway)}
                                save={saveYearlyUserInfo}
                                value={acceptedPathway}
                            />
                        </React.Fragment>
                    )}
                </React.Fragment>
            )}

            {canViewUsers && (
                <EditableYesNoSelectAttribute
                    isOptional
                    label="Needs Follow-up"
                    name="needsFollowUp"
                    onChange={onChange(setNeedsFollowUp)}
                    readonly={!canEditVerifiedInfo}
                    save={basicSave}
                    value={needsFollowUp}
                />
            )}

            {canEditVerifiedInfo && (
                <React.Fragment>
                    <EditableProfileAttribute
                        content={user?.followUpNote}
                        label="Follow-up Note"
                        onClick={openFollowUpNoteModal}
                        preserveNewLines
                        readonly={readonly}
                        subtext={followUpNoteSubtext}
                    />

                    {isFollowUpNoteModalVisible && (
                        <TextAreaModal
                            label="Follow-up Note"
                            maxCharacters={MAX_FOLLOW_UP_NOTE_CHARS}
                            onHide={closeFollowUpNoteModal}
                            placeholder="Set Follow-up Note"
                            save={saveFollowUpNote}
                            showActivity={isPatchingUser}
                            title="Set Follow-up Note"
                            value={user?.followUpNote}
                        />
                    )}
                </React.Fragment>
            )}

            {canEditVip && (
                <EditableYesNoSelectAttribute
                    isOptional
                    label="VIP"
                    name="isVip"
                    onChange={onChange(setIsVip)}
                    save={basicSave}
                    value={isVip}
                />
            )}

            {canEditIsMinister && (
                <EditableYesNoSelectAttribute
                    isOptional
                    label="Is Minister"
                    name="isMinister"
                    onChange={onChange(setIsMinister)}
                    save={saveYearlyUserInfo}
                    value={isMinister}
                />
            )}

            <EditableDateAttribute
                infoTooltip={`The date on which new benefits are next expected to start for ${user?.firstName}`}
                isOptional={isInProspect}
                label="Active Date"
                name="activeDate"
                onChange={onChange(setActiveDate)}
                readonly={!canEditVerifiedInfo}
                save={handleActiveDateChange}
                validationSchema={string()
                    .isRequiredWhen(!isInProspect)
                    .isValidDate(false)
                    .isBefore({
                        message: (_, baseMessage) => `${baseMessage} ${maxActiveDate}`,
                        momentValue: moment(maxActiveDate),
                    })
                    .isAfter({
                        message: (_, baseMessage) => `${baseMessage} ${minActiveDate}`,
                        momentValue: moment(minActiveDate),
                    })
                    .label('Active Date')}
                value={activeDate}
            />

            {isActiveDateModalVisible && (
                <ConfirmationModal
                    body={
                        <div>
                            <p>
                                This field is critical for a variety of key platform features. It
                                impacts a member&apos;s Wage+ calculation, their shopping
                                experience, and what year&apos;s data gets automatically updated as
                                they go through enrollment. It&apos;s important for this date to be
                                accurate.
                            </p>
                            <p>Are you sure you want to change this member&apos;s Active Date?</p>
                        </div>
                    }
                    onNoClick={onActiveDateModalNoClick}
                    onYesClick={saveActiveDate}
                    showActivity={isPatchingUser}
                    title="Confirm"
                />
            )}

            <EditableDateAttribute
                infoTooltip={`The date on which ${user?.firstName} first became active with Remodel Health`}
                isOptional
                label="Original Start Date"
                name="originalStartDate"
                onChange={onChange(setOriginalStartDate)}
                readonly={!canEditVerifiedInfo}
                save={basicSave}
                validationSchema={string()
                    .isValidDate(false)
                    .label('Original Start Date')}
                value={originalStartDate}
            />

            <EditableDateAttribute
                infoTooltip={`This is the date ${user?.firstName} will stop having benefits managed by Remodel Health`}
                isOptional
                label="Termination Date"
                name="terminationDate"
                onChange={onChange(setTerminationDate)}
                readonly={!canEditVerifiedInfo}
                save={basicSave}
                validationSchema={string()
                    .isValidDate(false)
                    .label('Termination Date')}
                value={terminationDate}
            />

            <ProfileAttribute
                infoTooltip={`This is when ${user?.firstName} was set to the final status of Inactive`}
                label="Inactive Date"
            >
                {formatDateForDisplay(user?.inactiveDate)}
            </ProfileAttribute>
            <EditablePhoneNumberAttribute
                {...commonProps(setPhone)}
                data-cy="phone"
                isOptional
                label="Phone"
                name="phone"
                validationSchema={string()
                    .isValidPhoneNumber(false)
                    .label('Phone')}
                value={phone}
            />
            <EditableAddressAttribute
                address={address}
                readonly={readonly}
                saveAddress={saveAddress}
                type={AddressTypes.Home}
                year={selectedYear}
            />
            <EditableAddressAttribute
                address={mailingAddress}
                deleteAddress={deleteAddress}
                readonly={readonly}
                saveAddress={saveAddress}
                type={AddressTypes.Mailing}
                year={selectedYear}
            />
            <EditableTextAttribute
                {...commonProps(setJobTitle)}
                isOptional
                label="Job Title"
                name="jobTitle"
                placeholder="Enter a job title"
                validationSchema={string()
                    .trim()
                    .label('Job Title')}
                value={jobTitle}
            />
            <EditableDateAttribute
                {...commonProps(setJobTitle)}
                label="Date of Birth"
                name="dateOfBirth"
                onChange={onChange(setDateOfBirth)}
                validationSchema={string()
                    .required()
                    .isValidDate(false)
                    .label('Date of Birth')}
                value={dateOfBirth}
            />

            <EditableDateAttribute
                data-cy="hire-date-input"
                isOptional
                label="Hire Date"
                name="hireDate"
                onChange={onChange(setHireDate)}
                readonly={!canEditVerifiedInfo}
                save={basicSave}
                validationSchema={string()
                    .isValidDate(false)
                    .label('Hire Date')}
                value={hireDate}
            />
            {canViewPayrollSystemId && (
                <EditableTextAttribute
                    {...commonProps(setPayrollSystemId)}
                    isOptional
                    label="Payroll System ID"
                    name="payrollSystemId"
                    placeholder="Enter a Payroll System ID"
                    readonly={false}
                    validationSchema={string()
                        .trim()
                        .label('Payroll System ID')}
                    value={payrollSystemId}
                />
            )}
            {showAdpIntegrationStatus && (
                <ProfileAttribute label="ADP Integration Status">
                    <HrsIntegrationStatus />
                </ProfileAttribute>
            )}
            {showAdpConfigurations && (
                <EditableYesNoSelectAttribute
                    label="Is ADP Set Withholding and Wage+"
                    name={nameof<IUser>('isAdpSetWithholdingAndWageUp')}
                    onChange={onChange(setIsAdpSetWithholdingAndWageUp)}
                    readonly={!canSetPayrollSystemDeduction}
                    save={basicSave}
                    value={isAdpSetWithholdingAndWageUp}
                />
            )}
            <EditableSelectAttribute
                data-cy="previous-coverage-level-input"
                defaultText="Select a Coverage Level"
                formatter={formatCoverageLevel(groupLevels) as IEditableAttributeFormatter}
                items={groupLevels}
                label="Previous Group Plan Coverage Level"
                name="previousGroupLevelId"
                onChange={onChange(setPreviousGroupLevelId)}
                optionText="fulltext"
                optionValue="groupLevelId"
                readonly={!isRhOrAdvisor}
                save={basicSave}
                validationSchema={string()
                    .required()
                    .label('Previous Group Plan Coverage Level')}
                value={previousGroupLevelId}
            />

            {hasMemberQuoteInfo && !hasMemberVerifiedInfo && (
                <SpouseDateOfBirth
                    memberQuoteInfo={memberQuoteInfo}
                    quoteInfoCommonProps={quoteInfoCommonProps}
                />
            )}

            {hasMemberVerifiedInfo && canViewVerifiedInfo && (
                <BasicVerifiedInfo
                    memberVerifiedInfo={memberVerifiedInfo}
                    userId={userId}
                    verifiedInfoCommonProps={verifiedInfoCommonProps}
                />
            )}
        </React.Fragment>
    );
};

export default hot(module)(BasicInfo);
