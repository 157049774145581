import { ShoppingConfigurationIds } from 'api/generated/enums';
import useTeamProps from 'hooks/useTeamProps';
import useUserProps from 'hooks/useUserProps';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasValue } from 'utilities/index';
import { isHealthcareSharingPathwayType, isIchraPathwayType } from 'utilities/pathways';

const useShoppingConfiguration = () => {
    const { user } = useUserProps();
    const { team } = useTeamProps();
    const { activePathwayType } = useSelector((state: AppStore) => ({
        activePathwayType: state.pathwayUser?.pathwayType,
    }));
    let shoppingConfiguration = null;
    if (isIchraPathwayType(activePathwayType)) {
        shoppingConfiguration =
            user?.ichraShoppingConfigurationOverride ?? team?.ichraShoppingConfiguration;
    } else {
        shoppingConfiguration =
            user?.wagePlusShoppingConfigurationOverride ?? team?.wagePlusShoppingConfiguration;
        if (isHealthcareSharingPathwayType(activePathwayType) && hasValue(shoppingConfiguration)) {
            shoppingConfiguration |= ShoppingConfigurationIds.MediShare;
        }
    }

    return shoppingConfiguration;
};

export default useShoppingConfiguration;
