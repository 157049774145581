import Box from '@mui/material/Box';
import { IMarketplacePlanDto } from 'api/generated/models';
import MarketplacePlanCardContent from 'components/MarketplacePlanCardContent';
import isEmpty from 'lodash/isEmpty';
import PlanCardBase from 'pages/shop/PlanCardBase';
import { IShoppingPlan } from 'pages/shop/shopping';
import React from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasValue, roundTwoDecimals } from 'utilities';
import { isIchraPathwayType } from 'utilities/pathways';

const MarketplacePlanCard = ({
    dataCyPrefix,
    handleSelectPlan,
    hideCost,
    hideSelectPlan,
    householdMembersCovered,
    individualOnly,
    isChunkPlan,
    isDisabled,
    isIchraFlow,
    isPrimaryCovered,
    pathwayPlanCardHeader,
    plan,
    showTaxSavings,
}: {
    dataCyPrefix?: string;
    handleSelectPlan?: (plan: Partial<IShoppingPlan>) => Promise<void>;
    hideCost?: boolean;
    hideSelectPlan?: boolean;
    householdMembersCovered?: string[];
    individualOnly?: boolean;
    isChunkPlan?: boolean;
    isDisabled?: boolean;
    isIchraFlow?: boolean;
    isPrimaryCovered?: boolean;
    pathwayPlanCardHeader?: JSX.Element;
    plan: Partial<IShoppingPlan>;
    showTaxSavings?: boolean;
}) => {
    const { pathwayUser } = useSelector((state: AppStore) => ({
        pathwayUser: state.pathwayUser,
    }));
    const isInIchraPathway = isIchraPathwayType(pathwayUser?.pathwayType);
    const marketplacePlan = plan as IMarketplacePlanDto;
    const roundingMethod = isInIchraPathway ? roundTwoDecimals : Math.round;
    const planCost = roundingMethod(marketplacePlan.premium ?? NaN);
    const costWithCredit = roundingMethod(marketplacePlan.premiumWithCredits ?? NaN);
    const showTaxSavingsTag = showTaxSavings && isEmpty((plan as IMarketplacePlanDto).exchange);
    return (
        <PlanCardBase
            benefitDetailsButtonHref={marketplacePlan.benefitsUrl}
            benefitDetailsButtonText="Summary of Benefits"
            carrier={marketplacePlan?.issuer?.name}
            cost={planCost}
            costWithCredit={costWithCredit}
            dataCyPrefix={dataCyPrefix}
            handleSelectPlan={handleSelectPlan}
            hideSelectPlan={hideSelectPlan}
            hideYourCost={hideCost}
            householdMembersCovered={householdMembersCovered}
            isChunkPlan={isChunkPlan}
            isDisabled={isDisabled}
            isIchraFlow={isIchraFlow}
            isPrimaryCovered={isPrimaryCovered}
            name={marketplacePlan.name}
            pathwayPlanCardHeader={pathwayPlanCardHeader}
            plan={plan}
            planCardContent={
                <Box
                    sx={{
                        display: 'grid',
                        gap: 2,
                        gridTemplateColumns: '1fr 1fr 1fr',
                    }}
                >
                    <MarketplacePlanCardContent
                        familyDeductibleCost={marketplacePlan.familyDeductibleCost}
                        familyMoopCost={marketplacePlan.familyMoopCost}
                        genericDrugCost={marketplacePlan.genericDrugCost}
                        individualDeductibleCost={marketplacePlan.individualDeductibleCost}
                        individualMoopCost={marketplacePlan.individualMoopCost}
                        individualOnly={hasValue(individualOnly) && individualOnly}
                        networkType={marketplacePlan.planType}
                        planName={marketplacePlan.name}
                        primaryCareCost={marketplacePlan.primaryCareCost}
                        reimbursementOverlayDto={marketplacePlan.reimbursementOverlayDto}
                        specialistCost={marketplacePlan.specialistCost}
                    />
                </Box>
            }
            selectButtonText="Select Plan"
            showTaxSavings={showTaxSavingsTag}
        />
    );
};

export default hot(module)(MarketplacePlanCard);
