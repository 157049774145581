import { Paper } from '@mui/material';
import {
    clearAncillaryBenefits,
    clearApiActivity,
    clearCompletedUserTasks,
    clearUserBudget,
} from 'actions/clear';
import { getPathwayBlueprintDataForUser } from 'actions/pathwayBlueprint/getPathwayBlueprintDataForUser';
import { getCompletedUserTasks } from 'actions/task/getCompletedUserTasks';
import { clearTaxData } from 'actions/taxData/clearTaxData';
import { getTaxData } from 'actions/taxData/getTaxData';
import { listAncillaryBenefits } from 'actions/teamBenefit/listAncillaryBenefits';
import { GET_HOUSEHOLD_ACTION, getHousehold } from 'actions/user/getHousehold';
import { getUserBudget } from 'actions/user/getUserBudget';
import { patchMemberQuoteInfo } from 'actions/user/patchMemberQuoteInfo';
import { patchMemberVerifiedInfo } from 'actions/user/patchMemberVerifiedInfo';
import { patchUser } from 'actions/user/patchUser';
import { patchYearlyUserInfo } from 'actions/user/patchYearlyUserInfo';
import { UserStatus } from 'api/generated/enums';
import {
    IMemberQuoteInfo,
    IMemberVerifiedInfo,
    IUser,
    IYearlyUserInfo,
    YearlyUserInfoDto,
} from 'api/generated/models';
import {
    EditTeamBenefit,
    EditUser,
    ViewMviEmployeeAnnualIncome,
    ViewPathwayBlueprints,
    ViewTaxData,
    ViewUserVerifiedInfo,
    ViewUserVerifiedInfoForOwnTeam,
} from 'api/generated/permissions';
import { ROLE_IDS } from 'api/generated/roleIds';
import { IValueType } from 'components/EditableAttribute';
import { ISaveEditableTextField } from 'components/EditableTextField';
import { otherCoverageEligibilities } from 'constants/otherCoverageEligibilities';
import useContributionProps from 'hooks/useContributionProps';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import startCase from 'lodash/startCase';
import BasicInfo from 'pages/profile/BasicInfo';
import CoverageInfo from 'pages/profile/CoverageInfo';
import HouseholdMemberInfo from 'pages/profile/householdMemberInfo/HouseholdMemberInfo';
import IncomeInfo from 'pages/profile/IncomeInfo';
import { setSelectedYear } from 'pages/profile/profileActions';
import ProfileHeader from 'pages/profile/ProfileHeader';
import {
    getCanEditVerifiedInfo,
    getIsInProspect,
    getIsOwnInfoAndTeamIsProspect,
} from 'pages/profile/profileSelectors';
import React, { useCallback, useEffect, useMemo } from 'react';
import Alert from 'react-bootstrap/Alert';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasSomePermissions, ITeamProps, IUserProps } from 'selectors';
import { hasCompletedRequests } from 'selectors/activity';
import { isInRoleSelector } from 'selectors/role';
import { hasValue, isTrue, nameof, updateFlag } from 'utilities';

type IKeyOfMemberInfo =
    | keyof IMemberQuoteInfo
    | keyof IMemberVerifiedInfo
    | keyof IUser
    | keyof IYearlyUserInfo;

export type ICommonProps<T> = {
    readonly: boolean;
    save: (name: keyof T, value: IValueType) => Promise<void>;
};

export type IProfilePageDropdownProps = {
    defaultText: string;
    defaultValue: string;
    invalidMessage: string;
    isRequired: boolean;
    items: {
        text: string;
        value: boolean;
    }[];
    optionText: string;
    optionValue: string;
};

export type ISaveYearlyUserInfo = (
    name: keyof YearlyUserInfoDto,
    value: IValueType | { value: IValueType }
) => Promise<void>;

type ISaveMemberInfo = (
    userId: string | undefined,
    year: number,
    memberInfo: Partial<IMemberQuoteInfo | IMemberVerifiedInfo | IUser>,
    isCurrent: boolean
) => Promise<void>;

const profilePageSelector = (teamProps: ITeamProps, userProps: IUserProps) => (state: AppStore) => {
    const { isSameTeam } = teamProps;
    const { isCurrent } = userProps;
    const props = {
        canEditQuoteInfo: false,
        canEditTeamBenefits: hasSomePermissions(state, EditTeamBenefit),
        canEditVerifiedInfo: getCanEditVerifiedInfo(state, teamProps, userProps),
        canViewMviEmployeeAnnualIncome: false,
        canViewPathwayBlueprints: hasSomePermissions(state, ViewPathwayBlueprints) || isCurrent,
        canViewPreviousCostInfo: false,
        canViewTaxData: hasSomePermissions(state, ViewTaxData),
        canViewVerifiedInfo: false,
        hasCompletedInitialRequest: hasCompletedRequests(state, GET_HOUSEHOLD_ACTION),
        householdMembers: state.householdMembers,
        isInProspect: getIsInProspect(teamProps),
        selectedYear: +state.profileState.selectedYear,
        showActivity: state.apiActivity[GET_HOUSEHOLD_ACTION],
    };

    const isTeamAdmin = isInRoleSelector(state, ROLE_IDS.TEAM_ADMIN);
    const isTeamPayrollAdmin = isInRoleSelector(state, ROLE_IDS.TEAM_PAYROLL_ADMIN);
    if (isSameTeam) {
        props.canViewVerifiedInfo =
            isCurrent || hasSomePermissions(state, ViewUserVerifiedInfoForOwnTeam);
        props.canViewMviEmployeeAnnualIncome =
            isCurrent || hasSomePermissions(state, ViewMviEmployeeAnnualIncome);
        props.canViewPreviousCostInfo = isTeamAdmin || isTeamPayrollAdmin;
    } else {
        props.canViewVerifiedInfo = isCurrent || hasSomePermissions(state, ViewUserVerifiedInfo);
        props.canViewPreviousCostInfo = true;
    }

    const isProspectOrNotTeamAdmin = props.isInProspect || !isTeamAdmin;
    const isOtherInfoAndHasEditUserPermission =
        !isCurrent && hasSomePermissions(state, EditUser) && isProspectOrNotTeamAdmin;

    const isOwnInfoAndTeamIsProspect = getIsOwnInfoAndTeamIsProspect(teamProps, userProps);
    props.canEditQuoteInfo = isOwnInfoAndTeamIsProspect || isOtherInfoAndHasEditUserPermission;

    return props;
};

const ProfilePage = () => {
    const dispatch = useThunkDispatch();
    const teamProps = useTeamProps();
    const { activeDate, hasTeam, teamStateId } = teamProps;
    const userProps = useUserProps();
    const { hasAnyContributionInfo } = useContributionProps();
    const {
        hasMemberQuoteInfo,
        hasMemberVerifiedInfo,
        hasOnlyBasicInfo,
        hasUser,
        isCurrent,
        memberQuoteInfo,
        memberVerifiedInfo,
        user,
        userId,
    } = userProps;
    const {
        canEditQuoteInfo,
        canEditTeamBenefits,
        canEditVerifiedInfo,
        canViewMviEmployeeAnnualIncome,
        canViewPathwayBlueprints,
        canViewPreviousCostInfo,
        canViewTaxData,
        canViewVerifiedInfo,
        hasCompletedInitialRequest,
        isInProspect,
        selectedYear,
        showActivity,
    } = useSelector(profilePageSelector(teamProps, userProps));
    useEffect(() => {
        const hasTeamAndNotCurrent = hasTeam && !isCurrent;
        const hasUserAndIsCurrent = hasUser && isCurrent;
        if (selectedYear === 0 && (hasTeamAndNotCurrent || hasUserAndIsCurrent)) {
            let teamActiveYear =
                activeDate?.getYear().toString() ?? new Date().getUTCFullYear().toString();
            if (isCurrent && memberVerifiedInfo?.year) {
                teamActiveYear = memberVerifiedInfo?.year.toString();
            }
            dispatch(setSelectedYear(teamActiveYear));
        }
    }, [activeDate, dispatch, hasTeam, hasUser, isCurrent, memberVerifiedInfo, selectedYear]);
    useEffect(
        () => () => {
            dispatch(clearApiActivity());
        },
        [dispatch]
    );
    useEffect(() => {
        if (
            canEditTeamBenefits &&
            [UserStatus.Launching, UserStatus.Renewing].includes(
                user?.status ?? UserStatus.Active
            ) &&
            hasValue(user?.activeDate)
        ) {
            dispatch(getCompletedUserTasks(userId));
            dispatch(listAncillaryBenefits(userId, false));
        }
        return () => {
            dispatch(clearAncillaryBenefits());
            dispatch(clearCompletedUserTasks());
        };
    }, [canEditTeamBenefits, dispatch, user?.activeDate, user?.status, userId]);
    useEffect(() => {
        if (selectedYear > 0) {
            dispatch(clearTaxData());
            dispatch(clearUserBudget());
            dispatch(getHousehold(userId, isCurrent, selectedYear));
            if (canViewPathwayBlueprints) {
                dispatch(getPathwayBlueprintDataForUser(userId, selectedYear));
            }
            if (canViewTaxData) {
                dispatch(getTaxData(userId, selectedYear));
            }
        }
    }, [canViewPathwayBlueprints, canViewTaxData, dispatch, isCurrent, selectedYear, userId]);

    const saveApiCall = useCallback(
        async (
            name: IKeyOfMemberInfo,
            value: IValueType,
            apiCall: ISaveMemberInfo,
            transform?: (
                name: IKeyOfMemberInfo,
                value: IValueType
            ) => { name: IKeyOfMemberInfo; value: IValueType }
        ) => {
            let result = { name, value };
            if (transform) {
                result = transform(name, value);
            }
            await apiCall(userId, selectedYear, { [result.name]: result.value }, isCurrent);
            if (hasAnyContributionInfo && canViewPathwayBlueprints) {
                await dispatch(getPathwayBlueprintDataForUser(userId, selectedYear));
            }
            if (canViewTaxData) {
                dispatch(getTaxData(userId, selectedYear));
            }
        },
        [
            userId,
            selectedYear,
            isCurrent,
            hasAnyContributionInfo,
            canViewPathwayBlueprints,
            canViewTaxData,
            dispatch,
        ]
    );
    const save: ISaveEditableTextField<
        | IMemberQuoteInfo
        | IUser
        | IYearlyUserInfo
        | (IMemberVerifiedInfo & {
              hasParentOtherCoverage?: boolean;
              hasSpouseOtherCoverage?: boolean;
          })
    > = useCallback(
        async (name: IKeyOfMemberInfo, value: IValueType) =>
            saveApiCall(name, value, async (_userId, year, memberInfo, _isCurrent) => {
                await dispatch(patchUser(_userId, year, memberInfo, _isCurrent, undefined, true));
            }),
        [saveApiCall, dispatch]
    );
    const saveMemberQuoteInfo = useCallback(
        async (name: keyof IMemberQuoteInfo, value: IValueType) => {
            if (name === 'spouseDateOfBirth' && !hasValue(value)) {
                value = undefined;
            }
            await dispatch(
                patchMemberQuoteInfo(
                    userId,
                    selectedYear,
                    ({ [name]: value } as unknown) as IMemberQuoteInfo,
                    isCurrent
                )
            );
            if (name === 'employeeAnnualIncome') {
                await dispatch(getUserBudget(userId, selectedYear));
            }
            if (hasAnyContributionInfo && canViewPathwayBlueprints) {
                await dispatch(getPathwayBlueprintDataForUser(userId, selectedYear));
            }
            if (canViewTaxData) {
                dispatch(getTaxData(userId, selectedYear));
            }
        },
        [
            dispatch,
            userId,
            selectedYear,
            isCurrent,
            hasAnyContributionInfo,
            canViewPathwayBlueprints,
            canViewTaxData,
        ]
    );

    const saveYearlyUserInfo = useCallback(
        async (name, value) => {
            await dispatch(
                patchYearlyUserInfo(userId, selectedYear, { [name]: value }, isCurrent, {
                    toastErrorMessage: `Could not set ${startCase(name)}`,
                })
            );
            if (
                hasAnyContributionInfo &&
                [
                    nameof<YearlyUserInfoDto>('usePremiumTaxCredits'),
                    nameof<YearlyUserInfoDto>('needsMajorMedicalCoverage'),
                ].includes(name)
            ) {
                await dispatch(getPathwayBlueprintDataForUser(userId, selectedYear));
            }
        },
        [dispatch, userId, isCurrent, hasAnyContributionInfo, selectedYear]
    );

    const getNewOtherCoverageValue = useCallback(
        (value, eligibility, data = memberVerifiedInfo) => {
            const shouldSetFlag = isTrue(value);
            let newValue = shouldSetFlag ? eligibility : 0;
            if (data) {
                newValue = updateFlag(shouldSetFlag, data.otherCoverageEligibilities, eligibility);
            }
            return newValue;
        },
        [memberVerifiedInfo]
    );
    const saveMemberVerifiedInfo = useCallback(
        async (
            name: IKeyOfMemberInfo | 'hasParentOtherCoverage' | 'hasSpouseOtherCoverage',
            value: IValueType
        ) =>
            saveApiCall(
                name as IKeyOfMemberInfo,
                value,
                async (
                    ...args: [string | undefined, number, Partial<IMemberVerifiedInfo>, boolean]
                ) => {
                    await dispatch(
                        patchMemberVerifiedInfo(memberVerifiedInfo?.memberVerifiedInfoId, ...args)
                    );
                    await dispatch(getUserBudget(userId, +selectedYear));
                },
                (
                    _name: IKeyOfMemberInfo | 'hasParentOtherCoverage' | 'hasSpouseOtherCoverage',
                    _value: IValueType
                ) => {
                    switch (_name) {
                        case 'phone':
                            return {
                                name: _name,
                                value:
                                    typeof _value === 'string'
                                        ? _value?.removeNonNumericCharacters()
                                        : _value,
                            };
                        case 'hasSpouseOtherCoverage': {
                            return {
                                name: 'otherCoverageEligibilities',
                                value: getNewOtherCoverageValue(
                                    _value,
                                    otherCoverageEligibilities.SpouseEmployer
                                ),
                            };
                        }
                        case 'hasParentOtherCoverage':
                            return {
                                name: 'otherCoverageEligibilities',
                                value: getNewOtherCoverageValue(
                                    _value,
                                    otherCoverageEligibilities.Parent
                                ),
                            };
                    }
                    return { name: _name, value: _value };
                }
            ),
        [saveApiCall, dispatch, memberVerifiedInfo, userId, selectedYear, getNewOtherCoverageValue]
    );
    const isLoading = useMemo(() => !hasCompletedInitialRequest || showActivity, [
        hasCompletedInitialRequest,
        showActivity,
    ]);
    const quoteInfoCommonProps: ICommonProps<IMemberQuoteInfo> = {
        readonly: !canEditQuoteInfo,
        save: saveMemberQuoteInfo,
    };
    const verifiedInfoCommonProps: ICommonProps<IMemberVerifiedInfo & {
        hasParentOtherCoverage?: boolean;
        hasSpouseOtherCoverage?: boolean;
    }> = {
        readonly: !canEditVerifiedInfo,
        save: saveMemberVerifiedInfo,
    };
    const commonInfoProps = {
        canViewMviEmployeeAnnualIncome,
        canViewVerifiedInfo,
        hasMemberQuoteInfo,
        hasMemberVerifiedInfo,
        hasOnlyBasicInfo,
        isCurrent,
        memberQuoteInfo,
        memberVerifiedInfo,
        quoteInfoCommonProps,
        user,
        verifiedInfoCommonProps,
    };
    return (
        <Paper>
            {isLoading || !hasUser || !hasTeam ? (
                <React.Fragment>
                    <Skeleton />
                    <hr />
                    <Skeleton count={5} />
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <ProfileHeader />
                    <Row>
                        {((hasMemberVerifiedInfo && !canEditVerifiedInfo) ||
                            (hasMemberQuoteInfo && !canEditQuoteInfo)) &&
                            !isInProspect && (
                                <Col className="pb-3" xs="12">
                                    <Alert variant="info">
                                        Please contact your Remodel Health representative to change
                                        any personal information.
                                    </Alert>
                                </Col>
                            )}
                        <Col md="6">
                            <BasicInfo
                                {...commonInfoProps}
                                basicSave={save as ISaveEditableTextField<IUser>}
                                saveYearlyUserInfo={saveYearlyUserInfo}
                            />
                            <div className="mt-7" />
                            <CoverageInfo
                                {...commonInfoProps}
                                basicSave={save as ISaveEditableTextField<IUser>}
                                saveYearlyUserInfo={saveYearlyUserInfo}
                            />
                        </Col>
                        <Col md="6">
                            <div className="d-md-none mt-7" />
                            <IncomeInfo
                                canViewPreviousCosts={canViewPreviousCostInfo}
                                {...commonInfoProps}
                                teamStateId={teamStateId}
                            />
                            <div className="mt-7" />
                            <HouseholdMemberInfo {...commonInfoProps} />
                        </Col>
                    </Row>
                </React.Fragment>
            )}
        </Paper>
    );
};

export default hot(module)(ProfilePage);
