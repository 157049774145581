import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { HouseholdMemberInfoDto } from 'api/generated/models';
import React from 'react';
import { hot } from 'react-hot-loader';

type IHouseholdMemberCard = {
    form: JSX.Element;
    handleEditCancelButtonClick: () => void;
    hhmIndex: number;
    initialHhm?: HouseholdMemberInfoDto;
    isEdited: boolean;
    isExpanded: boolean;
    removeHhm: (hhmIndex: number) => void;
};

const HouseholdMemberCard = ({
    form,
    handleEditCancelButtonClick,
    hhmIndex,
    initialHhm,
    isEdited,
    isExpanded,
    removeHhm,
}: IHouseholdMemberCard) => (
    <Stack alignItems="flex-start" direction="column" justifyContent="space-between" spacing={2}>
        <Accordion
            disableGutters
            elevation={0}
            expanded={isExpanded}
            sx={{
                flexDirection: 'row-reverse',
                width: 'auto',
            }}
        >
            <AccordionSummary
                sx={{
                    cursor: 'default !important',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                }}
            >
                <Grid container>
                    <Grid item lg={4} xs={12}>
                        <Typography alignItems="center" variant="h5">
                            {initialHhm?.firstName} {initialHhm?.lastName}{' '}
                        </Typography>
                        <Typography variant="body2">
                            Age: {initialHhm?.birthdate.getAge()}
                        </Typography>
                        <Typography variant="body2">Gender: {initialHhm?.gender}</Typography>
                    </Grid>
                    <Grid alignItems="center" container item justifyContent="flex-end" xs={4}>
                        {isEdited && (
                            <Typography fontStyle="italic" textAlign="right" variant="body2">
                                unsaved edits
                            </Typography>
                        )}
                    </Grid>
                    <Grid
                        alignItems="center"
                        container
                        direction="row"
                        gap={2}
                        item
                        justifyContent="flex-end"
                        sm={4}
                        xs={8}
                    >
                        <Button
                            color="primary"
                            onClick={handleEditCancelButtonClick}
                            sx={{ paddingX: 4 }}
                            variant="text"
                        >
                            {isExpanded ? 'Cancel' : 'Edit'}
                        </Button>
                        <Button
                            color="error"
                            onClick={() => removeHhm(hhmIndex)}
                            sx={{ paddingX: 2 }}
                            variant="text"
                        >
                            Remove
                        </Button>
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails sx={{ paddingBottom: 3 }}>
                <Divider sx={{ marginBottom: 4 }} />
                {form}
            </AccordionDetails>
        </Accordion>
    </Stack>
);

export default hot(module)(HouseholdMemberCard);
