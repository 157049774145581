import { getTeamUserRoles } from 'actions/user/getTeamUserRoles';
import { HrsIntegrationProviders, PartnerTeams } from 'api/generated/enums';
import { ITeam, IUserTeamRelationship } from 'api/generated/models';
import {
    EditTeamAdvisor,
    GenerateHrsIntegrationKey,
    ViewHrsIntegrationKey,
} from 'api/generated/permissions';
import { ROLE_IDS } from 'api/generated/roleIds';
import { IValueType } from 'components/EditableAttribute';
import EditablePhoneNumberAttribute from 'components/EditablePhoneNumberAttribute';
import EditableSelectAttribute from 'components/EditableSelectAttribute';
import EditableTextAttribute from 'components/EditableTextAttribute';
import { ISaveEditableTextField } from 'components/EditableTextField';
import EditableYesNoSelectAttribute from 'components/EditableYesNoSelectAttribute';
import ProfileAttribute from 'components/ProfileAttribute';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import { startCase } from 'lodash';
import find from 'lodash/find';
import get from 'lodash/get';
import HrsIntegrationSection from 'pages/teamProfile/HrsIntegrationSection';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { generatePath, Link } from 'react-router-dom';
import { AppStore } from 'reducers/appReducer';
import { TEAMS_PEOPLE_PROFILE_PATH } from 'routers/routes';
import { hasSomePermissions } from 'selectors';
import { isRhSelector } from 'selectors/role';
import { enumToNameValueArray, getEnumElementByIndex, isTrue } from 'utilities';
import { onChange } from 'utilities/forms';
import { string } from 'yup';

const TWO = 2;

const PARTNER_TEAM_ITEMS = enumToNameValueArray(PartnerTeams, {
    formatName: startCase,
    nameKey: 'text',
    nameMap: { CareSource: 'CareSource' },
});

const hrsIntegrationTypeFormatter = (x: string | undefined) => startCase(x);

const HRS_INTEGRATION_TYPE_OPTIONS = (enumToNameValueArray(HrsIntegrationProviders, {
    formatName: hrsIntegrationTypeFormatter,
    nameKey: 'label',
}) as unknown) as { label: string; value: HrsIntegrationProviders }[];
const findTeamOwner = (teamRelationships: IUserTeamRelationship[]) => {
    const owner = teamRelationships.find((x) => isTrue(x.isOwner))?.user;
    return `${owner?.firstName ?? ''} ${owner?.lastName ?? ''}`;
};

const ThirdSection = ({
    readonly,
    save,
}: {
    readonly: boolean;
    save: ISaveEditableTextField<ITeam>;
}) => {
    const dispatch = useThunkDispatch();
    const { createdBy, team } = useTeamProps();
    const {
        advisementTeams,
        canEditAdvisementTeam,
        canGenerateHrsIntegrationKey,
        canViewHrsIntegrationKey,
        isRh,
        teamAdmins,
        teamRelationships,
    } = useSelector((state: AppStore) => ({
        advisementTeams: state.advisementTeams,
        canEditAdvisementTeam: hasSomePermissions(state, EditTeamAdvisor),
        canGenerateHrsIntegrationKey: hasSomePermissions(state, GenerateHrsIntegrationKey),
        canViewHrsIntegrationKey: hasSomePermissions(state, ViewHrsIntegrationKey),
        isRh: isRhSelector(state),
        representativeOptions: state.teamRepresentativeOptions,
        teamAdmins: state.teamUserRoles.filter((x) =>
            x.roleIds?.some((y) => y === ROLE_IDS.TEAM_ADMIN)
        ),
        teamRelationships: state.teamRelationships,
    }));
    const teamAdminOptions = teamAdmins.map((ta) => ({ id: ta.userId, name: ta.displayName }));
    const [teamAdminForEmail, setTeamAdminForEmail] = useState<string>(
        team?.teamAdminForEmailId ?? ''
    );
    const [brokerAgencyName, setBrokerAgencyName] = useState<string>(team?.brokerAgencyName ?? '');
    const [brokerAgentName, setBrokerAgentName] = useState<string>(team?.brokerAgentName ?? '');
    const [brokerEmail, setBrokerEmail] = useState<string>(team?.brokerEmail ?? '');
    const [capitalBlueCrossPaStockId, setCapitalBlueCrossPaStockId] = useState<string>(
        team?.capitalBlueCrossPaStockId ?? ''
    );
    const [brokerPhoneNumber, setBrokerPhoneNumber] = useState<string>(
        team?.brokerPhoneNumber ?? ''
    );
    const [advisementTeamId, setAdvisementTeamId] = useState(
        get(team, 'advisementTeamId', undefined)
    );
    const [partnerTeamId, setPartnerTeamId] = useState(team?.partnerTeamId);
    const [useMultiFactorAuthorization, setUseMultiFactorAuthorization] = useState(
        team?.useMultiFactorAuthorization.toString()
    );
    const [allowRememberDeviceForMfa, setAllowRememberDeviceForMfa] = useState(
        team?.allowRememberDeviceForMfa.toString()
    );
    const [hrsIntegrationProvider, setHrsIntegrationProvider] = useState<
        HrsIntegrationProviders | undefined
    >(team?.hrsIntegrationProvider);

    const getAdvisementTeamName = useCallback(
        (value) =>
            get(
                find(advisementTeams, (g) => g.teamId.toString() === value),
                'name',
                ''
            ),
        [advisementTeams]
    );
    const advisementTeamMenuItems = useMemo(
        () =>
            advisementTeams.map((p) => ({
                id: p.teamId,
                name: p.name,
            })),
        [advisementTeams]
    );
    const teamAdminNames = useMemo(
        () =>
            teamAdmins.map((x, i) => {
                const path = generatePath(TEAMS_PEOPLE_PROFILE_PATH, {
                    teamId: x.teamId,
                    userId: x.userId,
                });
                let separator;
                if (teamAdmins.length === 1 || i === teamAdmins.length - 1) {
                    separator = '';
                } else if (teamAdmins.length === TWO) {
                    separator = ' and ';
                } else {
                    separator = i === teamAdmins.length - TWO ? ', and ' : ', ';
                }
                return (
                    <React.Fragment key={x.userId}>
                        <Link to={path}>{x.displayName}</Link>
                        {separator}
                    </React.Fragment>
                );
            }),
        [teamAdmins]
    );

    const getTeamAdminName = (value: string) =>
        get(
            find(teamAdminOptions, (ta) => ta.id.toString() === value),
            'name',
            ''
        );

    const saveHrsIntegrationType = useCallback(
        async (name, value) => {
            await save(name, value);
        },
        [save]
    );

    const handleHrsIntegrationProviderChange = (value: number) => {
        const enumValue = getEnumElementByIndex(HrsIntegrationProviders, value);
        setHrsIntegrationProvider(enumValue);
    };

    useEffect(() => {
        if (team?.teamId && isRh) {
            dispatch(getTeamUserRoles(team.teamId, ROLE_IDS.TEAM_ADMIN));
        }
    }, [dispatch, isRh, team]);

    return (
        <React.Fragment>
            {isRh && (
                <React.Fragment>
                    <ProfileAttribute label="Team Admins">{teamAdminNames}</ProfileAttribute>
                    <EditableSelectAttribute
                        defaultText="Select an Admin"
                        formatter={(x: IValueType) => getTeamAdminName(x as string)}
                        infoTooltip="This can be used to specify a specific team administrator to use for the survey email. If not set, the first admin listed will be used."
                        items={teamAdminOptions}
                        label="Team Admin Name for Survey Email"
                        name="teamAdminForEmailId"
                        onChange={onChange(setTeamAdminForEmail)}
                        optionText="name"
                        optionValue="id"
                        readonly={readonly}
                        save={save}
                        validationSchema={string().label('Team Admin Name for Survey Email')}
                        value={teamAdminForEmail}
                    />
                    <ProfileAttribute label="Team Owner">
                        {findTeamOwner(teamRelationships)}
                    </ProfileAttribute>
                    <EditableTextAttribute
                        isOptional
                        label="Broker Agency Name"
                        name="brokerAgencyName"
                        onChange={onChange(setBrokerAgencyName)}
                        placeholder="Enter a broker agency name"
                        readonly={readonly}
                        save={save}
                        validationSchema={string()
                            .trim()
                            .label('Broker Agency Name')}
                        value={brokerAgencyName}
                    />
                    <EditableTextAttribute
                        isOptional
                        label="Broker Agent Name"
                        name="brokerAgentName"
                        onChange={onChange(setBrokerAgentName)}
                        placeholder="Enter a broker agent name"
                        readonly={readonly}
                        save={save}
                        validationSchema={string()
                            .trim()
                            .label('Broker Agent Name')}
                        value={brokerAgentName}
                    />
                    <EditableTextAttribute
                        isOptional
                        label="Broker Email"
                        name="brokerEmail"
                        onChange={onChange(setBrokerEmail)}
                        placeholder="Enter a broker email"
                        readonly={readonly}
                        save={save}
                        validationSchema={string()
                            .trim()
                            .label('Broker Email')}
                        value={brokerEmail}
                    />
                    <EditablePhoneNumberAttribute
                        isOptional
                        label="Broker Phone Number"
                        name="brokerPhoneNumber"
                        onChange={onChange(setBrokerPhoneNumber)}
                        placeholder="Enter a broker phone number"
                        readonly={readonly}
                        save={save}
                        validationSchema={string()
                            .isValidPhoneNumber(false)
                            .label('Broker Phone Number')}
                        value={brokerPhoneNumber}
                    />
                    <EditableYesNoSelectAttribute
                        infoTooltip="Turning this on will require members of this team to enter a code sent to their email in addition to entering their username and password to log into the platform."
                        label="Use Multi-Factor Authorization"
                        name="useMultiFactorAuthorization"
                        onChange={onChange(setUseMultiFactorAuthorization)}
                        optionText="text"
                        optionValue="value"
                        save={save}
                        value={useMultiFactorAuthorization}
                    />
                    <EditableYesNoSelectAttribute
                        infoTooltip="Turning this on will allow members to have their devices remebered. Remembered devices will only be asked for verification codes periodically."
                        label="Allow Remember Device for MFA"
                        name="allowRememberDeviceForMfa"
                        onChange={onChange(setAllowRememberDeviceForMfa)}
                        optionText="text"
                        optionValue="value"
                        save={save}
                        value={allowRememberDeviceForMfa}
                    />
                </React.Fragment>
            )}
            {canEditAdvisementTeam && (
                <EditableSelectAttribute
                    defaultText="Select an Advisement Team"
                    formatter={getAdvisementTeamName}
                    items={advisementTeamMenuItems}
                    label="Advisement Team"
                    name="advisementTeamId"
                    onChange={onChange(setAdvisementTeamId)}
                    optionText="name"
                    optionValue="id"
                    readonly={readonly}
                    save={save}
                    validationSchema={string().label('Advisement Team')}
                    value={advisementTeamId}
                />
            )}
            {canViewHrsIntegrationKey && (
                <EditableSelectAttribute
                    formatter={(x: IValueType) =>
                        hrsIntegrationTypeFormatter(HrsIntegrationProviders[x as number])
                    }
                    items={HRS_INTEGRATION_TYPE_OPTIONS}
                    label="HRS Integration Provider"
                    name="hrsIntegrationProvider"
                    onChange={onChange(handleHrsIntegrationProviderChange)}
                    optionText="label"
                    optionValue="value"
                    readonly={!canGenerateHrsIntegrationKey}
                    save={saveHrsIntegrationType}
                    validationSchema={string()
                        .label('HRS Integration Provider to Send')
                        .required()}
                    value={hrsIntegrationProvider}
                />
            )}
            {hrsIntegrationProvider !== null && <HrsIntegrationSection save={save} />}
            {isRh && (
                <React.Fragment>
                    <EditableTextAttribute
                        infoTooltip="This is an ID provided by Capital BlueCross in PA for use with the auto-enrollment process."
                        isOptional
                        label="Capital BlueCross PA Stock ID"
                        name="capitalBlueCrossPaStockId"
                        onChange={onChange(setCapitalBlueCrossPaStockId)}
                        placeholder="Enter a Capital BlueCross PA Stock ID"
                        readonly={readonly}
                        save={save}
                        validationSchema={string()
                            .trim()
                            .label('Capital BlueCross PA Stock ID')}
                        value={capitalBlueCrossPaStockId}
                    />
                    <EditableSelectAttribute
                        formatter={(x) => startCase(PartnerTeams[x as number])}
                        isOptional
                        items={PARTNER_TEAM_ITEMS}
                        label="Partner Team"
                        name="partnerTeamId"
                        onChange={onChange(setPartnerTeamId)}
                        optionText="text"
                        optionValue="value"
                        save={save}
                        validationSchema={string().label('Partner Team')}
                        value={partnerTeamId}
                    />
                    <ProfileAttribute label="Created By">{createdBy}</ProfileAttribute>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default hot(module)(ThirdSection);
