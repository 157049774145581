import { Divider, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { HouseholdMemberTypes, Tasks } from 'api/generated/enums';
import { IHouseholdMemberInfoDto, IHouseholdMembersDto, TaskDto } from 'api/generated/models';
import AddressComponent from 'pages/dashboard/ichraTaskFlow/taskFlowPages/householdSummaryPage/AddressComponent';
import SummaryRowItem from 'pages/dashboard/ichraTaskFlow/taskFlowPages/householdSummaryPage/SummaryRowItem';
import SummarySectionHeader from 'pages/dashboard/ichraTaskFlow/taskFlowPages/householdSummaryPage/SummarySectionHeader';
import React from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { formatDateForDisplay } from 'utilities/format';
import { arrayHasValue, hasValue } from 'utilities/index';

const HouseholdMembersSection = ({ tasks }: { tasks: TaskDto[] }) => {
    const { ssnTask } = useSelector((state: AppStore) => ({
        ssnTask: state.socialSecurityNumbers,
    }));
    const task = tasks.find((t) => t.taskId === Tasks.HouseholdMembers);
    const taskId = task?.globalId as string;
    const chunk = task?.response as IHouseholdMembersDto;
    const hhms = chunk.householdMembers as IHouseholdMemberInfoDto[];

    if (!arrayHasValue(hhms)) {
        return <React.Fragment />;
    }

    return (
        <Grid container item rowGap={2} xs={12}>
            <Grid item xs={12}>
                <SummarySectionHeader label="Household Members" taskId={taskId} />
                <Divider sx={{ md: { mb: 1 }, xs: { mb: 0 } }} />
            </Grid>
            <Grid columnSpacing={12} container item rowGap={6} xs={12}>
                {hhms.map((hhm) => {
                    const hhmType =
                        hhm.householdMemberTypeId === HouseholdMemberTypes.Spouse
                            ? 'Spouse'
                            : 'Child';
                    const hhmSsn = ssnTask.socialSecurityNumbers?.find(
                        (ssn) => ssn.entityKeyId === hhm.householdMemberId
                    )?.socialSecurityNumber;

                    return (
                        <Grid
                            container
                            direction="column"
                            item
                            key={hhm.householdMemberId}
                            md={6}
                            rowGap={1}
                            xs={12}
                        >
                            <Typography fontWeight={700} mb={1} variant="body1">
                                {hhmType} - {hhm.firstName}
                            </Typography>
                            <SummaryRowItem content={hhm.firstName} label="Legal First Name" />
                            <SummaryRowItem content={hhm.lastName} label="Last Name" />
                            {hasValue(hhm.preferredName) && (
                                <SummaryRowItem
                                    content={hhm.preferredName}
                                    label="Preferred First Name"
                                />
                            )}
                            <SummaryRowItem
                                content={hhm.gender === 'M' ? 'Male' : 'Female'}
                                label="Gender"
                            />
                            <SummaryRowItem
                                content={formatDateForDisplay(hhm.birthdate)}
                                label="Date of Birth"
                            />
                            <SummaryRowItem
                                content={hhm.usesTobacco ? 'Yes' : 'No'}
                                label="Tobacco Use"
                            />
                            {hhm.householdMemberTypeId === HouseholdMemberTypes.Spouse && (
                                <SummaryRowItem
                                    content={hhm.sameEmployer ? 'Yes' : 'No'}
                                    label="Same Employer as Primary"
                                />
                            )}
                            <SummaryRowItem content={hhmSsn} label="SSN" useMask />
                            {hhm.address && <AddressComponent address={hhm.address} hideHeader />}
                        </Grid>
                    );
                })}
            </Grid>
        </Grid>
    );
};

export default hot(module)(HouseholdMembersSection);
