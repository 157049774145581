import { Grid, Typography } from '@mui/material';
import { listVisibleFlows } from 'actions/taskFlows/listVisibleFlows';
import { getUserProfile } from 'actions/user/getUserProfile';
import { EnrollmentStatuses, Tasks } from 'api/generated/enums';
import { IMemberTermsDto, TaskDto } from 'api/generated/models';
import CalendlyModalButton from 'components/calendly/CalendlyModalButton';
import { useSetUserAdvisementScheduled } from 'hooks/useSetUserAdvisementScheduled';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import {
    MAX_WIDTH,
    getPreviousTask,
    getSupportedEnrollmentUserFlow,
} from 'pages/dashboard/ichraTaskFlow/ichraFlowUtilities';
import IchraFlowTitle from 'pages/dashboard/ichraTaskFlow/taskFlowPages/components/IchraFlowTitle';
import React, { useEffect } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasValue } from 'utilities/index';

const AdvisementNeededPage = () => {
    const { isCurrent, userId, yearlyUserInfo } = useUserProps();
    const dispatch = useThunkDispatch();
    const setUserAdvisementScheduled = useSetUserAdvisementScheduled();
    const { userFlows } = useSelector((state: AppStore) => ({
        userFlows: state.userFlows,
    }));

    const termsTask = getPreviousTask(
        getSupportedEnrollmentUserFlow(userFlows)?.tasks as TaskDto[],
        Tasks.MemberTerms,
        Tasks.SelectBenefits
    );

    let content = (
        <React.Fragment>
            <Typography marginBottom={3} variant="body1">
                Based on the information you&apos;ve shared with us, we&apos;d like to connect you
                with one of our licensed advisors to talk through your specific plan options.
                <br />
                Please click &quot;Schedule Advisement&quot; to find a time that works for you!
            </Typography>
            <CalendlyModalButton
                buttonLabel="Schedule Advisement"
                memberTermsTask={termsTask}
                onAdvisementScheduled={async () => setUserAdvisementScheduled()}
                showAgreeToTerms={!hasValue((termsTask?.response as IMemberTermsDto)?.downloadUrl)}
                variant="contained"
            />
        </React.Fragment>
    );
    if (yearlyUserInfo?.enrollmentStatus?.value === EnrollmentStatuses.AdvisementScheduled) {
        content = (
            <Typography marginBottom={3} variant="body1">
                You&apos;ve scheduled your advisement! Check your email for a confirmation from
                Calendly.
            </Typography>
        );
    }

    useEffect(() => {
        dispatch(getUserProfile(userId, isCurrent));
        if (!hasValue(userFlows)) {
            dispatch(listVisibleFlows(userId));
        }
    }, [dispatch, isCurrent, userFlows, userId]);

    return (
        <Grid
            alignItems="center"
            container
            direction="column"
            gap={3}
            item
            maxWidth={MAX_WIDTH}
            width="100%"
        >
            <IchraFlowTitle title="Talk with an advisor!" />
            {content}
        </Grid>
    );
};

export default hot(module)(AdvisementNeededPage);
