import { getTeamPathwayBlueprints } from 'actions/pathwayBlueprint/getTeamPathwayBlueprints';
import { getTeamRelationships } from 'actions/team/getTeamRelationships';
import {
    TRANSITION_TO_RH_LAUNCH_ACTION,
    transitionToRhLaunch,
} from 'actions/team/transitionToRhLaunch';
import { RelationshipTypeIds } from 'api/generated/enums';
import { IPathwayBlueprint } from 'api/generated/models';
import { ViewPathwayBlueprints } from 'api/generated/permissions';
import ApplyingStaleContributionsWarning from 'components/ApplyingStaleContributionsWarning';
import Button from 'components/Button';
import ConfirmationModal from 'components/ConfirmationModal';
import useModalState from 'hooks/useModalState';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import { some } from 'lodash';
import HandoffToLaunchModal, { IRequirement } from 'pages/dashboard/HandoffToLaunchModal';
import React, { useCallback, useEffect, useMemo } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasSomePermissions } from 'selectors';
import { hasApiActivity } from 'selectors/activity';

const HandoffToLaunch = ({ teamId }: { teamId: string }) => {
    const dispatch = useThunkDispatch();
    const {
        activeYear,
        canViewPathwayBlueprints,
        hasEngagementRep,
        hasLaunchRep,
        hasTeamActiveDate,
        isLoading,
        pathwayBlueprint,
    } = useSelector((state: AppStore) => ({
        activeYear: state?.teamProfile?.team?.activeDate?.getYear(),
        canViewPathwayBlueprints: hasSomePermissions(state, ViewPathwayBlueprints),
        hasEngagementRep: some(
            state.teamRelationships,
            (x) =>
                x.relationshipType?.teamRelationshipTypeId ===
                RelationshipTypeIds.EngagementRepresentative
        ),
        hasLaunchRep: some(
            state.teamRelationships,
            (x) =>
                x.relationshipType?.teamRelationshipTypeId ===
                RelationshipTypeIds.LaunchRepresentative
        ),
        hasTeamActiveDate: state?.teamProfile?.team?.activeDate ? true : false,
        isLoading: hasApiActivity(state, TRANSITION_TO_RH_LAUNCH_ACTION),
        pathwayBlueprint: state.pathwayBlueprints.find(
            (x) =>
                x.isActive &&
                x.year === state?.teamProfile?.team?.activeDate?.getYear() &&
                x?.teamId === teamId
        ),
    }));
    const { hasTeam } = useTeamProps();
    const hasActivePBP = !!pathwayBlueprint;
    const requirements = useMemo(
        () =>
            [
                {
                    description: 'Engagement Rep Set',
                    requirementMet: hasEngagementRep ? true : false,
                },
                { description: 'Launch Rep Set', requirementMet: hasLaunchRep ? true : false },
                {
                    description: 'Team Active Date Set',
                    requirementMet: hasTeamActiveDate ? true : false,
                },
                {
                    description: `Active Pathway Blueprint for ${activeYear ??
                        'Team Active Year'} Set`,
                    requirementMet: hasActivePBP,
                },
            ] as IRequirement[],
        [activeYear, hasActivePBP, hasEngagementRep, hasLaunchRep, hasTeamActiveDate]
    );

    useEffect(() => {
        if (hasTeam && canViewPathwayBlueprints) {
            dispatch(getTeamPathwayBlueprints(teamId));
        }
        dispatch(getTeamRelationships(teamId));
    }, [canViewPathwayBlueprints, dispatch, hasTeam, teamId]);
    const {
        openModal: openLaunchModal,
        closeModal: closeLaunchModal,
        isVisible: isLaunchModalVisible,
    } = useModalState();
    const {
        closeModal: closeConfirmationModal,
        isVisible: isConfirmationModalVisible,
        openModal: openConfirmationModal,
    } = useModalState();
    const attemptTransition = useCallback(() => {
        if (hasEngagementRep && hasLaunchRep && hasTeamActiveDate && hasActivePBP) {
            openConfirmationModal();
        } else {
            openLaunchModal();
        }
    }, [
        hasActivePBP,
        hasEngagementRep,
        hasLaunchRep,
        hasTeamActiveDate,
        openConfirmationModal,
        openLaunchModal,
    ]);

    return (
        <React.Fragment>
            {isLaunchModalVisible && (
                <HandoffToLaunchModal onClose={closeLaunchModal} requirements={requirements} />
            )}
            {isConfirmationModalVisible && (
                <ConfirmationModal
                    body={
                        <React.Fragment>
                            <p>Are you sure you want to transition this team to Launch?</p>
                            <p>
                                All Projected Wage+ amounts and Reimbursements on the Active Pathway
                                Blueprint will become Active for each member. Members in the Unknown
                                Pathway or with calculation errors will not be given an Active Wage+
                                or Reimbursement.
                            </p>
                            <p>
                                <strong className="mb-1">
                                    This should only be done if the team has signed a contract.
                                </strong>
                            </p>
                            <ApplyingStaleContributionsWarning
                                pathwayBlueprint={pathwayBlueprint as Partial<IPathwayBlueprint>}
                            />
                        </React.Fragment>
                    }
                    onNoClick={closeConfirmationModal}
                    onYesClick={async () => {
                        await dispatch(transitionToRhLaunch(teamId));
                        closeConfirmationModal();
                    }}
                    showActivity={isLoading}
                    title="Confirm Transition from Prospect to Launch"
                />
            )}
            <Button
                data-cy="hand-off-to-launch"
                isLoading={isLoading}
                onClick={attemptTransition}
                xsClassName="mb-4"
            >
                Hand this team off to Launch
            </Button>
        </React.Fragment>
    );
};

export default hot(module)(HandoffToLaunch);
