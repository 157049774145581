import { clearCounties } from 'actions/clear';
import { HouseholdMemberTypes } from 'api/generated/enums';
import { AddressDto, HouseholdMemberInfoDto } from 'api/generated/models';
import {
    clearAddressInputs,
    updateAddressInputs,
    validateAddressInputs,
} from 'components/addressInput/addressInputActions';
import { IAddressInputs } from 'components/addressInput/addressInputState';
import useForm from 'hooks/useForm';
import useThunkDispatch from 'hooks/useThunkDispatch';
import { householdMembersSchema } from 'pages/dashboard/ichraTaskFlow/ichraFlowSchema';
import {
    HhmContext,
    householdMemberCheckForEdits,
} from 'pages/dashboard/ichraTaskFlow/ichraFlowUtilities';
import AddHouseholdMemberContent from 'pages/dashboard/ichraTaskFlow/taskFlowPages/householdMembersPage/AddHouseholdMemberContent';
import HouseholdMemberCard from 'pages/dashboard/ichraTaskFlow/taskFlowPages/householdMembersPage/HouseholdMemberCard';
import HouseholdMemberForm from 'pages/dashboard/ichraTaskFlow/taskFlowPages/householdMembersPage/HouseholdMemberForm';
import React, { useContext, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { formatDateForDisplay } from 'utilities/format';
import { hasValue } from 'utilities/index';

export const modifyHouseholdMember = (
    field: string,
    hasSameAddress: boolean,
    hhm: HouseholdMemberInfoDto,
    value: boolean | string
) => {
    let newHhm;
    let newSameAddressValue = hasSameAddress;
    switch (field) {
        case 'firstName':
            newHhm = { ...hhm, firstName: value as string };
            break;
        case 'lastName':
            newHhm = { ...hhm, lastName: value as string };
            break;
        case 'preferredName':
            newHhm = { ...hhm, preferredName: value as string };
            break;
        case 'gender':
            newHhm = { ...hhm, gender: value as string };
            break;
        case 'birthdate':
            newHhm = { ...hhm, birthdate: value as string };
            break;
        case 'usesTobacco':
            newHhm = { ...hhm, usesTobacco: value as boolean };
            break;
        case 'sameEmployer':
            newHhm = { ...hhm, sameEmployer: value as boolean };
            break;
        case 'sameAddress':
            if (hasSameAddress) {
                newHhm = { ...hhm };
                newSameAddressValue = false;
            } else {
                newHhm = { ...hhm, address: undefined };
                newSameAddressValue = true;
            }
            break;
        default:
            break;
    }
    return { newHhm, newSameAddressValue };
};

const HouseholdMemberContent = ({
    editHhms,
    hhmIndex,
    hhmType,
    householdMember,
    removeHhm,
}: {
    editHhms: (hhm: HouseholdMemberInfoDto, hhmIndex: number) => void;
    hhmIndex?: number;
    hhmType: HouseholdMemberTypes;
    householdMember?: HouseholdMemberInfoDto;
    removeHhm: (hhmIndex: number) => void;
}) => {
    const { addressInputs } = useSelector((state: AppStore) => ({
        addressInputs: state.addressInputState.addressInputs,
        genderList: state.options.genderList,
    }));
    const { errors, validate } = useForm(householdMembersSchema);
    const dispatch = useThunkDispatch();
    const {
        hhmExpanded,
        isAddingNewHhm,
        newHhmType,
        setHhmExpanded,
        setIsAddingNewHhm,
        setNewHhmType,
    } = useContext(HhmContext);

    const isNewHhm = !hasValue(hhmIndex);
    const isSpouse = hhmType === HouseholdMemberTypes.Spouse;

    const [hhm, setHhm] = useState<HouseholdMemberInfoDto>(
        new HouseholdMemberInfoDto({
            ...householdMember,
            birthdate: formatDateForDisplay(householdMember?.birthdate) ?? '',
            householdMemberTypeId: hhmType,
            sameEmployer: householdMember?.sameEmployer,
            usesTobacco: householdMember?.usesTobacco as boolean,
        })
    );
    const [hasSameAddress, setHasSameAddress] = useState<boolean>(
        !hasValue(householdMember?.address)
    );
    const isEdited = householdMemberCheckForEdits(hasSameAddress, householdMember, hhm);

    const hhmTypeLabel = isSpouse ? 'Spouse' : 'Child';
    const showNewAddForm = isAddingNewHhm && newHhmType === hhmType && !hasValue(hhmExpanded);

    const handleAddButtonClick = () => {
        setHhmExpanded?.(undefined);
        setNewHhmType?.(hhmType);
        setIsAddingNewHhm?.(true);
        dispatch(clearAddressInputs());
        dispatch(clearCounties());
    };

    const handleEditCancelButtonClick = async () => {
        if (!hasValue(hhm.address)) {
            dispatch(clearAddressInputs());
            dispatch(clearCounties());
        } else {
            dispatch(updateAddressInputs({ ...hhm.address } as IAddressInputs));
        }
        setIsAddingNewHhm?.(false);
        setHhmExpanded?.(hhmExpanded === hhmIndex ? undefined : hhmIndex);
    };

    const saveChanges = async () => {
        const { isValid } = await validate({
            birthdate: hhm.birthdate,
            firstName: hhm.firstName,
            gender: hhm.gender,
            lastName: hhm.lastName,
            preferredName: hhm.preferredName,
            sameEmployer: hhm.sameEmployer,
            usesTobacco: hhm.usesTobacco,
        });

        hhm.householdMemberTypeId = isSpouse
            ? HouseholdMemberTypes.Spouse
            : HouseholdMemberTypes.IchraChild;
        hhm.address = hasSameAddress
            ? undefined
            : new AddressDto({
                  addressLine1: addressInputs.addressLine1,
                  addressLine2: addressInputs.addressLine2,
                  city: addressInputs.city,
                  county: addressInputs.county,
                  countyFips: addressInputs.countyFips,
                  state: addressInputs.state,
                  zip: addressInputs.zip,
              });

        const isValidAddress =
            hasSameAddress || (await dispatch(validateAddressInputs(addressInputs)));

        if (isValid && isValidAddress) {
            setHhmExpanded?.(undefined);
            editHhms(hhm, hhmIndex ?? -1);
            setIsAddingNewHhm?.(false);
            if (isNewHhm) {
                setHhm({} as HouseholdMemberInfoDto);
            }
        }
    };

    const handleHhmChange = async (field: string, value: boolean | string) => {
        const { newHhm, newSameAddressValue } = modifyHouseholdMember(
            field,
            hasSameAddress,
            hhm,
            value
        );
        setHhm(newHhm as HouseholdMemberInfoDto);
        setHasSameAddress(newSameAddressValue);
    };

    const hhmForm = (
        <HouseholdMemberForm
            errors={errors}
            handleHhmChange={handleHhmChange}
            hasSameAddress={hasSameAddress}
            hhm={hhm}
            hhmIndex={hhmIndex}
            hhmLabel={hhmTypeLabel}
            isNewHhm={isNewHhm}
            isSpouse={isSpouse}
            saveChanges={saveChanges}
        />
    );

    if (isNewHhm) {
        return (
            <AddHouseholdMemberContent
                form={hhmForm}
                handleAddButtonClick={handleAddButtonClick}
                hhmLabel={hhmTypeLabel}
                setIsAddingNewHhm={setIsAddingNewHhm}
                showForm={showNewAddForm as boolean}
            />
        );
    }

    return (
        <HouseholdMemberCard
            form={hhmForm}
            handleEditCancelButtonClick={handleEditCancelButtonClick}
            hhmIndex={hhmIndex}
            initialHhm={householdMember}
            isEdited={isEdited}
            isExpanded={hhmIndex === hhmExpanded}
            removeHhm={removeHhm}
        />
    );
};

export default hot(module)(HouseholdMemberContent);
