import { Grid } from '@mui/material';
import { getStates } from 'actions/getStates';
import AddressInput from 'components/addressInput/AddressInput';
import { validateAddressInputs } from 'components/addressInput/addressInputActions';
import PhoneTextField from 'components/PhoneTextField';
import TextField from 'components/TextField';
import useForm from 'hooks/useForm';
import useThunkDispatch from 'hooks/useThunkDispatch';
import FormTitle from 'pages/survey/FormTitle';
import { saveContactForm } from 'pages/survey/surveyActions';
import SurveyFormWrapper from 'pages/survey/SurveyFormWrapper';
import React, { useCallback, useEffect } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { object, string } from 'yup';

const schema = object({
    email: string()
        .trim()
        .email()
        .required()
        .label('Email'),
    phone: string()
        .required()
        .isValidPhoneNumber()
        .label('Phone'),
});

const ContactForm = () => {
    const dispatch = useThunkDispatch();
    const { address, contact, year } = useSelector((state: AppStore) => ({
        address: state.addressInputState.addressInputs,
        contact: { ...state.surveyState.contact },
        year: state.surveyState.incomeYear,
    }));
    const { errors, validate } = useForm(schema);

    useEffect(() => {
        dispatch(getStates());
    }, [dispatch]);

    const onChange = useCallback(
        async ({ target: { name, value } }) => dispatch(saveContactForm({ [name]: value })),
        [dispatch]
    );
    const handleSubmit = useCallback(async () => {
        const isValidAddress = await dispatch(validateAddressInputs(address));
        const { isValid } = await validate(contact);
        if (isValidAddress && isValid) {
            dispatch(saveContactForm({ ...contact, ...address }));
            return true;
        }
        return false;
    }, [dispatch, address, validate, contact]);

    return (
        <SurveyFormWrapper onSubmit={handleSubmit}>
            <FormTitle>Your Contact Information</FormTitle>

            <Grid container justifyContent="center" spacing={2}>
                <Grid item lg={6} xs={12}>
                    <TextField
                        data-cy="email"
                        disabled
                        errors={errors?.email}
                        label="Email"
                        name="email"
                        onChange={onChange}
                        placeholder="Enter an email address"
                        value={contact.email}
                    />
                </Grid>
                <Grid item lg={6} xs={12}>
                    <PhoneTextField
                        autoComplete="off"
                        autoFocus
                        errors={errors?.phone}
                        label="Phone"
                        name="phone"
                        onChange={onChange}
                        placeholder="Your phone number"
                        value={contact.phone}
                    />
                </Grid>

                <Grid item xs={12}>
                    <AddressInput address={contact} year={year} />
                </Grid>
            </Grid>
        </SurveyFormWrapper>
    );
};

export default hot(module)(ContactForm);
