import CardActions from '@mui/material/CardActions';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { IneligibleToKeepReasons, PlanTypeIds } from 'api/generated/enums';
import { IComparedSelectedPlan, ISelectedPlan } from 'api/generated/models';
import Icon from 'components/Icon';
import useUserProps from 'hooks/useUserProps';
import isEmpty from 'lodash/isEmpty';
import Comparison from 'pages/dashboard/taskLists/member/tasks/keepOrChange/Comparison';
import {
    getAreMarketplaceRatesAvailable,
    getShouldHideCosts,
} from 'pages/dashboard/taskLists/member/tasks/keepOrChange/keepOrChangeSelectors';
import React, { ReactNode, useMemo } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasValue } from 'utilities';

export const SwapIcon = () => <Icon variant="primary">swap</Icon>;

const needHelpMessage = <em>Need help making a decision?</em>;

const getIneligibleContent = (ineligibleReason: IneligibleToKeepReasons | undefined) => {
    switch (ineligibleReason) {
        case IneligibleToKeepReasons.PlanDiscontinued:
            return <DiscontinuedFooter />;
        case IneligibleToKeepReasons.IneligibleForPlan:
            return <IneligibleFooter />;
        case IneligibleToKeepReasons.HouseholdEligibilityChanges:
            return <GainedOtherEligibilityFooter />;
        case IneligibleToKeepReasons.IncludesUnclaimedMembers:
            return <UnclaimedMembersFooter />;
        default:
            return <React.Fragment />;
    }
};

const WarningFooter = ({
    children,
    sharedMessage = null,
}: {
    children: ReactNode;
    sharedMessage?: ReactNode;
}) => (
    <div>
        <Icon className="mr-1" variant="warning">
            warning
        </Icon>
        {children}{' '}
        {sharedMessage ?? (
            <React.Fragment>
                If you have questions, please reach out to us through one of the links in the{' '}
                {needHelpMessage} section below.
            </React.Fragment>
        )}
    </div>
);

const IneligibleFooter = () => (
    <WarningFooter>You are ineligible for this plan due to household changes.</WarningFooter>
);

const DiscontinuedFooter = () => (
    <WarningFooter>Your previous plan has left the market.</WarningFooter>
);

const GainedOtherEligibilityFooter = () => (
    <WarningFooter
        sharedMessage={
            <React.Fragment>
                To discuss your options, please schedule an advisement with us to using the link in
                the {needHelpMessage} section below.
            </React.Fragment>
        }
    >
        Your eligibility for other coverage has changed. Members of your household may no longer be
        eligible for a premium tax credit.
    </WarningFooter>
);

const UnclaimedMembersFooter = () => (
    <WarningFooter
        sharedMessage={
            <React.Fragment>
                For info on your plan&apos;s cost and benefits changes, please schedule an
                advisement with us using the button below.
            </React.Fragment>
        }
    >
        Cannot retrieve plan changes for next year for plans with household members not claimed on
        the primary member&apos;s taxes.
    </WarningFooter>
);

const KeepOrChangeBenefitCardFooter = ({
    className = '',
    selectedPlan,
}: {
    className: string;
    selectedPlan: IComparedSelectedPlan | ISelectedPlan;
}) => {
    const { year } = useUserProps();
    const { areMarketplaceRatesAvailable, shouldHideCost } = useSelector((state: AppStore) => ({
        areMarketplaceRatesAvailable: getAreMarketplaceRatesAvailable(state, year),
        shouldHideCost: getShouldHideCosts(state),
    }));

    const showRatesNotAvailable = useMemo(
        () => !areMarketplaceRatesAvailable && selectedPlan.planTypeId === PlanTypeIds.Marketplace,
        [areMarketplaceRatesAvailable, selectedPlan.planTypeId]
    );
    const comparedSelectedPlan = selectedPlan as IComparedSelectedPlan;
    let content: JSX.Element | undefined;
    if (showRatesNotAvailable) {
        content = (
            <span>
                <Icon variant="info">question</Icon> Rates are not available until November 1st.
            </span>
        );
    } else if (
        !shouldHideCost &&
        (!isEmpty(comparedSelectedPlan.comparisons) || comparedSelectedPlan.isPlanCrosswalked)
    ) {
        content = (
            <React.Fragment>
                {comparedSelectedPlan.isPlanCrosswalked && (
                    <div>
                        <SwapIcon /> Your previous plan has left the market, so we’ve quoted you
                        with a similar plan.
                    </div>
                )}
                {comparedSelectedPlan.comparisons?.map((comparison, index) => (
                    <div key={index}>
                        <Comparison comparison={comparison} />
                    </div>
                ))}
            </React.Fragment>
        );
    }
    if (hasValue(comparedSelectedPlan.ineligibleToKeepReason)) {
        content = getIneligibleContent(comparedSelectedPlan.ineligibleToKeepReason);
    }

    return content ? (
        <React.Fragment>
            <Divider />
            <CardActions className={className}>
                <Stack>{content}</Stack>
            </CardActions>
        </React.Fragment>
    ) : (
        <React.Fragment />
    );
};

export default hot(module)(KeepOrChangeBenefitCardFooter);
