import { PaylocityIntegrationStatuses } from 'api/generated/enums';
import { ITeam } from 'api/generated/models';
import { GenerateHrsIntegrationKey, ViewHrsIntegrationKey } from 'api/generated/permissions';
import Button from 'components/Button';
import CopyText from 'components/CopyText';
import EditableTextAttribute from 'components/EditableTextAttribute';
import { ISaveEditableTextField } from 'components/EditableTextField';
import ProfileAttribute from 'components/ProfileAttribute';
import useTeamProps from 'hooks/useTeamProps';
import startCase from 'lodash/startCase';
import React, { useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasSomePermissions } from 'selectors/index';
import { onChange } from 'utilities/forms';
import { hasValue } from 'utilities/index';
import { v4 as guid } from 'uuid';
import { string } from 'yup';

const PaylocityIntegrationSection = ({ save }: { save: ISaveEditableTextField<ITeam> }) => {
    const { canGeneratePaylocityIntegrationKey, canViewPaylocityIntegrationKey } = useSelector(
        (state: AppStore) => ({
            canGeneratePaylocityIntegrationKey: hasSomePermissions(
                state,
                GenerateHrsIntegrationKey
            ),
            canViewPaylocityIntegrationKey: hasSomePermissions(state, ViewHrsIntegrationKey),
        })
    );

    const { team } = useTeamProps();
    const [paylocityIntegrationKey, setPaylocityIntegrationKey] = useState(
        team?.paylocityIntegrationKey ?? ''
    );
    const [orgId, setOrgId] = useState(team?.paylocityOrganizationId ?? '');
    const hasIntegrationKey = hasValue(paylocityIntegrationKey);
    const showIntegrationStatus = canViewPaylocityIntegrationKey && hasIntegrationKey;

    const generateNewPaylocityIntegrationKey = () => {
        const newKey = guid();
        setPaylocityIntegrationKey(newKey);
        save('paylocityIntegrationKey', newKey);
    };

    const getAttributeContent = () => {
        if (!hasValue(paylocityIntegrationKey)) {
            return (
                <React.Fragment>
                    {canGeneratePaylocityIntegrationKey && (
                        <Button
                            onClick={generateNewPaylocityIntegrationKey}
                            size="small"
                            variant="outlined"
                        >
                            Generate Key
                        </Button>
                    )}
                </React.Fragment>
            );
        }

        return <CopyText text={paylocityIntegrationKey}>{paylocityIntegrationKey}</CopyText>;
    };

    return (
        <React.Fragment>
            {(showIntegrationStatus || canGeneratePaylocityIntegrationKey) && (
                <ProfileAttribute label="Paylocity Integration Key">
                    {getAttributeContent()}
                </ProfileAttribute>
            )}
            {showIntegrationStatus && (
                <React.Fragment>
                    <ProfileAttribute label="Paylocity Integration Status">
                        {startCase(
                            PaylocityIntegrationStatuses[
                                team?.paylocityIntegrationStatus ??
                                    PaylocityIntegrationStatuses.NotConfigured
                            ]
                        )}
                    </ProfileAttribute>
                    <EditableTextAttribute
                        infoTooltip="The organization's Company ID in Paylocity"
                        label="Paylocity Organization ID"
                        name="paylocityOrganizationId"
                        onChange={onChange(setOrgId)}
                        save={save}
                        validationSchema={string()
                            .trim()
                            .label('Paylocity Organization ID')}
                        value={orgId}
                    />
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default hot(module)(PaylocityIntegrationSection);
