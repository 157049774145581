import { IMediSharePlan } from 'api/generated/models';
import { MEDISHARE_GUIDELINES_URL } from 'constants/miscUrls';
import MediSharePlanCardContent from 'pages/shop/MediSharePlanCardContent';
import PlanCardBase from 'pages/shop/PlanCardBase';
import { IShoppingPlan } from 'pages/shop/shopping';
import React from 'react';
import { hot } from 'react-hot-loader';

export const MEDISHARE_PLAN_NAME = 'Medi-Share Program';

const MediSharePlanCard = ({
    dataCyPrefix,
    handleSelectPlan,
    hideCost,
    householdMembersCovered,
    isDisabled,
    pathwayPlanCardHeader,
    plan,
}: {
    dataCyPrefix?: string;
    handleSelectPlan?: (plan: Partial<IShoppingPlan>) => Promise<void>;
    hideCost?: boolean;
    householdMembersCovered?: string[];
    isDisabled?: boolean;
    pathwayPlanCardHeader?: JSX.Element;
    plan: Partial<IShoppingPlan>;
}) => {
    const mediSharePlan = plan as IMediSharePlan;
    const planCost = Math.round(mediSharePlan.totalAmount);
    return (
        <PlanCardBase
            benefitDetailsButtonHref={MEDISHARE_GUIDELINES_URL}
            benefitDetailsButtonText="Program Details"
            cost={planCost}
            costWithCredit={planCost}
            dataCyPrefix={dataCyPrefix}
            handleSelectPlan={handleSelectPlan}
            hideYourCost={hideCost}
            householdMembersCovered={householdMembersCovered}
            isDisabled={isDisabled}
            name={MEDISHARE_PLAN_NAME}
            pathwayPlanCardHeader={pathwayPlanCardHeader}
            plan={plan}
            planCardContent={<MediSharePlanCardContent plan={mediSharePlan} />}
            selectButtonText="Select Program"
        />
    );
};

export default hot(module)(MediSharePlanCard);
