import BlockIcon from '@mui/icons-material/Block';
import CheckIcon from '@mui/icons-material/Check';
import React from 'react';
import { hot } from 'react-hot-loader';

const AncillarySelectionStatus = ({
    hasCompletedAncillaryTask,
}: {
    hasCompletedAncillaryTask: boolean;
}) => (
    <React.Fragment>
        {hasCompletedAncillaryTask ? (
            <CheckIcon color="secondary" fontSize="medium" />
        ) : (
            <BlockIcon color="warning" fontSize="medium" />
        )}
    </React.Fragment>
);

export default hot(module)(AncillarySelectionStatus);
