import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { GroupLevels, PlanTypeIds, UserStatus } from 'api/generated/enums';
import { IPayrollReportUserSnapshotMajorMedicalPlan } from 'api/generated/models';
import classNames from 'classnames';
import {
    COVERAGE_LEVEL_COLUMN_ID,
    COVERAGE_LEVEL_FILTER_ID,
    COVERAGE_LEVEL_OPTIONS,
    CoverageLevelHeader,
} from 'components/commonTableComponents/CoverageLevelHeader';
import {
    MEMBER_STATUS_COLUMN_ID,
    MEMBER_STATUS_FILTER_ID,
    MemberStatusHeader,
    USER_STATUS_OPTIONS,
} from 'components/commonTableComponents/MemberStatusHeader';
import HeaderDropdown from 'components/headerDropdown/HeaderDropdown';
import InformationIconTooltip from 'components/InformationIconTooltip';
import { PLAN_TYPE_ITEMS } from 'constants/selectedPlans';
import {
    ADJUSTMENT_WIDTH,
    ALIGN_ITEMS_START,
    BORDER_LEFT,
    BORDER_RIGHT,
    BORDER_X,
    CENTER_AND_ALIGN,
    CENTER_AND_OVERFLOW_AND_ALIGN,
    CENTER_CONTENT,
    MARGIN_LEFT_2,
} from 'constants/styleConstants';
import { sumBy } from 'lodash';
import { AdjustmentTypes } from 'pages/payrollReports/PayrollReportAdjustmentModal';
import { EmployerContributionTypes } from 'pages/payrollReports/PayrollReportCustomEmployerContributionModal';
import { IPayrollReportRow } from 'pages/payrollReports/PayrollReportsPage';
import {
    AdjustmentCell,
    ContributionCell,
    CurrencyCell,
    IndicatorCell,
    MajorMedicalBenefitTypeCell,
    NameCell,
    PayrollDeductionsActionButton,
    TaxCodeCell,
    TotalWithSummaryCell,
} from 'pages/payrollReports/PayrollReportTableCells';
import React, { ReactNode } from 'react';
import { Cell, Column, HeaderProps } from 'react-table';
import { getUniqueListBy, hasValue, roundTwoDecimals } from 'utilities';
import { formatCurrency, formatYesOrNo } from 'utilities/format';
import { getPlanTypeName } from 'utilities/plans';
import { CustomColumn } from 'utilities/reactTable';

export const NAME_COLUMN_ID = 'name';
export const PAYROLL_SYSTEM_ID_COLUMN_ID = 'payrollSystemId';
export const MAJOR_MEDICAL_BENEFIT_TYPE_FILTER_ID = 'majorMedicalBenefitTypeFilter';
export const MAJOR_MEDICAL_BENEFIT_TYPE_COLUMN_ID = 'majorMedicalBenefitType';
export const MAJOR_MEDICAL_BENEFIT_TYPE_PARAM = 'Major Medical Benefit Type';

export const MAJOR_MEDICAL_BENEFIT_TYPE_OPTIONS = [
    ...new Set(
        PLAN_TYPE_ITEMS.filter(
            (x) => ![PlanTypeIds.Ancillary, PlanTypeIds.CustomAncillary].contains(x.value)
        ).map((x) => getPlanTypeName(x.value))
    ),
    'None',
];

const getStatusText = (status: UserStatus | undefined) =>
    USER_STATUS_OPTIONS[status as UserStatus] ?? '';
const getCoverageLevelText = (groupLevel: GroupLevels | undefined) =>
    COVERAGE_LEVEL_OPTIONS[groupLevel as GroupLevels] ?? '';

const conditionalCell = <T extends Cell<IPayrollReportRow>>(
    CellComponent: React.FC<T> | ((cellData: T) => ReactNode),
    condition: (cellData: T) => boolean
) => (cellData: T): ReactNode => (condition(cellData) ? CellComponent(cellData) : React.Fragment);

const parentRowOnly = <T extends Cell<IPayrollReportRow>>(
    CellComponent: React.FC<T> | ((cellData: T) => ReactNode)
) => (cellData: T): ReactNode =>
    cellData.row.original.isParentRow ? CellComponent(cellData) : React.Fragment;

// eslint-disable-next-line react/display-name
const totalHeader = (title: string, accessor: (x: IPayrollReportRow) => number) => ({
    column,
    rows,
}: HeaderProps<IPayrollReportRow>) => (
    <Stack>
        <span>{title}</span>
        <Divider sx={{ my: 1 }} />
        <span data-cy={column.id}>
            {formatCurrency(
                sumBy(rows, (x) => roundTwoDecimals(accessor(x.original))),
                { preserveDecimal: true }
            )}
        </span>
    </Stack>
);

const getIndicatorColumn = (
    isLocked: boolean
): CustomColumn<IPayrollReportRow, { isLocked: boolean }> => ({
    isLocked,
    accessor: ({ note }) => note,
    Cell: parentRowOnly(IndicatorCell),
    className: `${CENTER_CONTENT} pr-2`,
    disableGlobalFilter: true,
    headerClassName: `${CENTER_CONTENT} pr-2`,
    id: 'note',
    width: 100,
});

const getMajorMedicalBenefitTypeAccessor = (
    majorMedicalPlans: IPayrollReportUserSnapshotMajorMedicalPlan[] | undefined
) => {
    const uniqueMajorMedicalPlans = getUniqueListBy(majorMedicalPlans, 'planType');
    if (!hasValue(majorMedicalPlans) || majorMedicalPlans.length === 0) {
        return '';
    } else if ((uniqueMajorMedicalPlans?.length ?? 0) > 1) {
        return 'Multiple';
    }
    return getPlanTypeName(uniqueMajorMedicalPlans?.[0]?.planType);
};
const MajorMedicalBenefitTypeHeader = ({
    column: { id, filterValue, setFilter },
    setSortBy,
    state: { sortBy },
}: HeaderProps<object>) => (
    <HeaderDropdown
        columnId={id}
        id="major-medical-benefit-type-filter-dropdown"
        options={MAJOR_MEDICAL_BENEFIT_TYPE_OPTIONS}
        selected={filterValue}
        setSelected={setFilter}
        setSorted={setSortBy}
        sorted={sortBy}
        title={MAJOR_MEDICAL_BENEFIT_TYPE_PARAM}
    />
);

const getPayrollSystemIdColumn = (width: number): CustomColumn<IPayrollReportRow> => ({
    width,
    accessor: ({ payrollSystemId }) => payrollSystemId,
    className: classNames(CENTER_CONTENT),
    Footer: <React.Fragment />,
    Header: 'Payroll System ID',
    headerClassName: classNames(CENTER_AND_ALIGN),
    id: PAYROLL_SYSTEM_ID_COLUMN_ID,
});
const getSetPayrollDeductionsColumn = (
    isLocked: boolean
): CustomColumn<IPayrollReportRow, { isLocked: boolean }> => ({
    isLocked,
    Cell: PayrollDeductionsActionButton,
    className: CENTER_CONTENT,
    id: 'setPayrollDeductions',
    width: 50,
});
export const getMemberColumn = (
    isLocked: boolean,
    showPayrollSystemId: boolean,
    showMajorMedicalBenefitTypeColumn: boolean | undefined,
    payrollSystemIdColumnWidth: number,
    isAdpIntegrationConnected: boolean
): Column<IPayrollReportRow> => {
    const columns: Column<IPayrollReportRow>[] = [getIndicatorColumn(isLocked)];
    if (isAdpIntegrationConnected) {
        columns.push(getSetPayrollDeductionsColumn(isLocked));
    }
    if (showPayrollSystemId) {
        columns.push(getPayrollSystemIdColumn(payrollSystemIdColumnWidth));
    }
    columns.push(
        {
            accessor: ({ firstName, lastName }) => `${lastName} ${firstName}`,
            Cell: parentRowOnly(NameCell),
            className: MARGIN_LEFT_2,
            Footer: <React.Fragment />,
            Header: 'Name',
            headerClassName: classNames(MARGIN_LEFT_2, ALIGN_ITEMS_START),
            id: NAME_COLUMN_ID,
            width: 150,
        },
        {
            accessor: ({ memberStatus }) => getStatusText(memberStatus),
            className: CENTER_CONTENT,
            disableGlobalFilter: true,
            disableSortBy: true,
            filter: MEMBER_STATUS_FILTER_ID,
            Header: MemberStatusHeader,
            headerClassName: classNames(CENTER_AND_OVERFLOW_AND_ALIGN),
            id: MEMBER_STATUS_COLUMN_ID,
            width: 120,
        },
        {
            accessor: ({ coverageLevel }) => getCoverageLevelText(coverageLevel),
            className: classNames(CENTER_CONTENT, {
                [BORDER_RIGHT]: !showMajorMedicalBenefitTypeColumn,
            }),
            disableGlobalFilter: true,
            disableSortBy: true,
            filter: COVERAGE_LEVEL_FILTER_ID,
            Header: CoverageLevelHeader,
            headerClassName: classNames(CENTER_AND_OVERFLOW_AND_ALIGN, {
                [BORDER_RIGHT]: !showMajorMedicalBenefitTypeColumn,
            }),
            id: COVERAGE_LEVEL_COLUMN_ID,
            width: 120,
        }
    );
    if (showMajorMedicalBenefitTypeColumn) {
        columns.push({
            accessor: ({ majorMedicalPlans }) =>
                getMajorMedicalBenefitTypeAccessor(majorMedicalPlans),
            Cell: parentRowOnly(MajorMedicalBenefitTypeCell),
            className: classNames(CENTER_CONTENT, BORDER_RIGHT),
            disableGlobalFilter: true,
            disableSortBy: true,
            filter: MAJOR_MEDICAL_BENEFIT_TYPE_FILTER_ID,
            Header: MajorMedicalBenefitTypeHeader,
            headerClassName: classNames(CENTER_AND_OVERFLOW_AND_ALIGN, BORDER_RIGHT),
            id: MAJOR_MEDICAL_BENEFIT_TYPE_COLUMN_ID,
            width: 195,
        });
    }
    return {
        columns,
        Footer: <React.Fragment />,
        Header: 'Member',
        headerClassName: classNames(CENTER_CONTENT, MARGIN_LEFT_2, BORDER_RIGHT),
        id: 'memberHeader',
    };
};

const lineFourteenColumn: CustomColumn<IPayrollReportRow, { taxCodeLine: string }> = {
    accessor: (row) => row.lineFourteenCode,
    Cell: parentRowOnly(TaxCodeCell),
    className: classNames(CENTER_CONTENT),
    disableGlobalFilter: true,
    Header: 'Line 14',
    headerClassName: classNames(CENTER_AND_ALIGN),
    id: 'lineFourteenCell',
    taxCodeLine: '14',
    width: 100,
};

const lineSixteenColumn: CustomColumn<IPayrollReportRow, { taxCodeLine: string }> = {
    accessor: (row) => row.lineSixteenCode,
    Cell: parentRowOnly(TaxCodeCell),
    className: classNames(CENTER_CONTENT),
    disableGlobalFilter: true,
    Header: 'Line 16',
    headerClassName: classNames(CENTER_AND_ALIGN),
    id: 'lineSixteenCell',
    taxCodeLine: '16',
    width: 100,
};

const lineSeventeenColumn: CustomColumn<IPayrollReportRow, { taxCodeLine: string }> = {
    accessor: (row) => row.lineSeventeenCode,
    Cell: parentRowOnly(TaxCodeCell),
    className: classNames(CENTER_CONTENT),
    disableGlobalFilter: true,
    Header: 'Line 17',
    headerClassName: classNames(CENTER_AND_ALIGN),
    id: 'lineSeventeenCell',
    taxCodeLine: '17',
    width: 100,
};

export const getTaxCodesColumn = (isIchra: boolean): Column<IPayrollReportRow> => ({
    columns: isIchra
        ? [lineFourteenColumn, lineSixteenColumn, lineSeventeenColumn]
        : [lineFourteenColumn, lineSixteenColumn],
    Footer: <React.Fragment />,
    Header: '1095-C Coding',
    headerClassName: classNames(CENTER_CONTENT, BORDER_X),
    id: 'taxCodes',
});

const wageUpAmountColumn: Column<IPayrollReportRow> = {
    accessor: (row) => row.wageUp,
    Cell: parentRowOnly(CurrencyCell),
    className: classNames(CENTER_CONTENT, BORDER_LEFT),
    disableGlobalFilter: true,
    Header: totalHeader('Amount', (x) => x.wageUp ?? 0),
    headerClassName: classNames(CENTER_AND_ALIGN, BORDER_LEFT),
    id: 'wageUpAmount',
    width: 100,
};

const getWageUpAdjustmentColumn = (
    isLocked: boolean
): CustomColumn<IPayrollReportRow, { adjustmentType: AdjustmentTypes; isLocked: boolean }> => ({
    isLocked,
    accessor: (row) => row.wageUpAdjustment,
    adjustmentType: AdjustmentTypes.WageUp,
    Cell: parentRowOnly(AdjustmentCell),
    className: CENTER_CONTENT,
    disableGlobalFilter: true,
    Header: 'Adjustment',
    headerClassName: classNames(CENTER_AND_ALIGN),
    id: 'wageUpAdjustment',
    width: ADJUSTMENT_WIDTH,
});

const wageUpTotalColumn: Column<IPayrollReportRow> = {
    accessor: (row) => row.wageUpTotal,
    Cell: parentRowOnly(CurrencyCell),
    className: classNames(CENTER_CONTENT, BORDER_RIGHT),
    disableGlobalFilter: true,
    Header: totalHeader('Total', (x) => x.wageUpTotal ?? 0),
    headerClassName: classNames(CENTER_AND_ALIGN, BORDER_RIGHT),
    id: 'wageUpTotal',
    width: 100,
};

export const getWageUpColumn = (isLocked: boolean): Column<IPayrollReportRow> => ({
    columns: [wageUpAmountColumn, getWageUpAdjustmentColumn(isLocked), wageUpTotalColumn],
    Footer: <React.Fragment />,
    Header: 'Wage+',
    headerClassName: classNames(CENTER_CONTENT, BORDER_X),
    id: 'wageUpHeader',
});

const ichraReimbursementAmountColumn: Column<IPayrollReportRow> = {
    accessor: (row) => row.ichraReimbursement,
    Cell: parentRowOnly(CurrencyCell),
    className: classNames(CENTER_CONTENT, BORDER_LEFT),
    disableGlobalFilter: true,
    Header: totalHeader('Amount', (x) => x.ichraReimbursement ?? 0),
    headerClassName: classNames(CENTER_AND_ALIGN, BORDER_LEFT),
    id: 'ichraReimbursementAmount',
    width: 100,
};

const getIchraReimbursementAdjustmentColumn = (
    isLocked: boolean
): CustomColumn<IPayrollReportRow, { adjustmentType: AdjustmentTypes; isLocked: boolean }> => ({
    isLocked,
    accessor: (row) => row.ichraReimbursementAdjustment,
    adjustmentType: AdjustmentTypes.IchraReimbursement,
    Cell: parentRowOnly(AdjustmentCell),
    className: CENTER_CONTENT,
    disableGlobalFilter: true,
    Header: 'Adjustment',
    headerClassName: classNames(CENTER_AND_ALIGN),
    id: 'ichraReimbursementAdjustment',
    width: ADJUSTMENT_WIDTH,
});

const ichraReimbursementTotalColumn: Column<IPayrollReportRow> = {
    accessor: (row) => row.ichraReimbursementTotal,
    Cell: parentRowOnly(CurrencyCell),
    className: classNames(CENTER_CONTENT, BORDER_RIGHT),
    disableGlobalFilter: true,
    Header: totalHeader('Total', (x) => x.ichraReimbursementTotal ?? 0),
    headerClassName: classNames(CENTER_AND_ALIGN, BORDER_RIGHT),
    id: 'ichraReimbursementTotal',
    width: 100,
};

const IchraReimbursementHeader = () => (
    <React.Fragment>
        ICHRA Reimbursement{' '}
        <InformationIconTooltip title="This is the maximum amount of non-taxable reimbursement that can be used to help cover employee health insurance premiums" />
    </React.Fragment>
);

export const getIchraReimbursementColumn = (isLocked: boolean): Column<IPayrollReportRow> => ({
    columns: [
        ichraReimbursementAmountColumn,
        getIchraReimbursementAdjustmentColumn(isLocked),
        ichraReimbursementTotalColumn,
    ],
    Footer: <React.Fragment />,
    Header: IchraReimbursementHeader,
    headerClassName: classNames(CENTER_CONTENT, BORDER_X),
    id: 'ichraReimbursementHeader',
});

const getIsHsaEligibleColumn = (isOnlyColumn: boolean): Column<IPayrollReportRow> => {
    const classes = classNames(CENTER_CONTENT, BORDER_LEFT, { [BORDER_RIGHT]: isOnlyColumn });
    return {
        accessor: (row) => formatYesOrNo(row.isHsaEligible),
        Cell: parentRowOnly(({ value }: Cell<IPayrollReportRow, boolean>) => value),
        className: classes,
        disableGlobalFilter: true,
        Header: 'HSA Eligible',
        headerClassName: classNames(classes, ALIGN_ITEMS_START),
        id: 'isHsaEligible',
        width: 100,
    };
};

const getHsaEmployerContributionColumn = (
    isLocked: boolean
): CustomColumn<
    IPayrollReportRow,
    { contributionType: EmployerContributionTypes; isLocked: boolean }
> => ({
    isLocked,
    accessor: (row) => row.customHsaEmployerContribution ?? row.hsaEmployerContribution,
    Cell: parentRowOnly(ContributionCell),
    className: classNames(CENTER_CONTENT, BORDER_RIGHT),
    contributionType: EmployerContributionTypes.Hsa,
    disableGlobalFilter: true,
    Header: 'Employer Contribution',
    headerClassName: classNames(CENTER_AND_ALIGN, BORDER_RIGHT),
    id: 'hsaEmployerContribution',
    width: ADJUSTMENT_WIDTH,
});

export const getHsaColumn = (
    isLocked: boolean,
    includeContribution: boolean | undefined
): Column<IPayrollReportRow> => {
    const columns: Column<IPayrollReportRow>[] = [getIsHsaEligibleColumn(!includeContribution)];
    if (includeContribution) {
        columns.push(getHsaEmployerContributionColumn(isLocked));
    }
    return {
        columns,
        Footer: <React.Fragment />,
        Header: 'HSA',
        headerClassName: classNames(CENTER_CONTENT, BORDER_X),
        id: 'hsaHeader',
    };
};

const getFsaEmployerContributionColumn = (
    isLocked: boolean
): CustomColumn<
    IPayrollReportRow,
    { contributionType: EmployerContributionTypes; isLocked: boolean }
> => ({
    isLocked,
    accessor: (row) => row.customFsaEmployerContribution ?? row.fsaEmployerContribution,
    Cell: parentRowOnly(ContributionCell),
    className: classNames(CENTER_CONTENT, BORDER_X),
    contributionType: EmployerContributionTypes.Fsa,
    disableGlobalFilter: true,
    Header: 'Employer Contribution',
    headerClassName: classNames(CENTER_AND_ALIGN, BORDER_X),
    id: 'fsaEmployerContribution',
    width: ADJUSTMENT_WIDTH,
});

export const getFsaColumn = (isLocked: boolean): Column<IPayrollReportRow> => ({
    columns: [getFsaEmployerContributionColumn(isLocked)],
    Footer: <React.Fragment />,
    Header: 'FSA',
    headerClassName: classNames(CENTER_CONTENT, BORDER_X),
    id: 'fsaHeader',
});

const preTaxWithholdingBenefitColumn: Column<IPayrollReportRow> = {
    accessor: (u) => u.preTaxBenefitType,
    className: classNames(CENTER_CONTENT, BORDER_LEFT),
    disableGlobalFilter: true,
    Header: 'Benefit',
    headerClassName: classNames(CENTER_AND_ALIGN, BORDER_LEFT),
    id: 'preTaxWithholdingsBenefit',
    width: 100,
};

const preTaxWithholdingAmountColumn: Column<IPayrollReportRow> = {
    accessor: (row) => row.preTaxAmount,
    Cell: CurrencyCell,
    className: CENTER_CONTENT,
    disableGlobalFilter: true,
    Header: totalHeader('Amount', (x) => x.preTaxAmountTotal ?? 0),
    headerClassName: classNames(CENTER_AND_ALIGN),
    id: 'preTaxWithholdingsAmount',
    width: 100,
};

const getPreTaxWithholdingAdjustmentColumn = (
    isLocked: boolean
): CustomColumn<IPayrollReportRow, { adjustmentType: AdjustmentTypes; isLocked: boolean }> => ({
    isLocked,
    accessor: (row) => row.preTaxAdjustment,
    adjustmentType: AdjustmentTypes.PreTaxWithholding,
    Cell: parentRowOnly(AdjustmentCell),
    className: CENTER_CONTENT,
    disableGlobalFilter: true,
    Header: 'Adjustment',
    headerClassName: classNames(CENTER_AND_ALIGN),
    id: 'preTaxWithholdingAdjustment',
    width: ADJUSTMENT_WIDTH,
});

const preTaxWithholdingTotalColumn: Column<IPayrollReportRow> = {
    accessor: (row) => row.preTaxTotal,
    Cell: parentRowOnly(TotalWithSummaryCell),
    className: classNames(CENTER_CONTENT, BORDER_RIGHT),
    disableGlobalFilter: true,
    Header: totalHeader('Total', (x) => x.preTaxTotal ?? 0),
    headerClassName: classNames(CENTER_AND_ALIGN, BORDER_RIGHT),
    id: 'preTaxWithholdingsTotal',
    width: 100,
};

export const getPreTaxWithholdingColumn = (isLocked: boolean): Column<IPayrollReportRow> => ({
    columns: [
        preTaxWithholdingBenefitColumn,
        preTaxWithholdingAmountColumn,
        getPreTaxWithholdingAdjustmentColumn(isLocked),
        preTaxWithholdingTotalColumn,
    ],
    Footer: <React.Fragment />,
    Header: 'Pre-Tax Withholding',
    headerClassName: classNames(CENTER_CONTENT, BORDER_X),
    id: 'preTaxWithholdings',
});

const postTaxWithholdingBenefitColumn: Column<IPayrollReportRow> = {
    accessor: (u) => u.postTaxBenefitType,
    className: classNames(CENTER_CONTENT, BORDER_LEFT),
    disableGlobalFilter: true,
    Header: 'Benefit',
    headerClassName: classNames(CENTER_AND_ALIGN, BORDER_LEFT),
    id: 'postTaxWithholdingsBenefit',
    width: 100,
};

const postTaxWithholdingAmountColumn: Column<IPayrollReportRow> = {
    accessor: (row) => row.postTaxAmount,
    Cell: CurrencyCell,
    className: CENTER_CONTENT,
    disableGlobalFilter: true,
    Header: totalHeader('Amount', (x) => x.postTaxAmountTotal ?? 0),
    headerClassName: classNames(CENTER_AND_ALIGN),
    id: 'postTaxWithholdingsAmount',
    width: 100,
};

const getPostTaxWithholdingAdjustmentColumn = (
    isLocked: boolean
): CustomColumn<IPayrollReportRow, { adjustmentType: AdjustmentTypes; isLocked: boolean }> => ({
    isLocked,
    accessor: (row) => row.postTaxAdjustment,
    adjustmentType: AdjustmentTypes.PostTaxWithholding,
    Cell: parentRowOnly(AdjustmentCell),
    className: CENTER_CONTENT,
    disableGlobalFilter: true,
    Header: 'Adjustment',
    headerClassName: classNames(CENTER_AND_ALIGN),
    id: 'postTaxWithholdingAdjustment',
    width: ADJUSTMENT_WIDTH,
});

const postTaxWithholdingTotalColumn: Column<IPayrollReportRow> = {
    accessor: (row) => row.postTaxTotal,
    Cell: parentRowOnly(TotalWithSummaryCell),
    className: CENTER_CONTENT,
    disableGlobalFilter: true,
    Header: totalHeader('Total', (x) => x.postTaxTotal ?? 0),
    headerClassName: classNames(CENTER_AND_ALIGN),
    id: 'postTaxWithholdingsTotal',
    width: 100,
};

export const getPostTaxWithholdingColumn = (isLocked: boolean): Column<IPayrollReportRow> => ({
    columns: [
        postTaxWithholdingBenefitColumn,
        postTaxWithholdingAmountColumn,
        getPostTaxWithholdingAdjustmentColumn(isLocked),
        postTaxWithholdingTotalColumn,
    ],
    Footer: <React.Fragment />,
    Header: 'Post-Tax Withholding',
    headerClassName: classNames(CENTER_CONTENT, BORDER_LEFT),
    id: 'postTaxWithholdings',
});

const reimbursablePremiumsBenefitColumn: Column<IPayrollReportRow> = {
    accessor: (u) => u.reimbursablePremiumBenefitType,
    className: classNames(CENTER_CONTENT, BORDER_LEFT),
    disableGlobalFilter: true,
    Header: 'Benefit',
    headerClassName: classNames(CENTER_AND_ALIGN, BORDER_LEFT),
    id: 'reimbursablePremiumsBenefit',
    width: 100,
};

const reimbursablePremiumsAmountColumn: Column<IPayrollReportRow> = {
    accessor: (row) => row.reimbursablePremiumAmount,
    Cell: CurrencyCell,
    className: CENTER_CONTENT,
    disableGlobalFilter: true,
    Header: totalHeader('Amount', (x) => x.reimbursablePremiumAmountTotal ?? 0),
    headerClassName: classNames(CENTER_AND_ALIGN),
    id: 'reimbursablePremiumsAmount',
    width: 100,
};

const getReimbursablePremiumsAdjustmentColumn = (
    isLocked: boolean
): CustomColumn<IPayrollReportRow, { adjustmentType: AdjustmentTypes; isLocked: boolean }> => ({
    isLocked,
    accessor: (row) => row.reimbursablePremiumAdjustment,
    adjustmentType: AdjustmentTypes.ReimbursablePremium,
    Cell: conditionalCell(AdjustmentCell, (c) =>
        hasValue(c.row.original.reimbursablePremiumAmount)
    ),
    className: CENTER_CONTENT,
    disableGlobalFilter: true,
    Header: 'Adjustment',
    headerClassName: classNames(CENTER_AND_ALIGN),
    id: 'reimbursablePremiumsAdjustment',
    width: ADJUSTMENT_WIDTH,
});

const reimbursablePremiumsTotalColumn: Column<IPayrollReportRow> = {
    accessor: (row) => row.reimbursablePremiumRowTotal,
    Cell: CurrencyCell,
    className: CENTER_CONTENT,
    disableGlobalFilter: true,
    Header: totalHeader('Total', (x) => x.reimbursablePremiumTotal ?? 0),
    headerClassName: classNames(CENTER_AND_ALIGN),
    id: 'reimbursablePremiumsTotal',
    width: 100,
};

export const getReimbursablePremiumsColumn = (isLocked: boolean): Column<IPayrollReportRow> => ({
    columns: [
        reimbursablePremiumsBenefitColumn,
        reimbursablePremiumsAmountColumn,
        getReimbursablePremiumsAdjustmentColumn(isLocked),
        reimbursablePremiumsTotalColumn,
    ],
    Footer: <React.Fragment />,
    Header: 'Reimbursable Premiums',
    headerClassName: classNames(CENTER_CONTENT, BORDER_LEFT),
    id: 'reimbursablePremiums',
});

const taxFreeReimbursementsBenefitColumn: Column<IPayrollReportRow> = {
    accessor: (row) => row.taxFreeReimbursementType,
    className: classNames(CENTER_CONTENT, BORDER_LEFT),
    disableGlobalFilter: true,
    Header: 'Benefit',
    headerClassName: classNames(CENTER_AND_ALIGN, BORDER_LEFT),
    id: 'taxFreeReimbursementsBenefit',
    width: 100,
};

const taxFreeReimbursementsAmountColumn: Column<IPayrollReportRow> = {
    accessor: (row) => row.taxFreeReimbursementAmount,
    Cell: CurrencyCell,
    className: CENTER_CONTENT,
    disableGlobalFilter: true,
    Header: totalHeader('Amount', (x) => x.taxFreeReimbursementAmountTotal ?? 0),
    headerClassName: classNames(CENTER_AND_ALIGN),
    id: 'taxFreeReimbursementsAmount',
    width: 100,
};

const getTaxFreeReimbursementAdjustmentColumn = (
    isLocked: boolean
): CustomColumn<IPayrollReportRow, { adjustmentType: AdjustmentTypes; isLocked: boolean }> => ({
    isLocked,
    accessor: (row) => row.taxFreeReimbursementAdjustment,
    adjustmentType: AdjustmentTypes.TaxFreeReimbursements,
    Cell: parentRowOnly(AdjustmentCell),
    className: CENTER_CONTENT,
    disableGlobalFilter: true,
    Header: 'Adjustment',
    headerClassName: classNames(CENTER_AND_ALIGN),
    id: 'taxFreeReimbursementsAdjustment',
    width: ADJUSTMENT_WIDTH,
});

const taxFreeReimbursementTotalColumn: Column<IPayrollReportRow> = {
    accessor: (row) => row.taxFreeReimbursementTotal,
    Cell: parentRowOnly(TotalWithSummaryCell),
    className: CENTER_CONTENT,
    disableGlobalFilter: true,
    Header: totalHeader('Total', (x) => x.taxFreeReimbursementTotal ?? 0),
    headerClassName: classNames(CENTER_AND_ALIGN),
    id: 'taxFreeReimbursementsTotal',
    width: 100,
};
const TaxFreeReimbursementHeader = () => (
    <React.Fragment>
        Tax-Free Reimbursements{' '}
        <InformationIconTooltip title="This should be a tax-free reimbursement given directly to the employee for plans they are paying themselves." />
    </React.Fragment>
);

export const getTaxFreeReimbursementsColumn = (isLocked: boolean): Column<IPayrollReportRow> => ({
    columns: [
        taxFreeReimbursementsBenefitColumn,
        taxFreeReimbursementsAmountColumn,
        getTaxFreeReimbursementAdjustmentColumn(isLocked),
        taxFreeReimbursementTotalColumn,
    ],
    Footer: <React.Fragment />,
    Header: TaxFreeReimbursementHeader,
    headerClassName: classNames(CENTER_CONTENT, BORDER_LEFT),
    id: 'taxFreeReimbursements',
});
