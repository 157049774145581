import { createPdfAndUploadToMemberFiles } from 'actions/memberTerms/createPdfAndUploadToMemberFiles';
import { saveCompletedUserTask } from 'actions/task/saveCompletedUserTask';
import { completeMemberTaskList } from 'actions/user/completeMemberTaskList';
import { getUserProfile } from 'actions/user/getUserProfile';
import { patchYearlyUserInfo } from 'actions/user/patchYearlyUserInfo';
import { setUserStatus } from 'actions/user/setUserStatus';
import { EnrollmentStatuses, PathwayTypes, TaskItems, UserStatus } from 'api/generated/enums';
import { taskListTypes } from 'constants/tasks';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import { completedTasksSelector, isWaived } from 'pages/dashboard/dashboardSelectors';
import OptInModal from 'pages/dashboard/taskLists/member/tasks/OptInModal';
import OptOutModal from 'pages/dashboard/taskLists/member/tasks/OptOutModal';
import TaskListItem from 'pages/dashboard/taskLists/TaskListItem';
import { needSomeHouseholdSsnSelector } from 'pages/dashboard/taskListSelectors';
import React, { useCallback, useMemo, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';

type IAcceptServicesTaskProps = {
    actionButtonText?: string;
    complete: boolean;
    description?: string;
    enabled: boolean;
    taskItemId?: TaskItems;
    title?: string;
};

const AcceptServicesTask = ({
    actionButtonText = 'Decide',
    complete,
    description = 'Opt in or out of Remodel Health services.',
    enabled,
    taskItemId: taskToComplete = TaskItems.AcceptRHServices,
    title = 'Agree to Remodel Health Services',
}: IAcceptServicesTaskProps) => {
    const dispatch = useThunkDispatch();
    const { isCurrent, memberVerifiedInfo, user, userId, year } = useUserProps();
    const { completedTasks, isUserWaived, needsSsn, pathwayType } = useSelector(
        (state: AppStore) => ({
            completedTasks: completedTasksSelector(state),
            isUserWaived: isWaived(state),
            needsSsn: needSomeHouseholdSsnSelector(state, memberVerifiedInfo),
            pathwayType: state.pathwayUser?.pathwayType,
        })
    );
    const isRenewal = taskToComplete === TaskItems.AcceptRHServices_Renewing;

    const [showOptInModal, setShowOptInModal] = useState(false);
    const [showOptOutModal, setShowOptOutModal] = useState(false);
    const [signature, setSignature] = useState('');

    const handleCloseClickAndOpen = useCallback(
        (setClose, setOpen) => () => {
            setClose(false);
            setOpen(true);
        },
        []
    );

    const handleOptOutSubmitClick = useCallback(() => {
        setShowOptOutModal(false);
        dispatch(saveCompletedUserTask(taskToComplete));
        const onSuccessAction = getUserProfile(userId, isCurrent, year);
        dispatch(setUserStatus(userId, UserStatus.Waived, isCurrent, { onSuccessAction }));
    }, [dispatch, isCurrent, taskToComplete, userId, year]);

    const optIn = useCallback(async () => {
        await dispatch(saveCompletedUserTask(taskToComplete));
        await dispatch(createPdfAndUploadToMemberFiles(userId, signature));
        if (user?.status === UserStatus.Waived || pathwayType === PathwayTypes.Waived) {
            await dispatch(
                patchYearlyUserInfo(
                    userId,
                    year,
                    { enrollmentStatus: { value: EnrollmentStatuses.AdvisementNeeded } },
                    isCurrent
                )
            );
        } else {
            const reviewInfoTask = isRenewal
                ? TaskItems.ReviewMemberInfo_Renewing
                : TaskItems.ReviewMemberInfo;
            const ssnTask = isRenewal
                ? TaskItems.EnterSocialSecurityNumbers_Renewing
                : TaskItems.EnterSocialSecurityNumbers;
            const isReviewInfoComplete = completedTasks.some(
                (x) => x.taskItemId === reviewInfoTask
            );
            const isSsnTaskComplete = completedTasks.some((x) => x.taskItemId === ssnTask);
            if (isReviewInfoComplete && (isSsnTaskComplete || !needsSsn)) {
                await dispatch(completeMemberTaskList(userId, year));
                await dispatch(getUserProfile(userId, true));
            }
        }
    }, [
        completedTasks,
        dispatch,
        isCurrent,
        isRenewal,
        needsSsn,
        pathwayType,
        signature,
        taskToComplete,
        user?.status,
        userId,
        year,
    ]);

    const handleOptInSubmitClick = useCallback(() => {
        setShowOptInModal(false);
        optIn();
    }, [optIn]);

    const onHide = useCallback((setClose) => () => setClose(false), []);
    const showModal = useCallback(() => setShowOptInModal(true), []);

    const canChange = useMemo(() => complete && isUserWaived, [complete, isUserWaived]);
    const showButton = useMemo(() => !complete || canChange, [complete, canChange]);
    const buttonText = useMemo(() => (canChange ? 'Change' : actionButtonText), [
        actionButtonText,
        canChange,
    ]);

    return (
        <TaskListItem
            action={showModal}
            buttonText={buttonText}
            complete={complete}
            data-cy="accept-services-task"
            description={description}
            enabled={enabled}
            showButton={showButton}
            showButtonWhenComplete
            taskId={taskToComplete}
            taskType={taskListTypes.User}
            title={title}
        >
            {showOptInModal && (
                <OptInModal
                    isOptIn={showOptInModal}
                    onHide={onHide(setShowOptInModal)}
                    onNoClick={handleCloseClickAndOpen(setShowOptInModal, setShowOptOutModal)}
                    onYesClick={handleOptInSubmitClick}
                    setSignature={setSignature}
                    signature={signature}
                />
            )}
            {showOptOutModal && (
                <OptOutModal
                    onHide={onHide(setShowOptOutModal)}
                    onNoClick={handleCloseClickAndOpen(setShowOptOutModal, setShowOptInModal)}
                    onYesClick={handleOptOutSubmitClick}
                    setSignature={setSignature}
                    signature={signature}
                />
            )}
        </TaskListItem>
    );
};

export default hot(module)(AcceptServicesTask);
