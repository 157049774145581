import SingleButtonModal from 'components/SingleButtonModal';
import useUserProps from 'hooks/useUserProps';
import React from 'react';
import { hot } from 'react-hot-loader';

const VerifyInfoModal = ({
    isIchra,
    onClose,
    onSubmit,
}: {
    isIchra: boolean;
    onClose: () => void;
    onSubmit: () => void;
}) => {
    const { memberVerifiedInfo } = useUserProps();
    const activeYear = memberVerifiedInfo?.year;

    const displayStandardInformation = () => (
        <span>
            <p>
                In this step of your open enrollment process, you will be required to enter income
                information for {activeYear} and verify other information about your household.
                These items are critical to ensure your household&apos;s coverage is correct and you
                do not incur any tax implications at the end of {activeYear}!
            </p>
            <p>
                After you enter information about your income for {activeYear}, please pay special
                attention to ensure the following items are correct:
            </p>
            <ol>
                <li>
                    <strong>Address</strong> – This should be your current primary residence. P.O.
                    box addresses are discouraged.
                </li>

                <li>
                    <strong>Household Members</strong> – Please include all members of your
                    household who you will be claiming on your {activeYear} taxes.
                </li>

                <li>
                    <strong>Household Income</strong> – Review this one more time and make sure it
                    is correct!
                </li>
            </ol>
            <p>
                If you have additional questions about what you should be updating during this
                process, please visit{' '}
                <a
                    href="https://www.healthcare.gov/glossary/qualifying-life-event"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    HealthCare.gov&apos;s list of qualifying life events
                </a>
                !
            </p>
        </span>
    );

    const displayIchraInformation = () => (
        <span>
            <p>
                In this step of your open enrollment process, you will be asked to verify important
                household information for {activeYear}.
            </p>
            <p>Please pay special attention to ensure the following items are correct:</p>
            <ol>
                <li>
                    <strong>Address</strong> – This should be your current primary residence. P.O.
                    box addresses are discouraged.
                </li>

                <li>
                    <strong>Household Members</strong> – Please include all members of your
                    household that will need coverage for {activeYear}.
                </li>
            </ol>
        </span>
    );

    return (
        <SingleButtonModal
            backdrop="static"
            buttonClickHandler={onSubmit}
            buttonText="Continue"
            onHide={onClose}
            title="Important Information"
        >
            {isIchra ? displayIchraInformation() : displayStandardInformation()}
        </SingleButtonModal>
    );
};

export default hot(module)(VerifyInfoModal);
