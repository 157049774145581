import { AppStoreThunkDispatch } from 'actions/commonAction';
import { editTaskStatus } from 'actions/taskFlows/editTaskStatus';
import { Flows, HouseholdMemberTypes, TaskStatuses, Tasks } from 'api/generated/enums';
import {
    HouseholdMemberInfoDto,
    IAddressDto,
    IAncillaryBenefitDto,
    IAncillaryBenefitInfoDto,
    IAncillaryBenefitsDto,
    IHouseholdMembersDto,
    IMemberTermsDto,
    IPersonalInfoDto,
    IPlanInfoDto,
    IPreferredProvidersDto,
    IPrescriptionsDto,
    ISelectedPlansDto,
    ISocialSecurityNumbersDto,
    IStringResponseDto,
    IUserFlowDto,
    IYourJobDto,
    TaskDto,
} from 'api/generated/models';
import reject from 'lodash/reject';
import { createContext } from 'react';
import { formatDateForDisplay } from 'utilities/format';
import { arrayHasValue, hasValue } from 'utilities/index';

export type IIchraEnrollmentTaskFlowContent = {
    currentFlow?: IUserFlowDto;
    currentTask: TaskDto;
};

export const MAX_WIDTH = '1200px';
export const SLIM_MAX_WIDTH = '700px';
export const FONT_SEMI_BOLD = 600;

export const NO_TASK_FOUND_INDEX = -1;

export const SUPPORTED_ICHRA_USER_FLOWS = [Flows.IchraLaunch, Flows.IchraRenewal];

export type IchraFlowResponseType =
    | IAddressDto
    | IAncillaryBenefitsDto
    | IHouseholdMembersDto
    | IMemberTermsDto
    | IPersonalInfoDto
    | IPlanInfoDto
    | IPreferredProvidersDto
    | IPrescriptionsDto
    | ISelectedPlansDto
    | ISocialSecurityNumbersDto
    | IStringResponseDto
    | IYourJobDto
    | string
    | undefined;

export const getSupportedEnrollmentUserFlow = (userFlows: IUserFlowDto[]) =>
    userFlows.find((uf) => SUPPORTED_ICHRA_USER_FLOWS.contains(uf.flowId));

const handleFlatteningTasks = (tasks: TaskDto[]): TaskDto[] =>
    tasks.flatMap(
        (task) => [task, ...handleFlatteningTasks(task.childTasks as TaskDto[])] as TaskDto[]
    );

export const findPreviousSiblingTask = (
    currentTaskId: string,
    tasks: TaskDto[]
): TaskDto | undefined => {
    if (!arrayHasValue(tasks)) {
        return undefined;
    }
    const flattenedTaskList = handleFlatteningTasks(tasks).filter(
        (fa) => ![TaskStatuses.Skipped, TaskStatuses.Overridden].contains(fa.taskStatusId)
    );
    const currentTaskIndex = flattenedTaskList.findIndex((x) => x.globalId === currentTaskId);

    return flattenedTaskList[currentTaskIndex - 1];
};

export const defaultHandlePrevious = async (dispatch: AppStoreThunkDispatch, taskId: string) => {
    await dispatch(editTaskStatus(taskId, TaskStatuses.Incomplete));
};

export type IHhmContext = {
    hhmExpanded?: number;
    isAddingNewHhm?: boolean;
    newHhmType?: HouseholdMemberTypes;
    setHhmExpanded?: React.Dispatch<React.SetStateAction<number | undefined>>;
    setIsAddingNewHhm?: React.Dispatch<React.SetStateAction<boolean>>;
    setNewHhmType?: React.Dispatch<React.SetStateAction<HouseholdMemberTypes | undefined>>;
};

export const HhmContext = createContext<IHhmContext>({});

export const personalInfoChunkHasValue = (chunk: IPersonalInfoDto) =>
    hasValue(chunk?.firstName) ||
    hasValue(chunk?.lastName) ||
    hasValue(chunk?.preferredName) ||
    hasValue(chunk?.gender) ||
    hasValue(chunk?.birthdate) ||
    hasValue(chunk?.phone) ||
    hasValue(chunk?.usesTobacco);

export const checkIsMissingValue = (properties: unknown[]) => {
    let isMissingValue = false;
    properties.forEach((p) => {
        if (!hasValue(p)) {
            isMissingValue = true;
        }
    });
    return isMissingValue;
};

export const householdMemberCheckForEdits = (
    hasSameAddress: boolean,
    initial?: HouseholdMemberInfoDto,
    current?: HouseholdMemberInfoDto
) => {
    if (hasValue(initial?.address) === hasSameAddress) {
        // this handles the change of the hasSameAddress checkbox
        return true;
    } else if (`${initial?.usesTobacco}` !== `${current?.usesTobacco}`) {
        // this handles the boolean value sometimes being a string
        return true;
    } else if (
        hasValue(initial?.preferredName)
            ? initial?.preferredName?.trim() !== current?.preferredName?.trim()
            : hasValue(current?.preferredName)
    ) {
        // this handles the preferred name field, matching '' to undefined
        return true;
    } else if (
        hasValue(initial?.sameEmployer)
            ? initial?.sameEmployer !== current?.sameEmployer
            : current?.sameEmployer === true
    ) {
        // this handles sameEmployer's default value of undefined being equal to false
        return true;
    } else if (
        initial?.address != current?.address ||
        formatDateForDisplay(initial?.birthdate) !== formatDateForDisplay(current?.birthdate) ||
        initial?.firstName?.trim() !== current?.firstName?.trim() ||
        initial?.lastName?.trim() !== current?.lastName?.trim() ||
        initial?.gender !== current?.gender
    ) {
        return true;
    } else {
        return false;
    }
};

export const getPreviousTask = (
    taskList: TaskDto[],
    task: Tasks,
    parentTask?: Tasks,
    grandParentTask?: Tasks
) => {
    let tasks;
    if (hasValue(parentTask)) {
        if (hasValue(grandParentTask)) {
            tasks = taskList
                .filter((t) => t.taskId === grandParentTask)[0]
                ?.childTasks?.filter((t) => t.taskId === parentTask)[0]?.childTasks;
        } else {
            tasks = taskList.filter((t) => t.taskId === parentTask)[0]?.childTasks;
        }
    } else {
        tasks = taskList;
    }
    return tasks?.find((t) => t.taskId === task);
};

type IHasEntityId = { entityId?: string };
export const filterNonChunkHhmData = <T extends IHasEntityId>(list: T[], entityIds: string[]) =>
    reject(list, (d) => !entityIds.includes(d.entityId as string));

export const handleAncillaryValues = (
    ancillaryTask: TaskDto,
    ancillariesFromState: IAncillaryBenefitDto[],
    userId: string
) => {
    if (hasValue(ancillaryTask) && arrayHasValue(ancillaryTask.response)) {
        const ancillariesFromChunk = (ancillaryTask.response as IAncillaryBenefitsDto)
            .ancillaryBenefits as IAncillaryBenefitInfoDto[];
        return ancillariesFromState.map((afs) => {
            const ancillaryFromChunk = ancillariesFromChunk.find(
                (afc) => afc.userTermDetailId === afs.userTermDetailId
            ) as IAncillaryBenefitInfoDto;

            if (hasValue(ancillaryFromChunk)) {
                const updatedStatusId = ancillaryFromChunk.statusId;
                const updatedInitiallyCoveredHouseholdMemberIds = [
                    ...(ancillaryFromChunk.householdMemberIds as string[]),
                    userId,
                ] as string[];

                return {
                    ...afs,
                    initiallyCoveredHouseholdMemberIds: updatedInitiallyCoveredHouseholdMemberIds,
                    statusId: updatedStatusId,
                };
            }

            return afs;
        });
    } else {
        return ancillariesFromState;
    }
};

export const ichraFlowSkeletonRowProps = {
    alignItems: 'center',
    display: 'flex',
    gap: 4,
    justifyContent: 'center',
    width: '100%',
};

export const skeletonStructure = [
    [
        {
            columnProps: { flex: 1 },
            count: 2,
            height: 50,
            sx: { mb: 2 },
            width: '100%',
        },
    ],
    [
        {
            columnProps: { flex: 1 },
            count: 1,
            height: 55,
            sx: { mb: 3, mt: 2 },
            width: '100%',
        },
    ],
];
