import Typography from '@mui/material/Typography';
import { GET_PATHWAY_ACTION, getPathway } from 'actions/pathwayBlueprint/getPathway';
import { PathwayInputTypes, PlanTypeIds } from 'api/generated/enums';
import { ISelectedPlan } from 'api/generated/models';
import Button from 'components/Button';
import CurrencyTextField from 'components/CurrencyTextField';
import Skeleton from 'components/Skeleton';
import useThunkDispatch from 'hooks/useThunkDispatch';
import debounce from 'lodash/debounce';
import startCase from 'lodash/startCase';
import {
    clearBenefitsSelectionState,
    setPlanForBenefitSelection,
    setRequestedWageUp,
} from 'pages/dashboard/medicalBenefitSelection/benefitSelectionActions';
import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { hasValue } from 'utilities';
import { formatCurrency } from 'utilities/format';
import { onChange } from 'utilities/forms';
import { buildSelectedOtherCoverageFromPlanInputs } from 'utilities/selectedPlanBuilder';

const ONE_THOUSAND_MILLISECONDS = 1000;
const WageUpRequestPlanDetails = ({
    planType,
    relation,
}: {
    planType: PlanTypeIds;
    relation: string;
}) => {
    const dispatch = useThunkDispatch();
    const {
        isAboveApprovalCap,
        isLoading,
        pathwayUser,
        requestedWageUp,
        startingWageUp,
    } = useSelector((state: AppStore) => ({
        isAboveApprovalCap: state.benefitSelectionState.isAboveApprovalCap,
        isLoading: hasApiActivity(state, GET_PATHWAY_ACTION),
        pathwayUser: state.pathwayUser,
        requestedWageUp: state.benefitSelectionState.requestedWageUp,
        startingWageUp: state.pathway?.pathwayInputs?.find(
            (x) => x.pathwayInputTypeId === PathwayInputTypes.StartingWageUp
        )?.value,
    }));
    const [isRequestMoreVisible, setIsRequestMoreVisible] = useState(false);
    const [isApprovalMessageVisible, setIsApprovalMessageVisible] = useState(false);

    useEffect(() => {
        if (pathwayUser?.pathwayId) {
            dispatch(getPathway(pathwayUser.pathwayId));
        }
    }, [dispatch, pathwayUser]);

    useEffect(() => {
        dispatch(
            setPlanForBenefitSelection(
                buildSelectedOtherCoverageFromPlanInputs({}, planType) as ISelectedPlan
            )
        );
        return () => {
            dispatch(clearBenefitsSelectionState());
        };
    }, [dispatch, planType]);

    const debouncedShowApprovalMessage = debounce(
        (value: boolean) => setIsApprovalMessageVisible(value),
        ONE_THOUSAND_MILLISECONDS
    );
    const onCostChange = (value: string) => {
        dispatch(setRequestedWageUp(value));
        debouncedShowApprovalMessage(hasValue(value));
    };

    return (
        <React.Fragment>
            <Col>
                <Typography mb={2} textAlign="center" variant="h5">
                    Congratulations, you have the opportunity to join the plan offered by your{' '}
                    {relation}&apos;s employer!
                </Typography>
                <Typography mb={4} textAlign="center" variant="h5">
                    Your employer is offering the following monthly Wage+ for you to join your{' '}
                    {relation}&apos;s plan.
                </Typography>
                <Skeleton count={2} isEnabled={isLoading} sx={{ mb: 2 }}>
                    <Typography mb={0} variant="h4">
                        Monthly Wage+: {formatCurrency(startingWageUp)}
                    </Typography>
                    <Button
                        color="secondary"
                        onClick={() => setIsRequestMoreVisible(true)}
                        variant="text"
                    >
                        The cost to join my {relation}&apos;s plan is more than this
                    </Button>
                </Skeleton>
                {isRequestMoreVisible && (
                    <Col sm={{ offset: 3, span: 6 }}>
                        <div className="my-3 font-weight-bold">Request Higher Wage+ Amount</div>
                        <Row className="justify-content-center">
                            <CurrencyTextField
                                autoFocus
                                helperText={`Enter the monthly cost for you to join your ${relation}’s plan`}
                                label={`Monthly Cost to Join ${startCase(relation)} Plan`}
                                name="requestedWageUp"
                                onChange={onChange(onCostChange)}
                                value={requestedWageUp}
                            />
                        </Row>
                        {isApprovalMessageVisible && (
                            <React.Fragment>
                                {!isAboveApprovalCap && (
                                    <div>
                                        Congratulations! This Wage+ amount has been automatically
                                        approved!
                                    </div>
                                )}
                                {isAboveApprovalCap && (
                                    <div>
                                        Please click Submit Request below, then we&apos;ll review
                                        the amount you&apos;ve requested. After submitting, you will
                                        be prompted to upload documentation of the cost to join your{' '}
                                        {relation}&apos;s plan
                                    </div>
                                )}
                            </React.Fragment>
                        )}
                    </Col>
                )}
            </Col>
        </React.Fragment>
    );
};

export default hot(module)(WageUpRequestPlanDetails);
