import {
    transitionToRenewing,
    TRANSITION_TO_RENEWING_ACTION,
} from 'actions/team/transitionToRenewing';
import Button from 'components/Button';
import ConfirmationModal from 'components/ConfirmationModal';
import TextField from 'components/TextField';
import UnselectableText from 'components/UnselectableText';
import useModalState from 'hooks/useModalState';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import moment from 'moment';
import { setSelectedYear } from 'pages/profile/profileActions';
import React, { useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { onChange } from 'utilities/forms';

const BeginRenewalPeriod = () => {
    const dispatch = useThunkDispatch();
    const { isLoading } = useSelector((state: AppStore) => ({
        isLoading: hasApiActivity(state, TRANSITION_TO_RENEWING_ACTION),
    }));
    const { activeDate, teamId } = useTeamProps();
    const textToMatch = `Transition this team to Renewing for ${Number(activeDate?.getYear()) + 1}`;

    const [inputValue, setInputValue] = useState('');
    const [isInvalid, setIsInvalid] = useState(false);
    const {
        closeModal: closeRenewalModal,
        isVisible: isRenewalModalVisible,
        openModal: openRenewalModal,
    } = useModalState();

    const currentActiveYear = moment(activeDate).year();
    const nextActiveYear = currentActiveYear + 1;

    const onTransitionToRenewal = async () => {
        if (inputValue === textToMatch) {
            setIsInvalid(false);
            dispatch(setSelectedYear(nextActiveYear.toString()));
            await dispatch(transitionToRenewing(teamId));
            closeRenewalModal();
        } else {
            setIsInvalid(true);
        }
    };

    const confirmModalBody = (
        <React.Fragment>
            <ul>
                <li>
                    The Active Date for the team and its members will be set to 1/1/
                    {nextActiveYear}.
                </li>
                <li>
                    Household data will be copied from {currentActiveYear} to {nextActiveYear}.
                </li>
                <li>
                    Team Yearly Operations Info will be copied from {currentActiveYear} to{' '}
                    {nextActiveYear}.
                </li>
            </ul>
            <br />
            <br />
            <span>
                Please type the phrase{' '}
                <UnselectableText as="strong">{textToMatch}</UnselectableText> exactly as it appears
                to confirm this action.
            </span>
            <br />
            <br />
            <TextField
                autoFocus
                data-cy="confirm-begin-renewal"
                defaultValue={inputValue}
                error={isInvalid}
                helperText={
                    isInvalid
                        ? 'Input text must match the bolded phrase above exactly to confirm this action'
                        : ''
                }
                onChange={onChange(setInputValue)}
            />
        </React.Fragment>
    );

    return (
        <React.Fragment>
            {isRenewalModalVisible && (
                <ConfirmationModal
                    noButtonText="Cancel"
                    onNoClick={closeRenewalModal}
                    onYesClick={onTransitionToRenewal}
                    showActivity={isLoading}
                    title="Begin Renewal Period"
                    yesButtonText="Confirm"
                >
                    {confirmModalBody}
                </ConfirmationModal>
            )}
            <Button
                data-cy="begin-renewal-period"
                isLoading={isLoading}
                onClick={openRenewalModal}
                xsClassName="mb-4"
            >
                Transition Team to Renewing
            </Button>
        </React.Fragment>
    );
};

export default hot(module)(BeginRenewalPeriod);
