import { Card, CardContent } from '@mui/material';
import Typography from '@mui/material/Typography';
import { GET_OFF_EXCHANGE_MARKETPLACE_PLAN_RATES_FOR_USER_ACTION } from 'actions/marketplacePlan/getOffExchangeMarketplacePlanRatesForUser';
import { GET_ON_EXCHANGE_MARKETPLACE_PLAN_RATES_FOR_USER_ACTION } from 'actions/marketplacePlan/getOnExchangeMarketplacePlanRatesForUser';
import { GET_MEDISHARE_PLANS_FOR_USER_ACTION } from 'actions/medishare/getMediSharePlansAndRatesForUser';
import { ADD_SELECTED_PLAN_ACTION } from 'actions/selectedPlan/addSelectedPlan';
import { OtherCoverageEligibilities } from 'api/generated/enums';
import { IMarketplacePlanDto, IMediSharePlan } from 'api/generated/models';
import Button from 'components/Button';
import Skeleton from 'components/Skeleton';
import useUserProps from 'hooks/useUserProps';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity, hasApiActivityWithParams } from 'selectors/activity';
import { isIchraPathwayType } from 'utilities/pathways';

export type ISpousePlan = {
    hasError?: boolean;
    id: string;
    isSpousePlan: boolean;
};

const SpousePlanCard = ({
    handleSelectPlan,
    isPlanSelectionDisabled,
}: {
    handleSelectPlan: (
        plan: IMarketplacePlanDto | IMediSharePlan | ISpousePlan,
        year?: number
    ) => void;
    isPlanSelectionDisabled: (plan: ISpousePlan) => boolean;
}) => {
    const { memberVerifiedInfo } = useUserProps();
    const plan = {
        id: 'spouse',
        isSpousePlan: true,
    };
    const { isSelectingPlan, pathwayUser, planDetailsActivity } = useSelector(
        (state: AppStore) => ({
            isSelectingPlan: hasApiActivityWithParams(state, ADD_SELECTED_PLAN_ACTION, {
                planId: plan.id,
            }),
            pathwayUser: state.pathwayUser,
            planDetailsActivity: hasApiActivity(
                state,
                GET_ON_EXCHANGE_MARKETPLACE_PLAN_RATES_FOR_USER_ACTION,
                GET_OFF_EXCHANGE_MARKETPLACE_PLAN_RATES_FOR_USER_ACTION,
                GET_MEDISHARE_PLANS_FOR_USER_ACTION
            ),
        })
    );
    const showSpousePlan =
        memberVerifiedInfo?.otherCoverageEligibilities ===
            OtherCoverageEligibilities.SpouseEmployer &&
        !isIchraPathwayType(pathwayUser.pathwayType);

    return (
        <React.Fragment>
            {showSpousePlan && (
                <Skeleton count={1} height="213px" isEnabled={planDetailsActivity} sx={{ mb: 3 }}>
                    <Card data-cy="plan-card" sx={{ my: 3 }}>
                        <CardContent>
                            <Row className="px-2 align-items-center justify-content-between text-center">
                                <div className="text-left">
                                    <Typography variant="h3">Spouse Employer Coverage</Typography>
                                </div>
                                <Row className="align-items-center justify-content-end px-3 py-0 d-block d-sm-flex">
                                    You reported being eligible for the plan offered by your
                                    spouse&apos;s employer. Select this card if you want to join
                                    your spouse&apos;s plan.
                                </Row>
                            </Row>
                            <hr />
                            <Row className="px-2 justify-content-center align-items-center">
                                <Col className="row d-block d-sm-flex">
                                    <Col>
                                        <Button
                                            data-cy="select-plan"
                                            disabled={isPlanSelectionDisabled(plan)}
                                            isLoading={isSelectingPlan}
                                            onClick={() => handleSelectPlan(plan)}
                                        >
                                            Select Plan
                                        </Button>
                                    </Col>
                                </Col>
                            </Row>
                        </CardContent>
                    </Card>
                </Skeleton>
            )}
        </React.Fragment>
    );
};

export default hot(module)(SpousePlanCard);
