import { AdpIntegrationStatuses } from 'api/generated/enums';
import { ITeam } from 'api/generated/models';
import { GenerateHrsIntegrationKey, ViewHrsIntegrationKey } from 'api/generated/permissions';
import Button from 'components/Button';
import CopyText from 'components/CopyText';
import { ISaveEditableTextField } from 'components/EditableTextField';
import ProfileAttribute from 'components/ProfileAttribute';
import useTeamProps from 'hooks/useTeamProps';
import startCase from 'lodash/startCase';
import React, { useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasSomePermissions } from 'selectors';
import { hasValue } from 'utilities';
import { v4 as guid } from 'uuid';

const AdpIntegrationSection = ({ save }: { save: ISaveEditableTextField<ITeam> }) => {
    const { canGenerateAdpIntegrationKey, canViewAdpIntegrationKey } = useSelector(
        (state: AppStore) => ({
            canGenerateAdpIntegrationKey: hasSomePermissions(state, GenerateHrsIntegrationKey),
            canViewAdpIntegrationKey: hasSomePermissions(state, ViewHrsIntegrationKey),
        })
    );
    const { team } = useTeamProps();
    const [adpIntegrationKey, setAdpIntegrationKey] = useState(team?.adpIntegrationKey ?? '');

    const generateNewAdpIntegrationKey = () => {
        const newKey = guid();
        setAdpIntegrationKey(newKey);
        save('adpIntegrationKey', newKey);
    };

    const getAttributeContent = () => {
        if (!hasValue(adpIntegrationKey)) {
            return (
                <React.Fragment>
                    {canGenerateAdpIntegrationKey && (
                        <Button
                            onClick={generateNewAdpIntegrationKey}
                            size="small"
                            variant="outlined"
                        >
                            Generate Key
                        </Button>
                    )}
                </React.Fragment>
            );
        }

        return <CopyText text={adpIntegrationKey}>{adpIntegrationKey}</CopyText>;
    };

    const hasIntegrationKey = hasValue(adpIntegrationKey);
    const showIntegrationStatus = canViewAdpIntegrationKey && hasIntegrationKey;

    return (
        <React.Fragment>
            {(showIntegrationStatus || canGenerateAdpIntegrationKey) && (
                <ProfileAttribute label="ADP Integration Key">
                    {getAttributeContent()}
                </ProfileAttribute>
            )}
            {showIntegrationStatus && (
                <ProfileAttribute label="ADP Integration Status">
                    {startCase(
                        AdpIntegrationStatuses[
                            team?.adpIntegrationStatus ?? AdpIntegrationStatuses.NotConfigured
                        ]
                    )}
                </ProfileAttribute>
            )}
        </React.Fragment>
    );
};

export default hot(module)(AdpIntegrationSection);
