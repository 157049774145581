import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { ADD_PATHWAY_BLUEPRINT_ACTION } from 'actions/pathwayBlueprint/addPathwayBlueprint';
import { EDIT_PATHWAY_BLUEPRINT_ACTION } from 'actions/pathwayBlueprint/editPathwayBlueprint';
import { GET_PATHWAY_BLUEPRINT_ACTION } from 'actions/pathwayBlueprint/getPathwayBlueprint';
import { GET_PATHWAY_MARKETPLACE_PLAN_MIGRATIONS_ACTION } from 'actions/pathwayBlueprint/getPlansForNextYearAsync';
import { IPathwayMarketplacePlanMigration } from 'api/generated/models';
import Skeleton from 'components/Skeleton';
import Typography from 'components/Typography';
import PathwayMarketplacePlanMigrationInputs from 'pages/pathwayBlueprints/PathwayMarketplacePlanMigrationInputs';
import React from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { arrayHasValue, hasValue } from 'utilities/index';

const PathwayMigrationsContent = ({
    migrations,
}: {
    migrations: IPathwayMarketplacePlanMigration[];
}) => {
    const { isLoading } = useSelector((state: AppStore) => ({
        isLoading: hasApiActivity(
            state,
            ADD_PATHWAY_BLUEPRINT_ACTION,
            EDIT_PATHWAY_BLUEPRINT_ACTION,
            GET_PATHWAY_BLUEPRINT_ACTION,
            GET_PATHWAY_MARKETPLACE_PLAN_MIGRATIONS_ACTION
        ),
    }));
    const ichraClassPlanMigrations = migrations.filter((pmpm) => hasValue(pmpm.ichraClass));
    const pathwayPlanMigrations = migrations.filter((pmpm) => hasValue(pmpm.pathway));

    const ichraClassPlanMigrationsContent = ichraClassPlanMigrations.map((x, index) => (
        <PathwayMarketplacePlanMigrationInputs
            index={index}
            key={`ichraClassPlanMigration-${x.ichraClass?.id}`}
            {...x}
        />
    ));

    const pathwayPlanMigrationsContent = pathwayPlanMigrations.map((x, index) => (
        <PathwayMarketplacePlanMigrationInputs
            index={index}
            key={`pathwayPlanMigration-${x.pathway?.id}`}
            {...x}
        />
    ));

    return (
        <Stack>
            <Skeleton
                isEnabled={isLoading}
                structure={[
                    [
                        {
                            columnProps: { flex: 1 },
                            count: 2,
                            height: 260,
                            sx: { mb: 3, mt: 2 },
                            width: '100%',
                        },
                    ],
                ]}
            >
                {arrayHasValue(ichraClassPlanMigrationsContent) && (
                    <React.Fragment>
                        <Divider sx={{ my: 2 }} />
                        <Typography sx={{ mb: 0 }} variant="h4">
                            ICHRA Classes
                        </Typography>
                        {ichraClassPlanMigrationsContent}
                    </React.Fragment>
                )}
                {arrayHasValue(pathwayPlanMigrationsContent) && (
                    <React.Fragment>
                        <Divider sx={{ mb: 2, mt: 4 }} />
                        <Typography sx={{ mb: 0 }} variant="h4">
                            Pathways
                        </Typography>
                        {pathwayPlanMigrationsContent}
                    </React.Fragment>
                )}
            </Skeleton>
        </Stack>
    );
};

export default hot(module)(PathwayMigrationsContent);
