import { AttachMoney } from '@mui/icons-material';
import { Chip, Stack } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { IMajorMedicalBenefitCostDto } from 'api/generated/models';
import IchraMediShareMyCostTooltip from 'components/IchraMediShareMyCostTooltip';
import InformationIconTooltip from 'components/InformationIconTooltip';
import MajorMedicalBenefitCostButton from 'components/majorMedicalBenefitCostBreakdownModal/MajorMedicalBenefitCostButton';
import ReimbursementMyCostTooltip from 'components/ReimbursementMyCostTooltip';
import { HEADER_FONT_COLOR } from 'constants/styleConstants';
import useUserProps from 'hooks/useUserProps';
import HsaEligibleChip from 'pages/shop/HsaEligibleChip';
import { getDataCyPathwayPlanPrefix } from 'pages/shop/PlanCardBase';
import { IShoppingPlan } from 'pages/shop/shopping';
import React from 'react';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { getCanShopForMember, getReimbursement } from 'selectors/shopping';
import styled from 'styled-components';
import { hasValue } from 'utilities';
import { formatCurrency } from 'utilities/format';
import { isIchraPathwayType } from 'utilities/pathways';
import { getCostLabel } from 'utilities/selectedPlan';

const getWasText = (isInIchraPathway: boolean, cost?: number, costWithCredit?: number) => {
    if (!isInIchraPathway && cost !== costWithCredit) {
        return (
            <SmallText as={Row} className="justify-content-end px-2">
                <SmallText className="text-muted mr-1">Was:</SmallText>
                <s className="text-danger">${cost} / mo</s>
            </SmallText>
        );
    }
    return '';
};

export type IPlanCardHeaderProps = {
    carrier?: string;
    cost?: number;
    costWithCredit?: number;
    dataCyPrefix?: string;
    hidePlanCost?: boolean;
    hidePreviouslySelected?: boolean;
    hideYourCost?: boolean;
    isChunkPlan?: boolean;
    isFlowSummary?: boolean;
    majorMedicalBenefitCostDto?: IMajorMedicalBenefitCostDto | null;
    name?: string;
    plan: Partial<IShoppingPlan>;
    reimbursementAmount?: number;
    showTaxSavings?: boolean;
};
const PlanCardHeader = ({
    carrier,
    cost,
    costWithCredit,
    dataCyPrefix,
    hidePlanCost = false,
    hidePreviouslySelected = false,
    hideYourCost,
    isChunkPlan,
    isFlowSummary,
    name,
    plan,
    reimbursementAmount,
    showTaxSavings = false,
}: IPlanCardHeaderProps) => {
    const { isCurrent } = useUserProps();
    const { canShopForMember, pathwayUser, reimbursement } = useSelector((state: AppStore) => ({
        canShopForMember: getCanShopForMember(state),
        pathwayUser: state.pathwayUser,
        reimbursement: getReimbursement(state, isCurrent),
    }));
    const isInIchraPathway = isIchraPathwayType(pathwayUser?.pathwayType);
    let formattedPlanCost = formatCurrency(costWithCredit, { includeDollarSign: false });
    let yourCost = Math.round(
        Number(plan?.majorMedicalBenefitCost?.preTaxTotalMajorMedicalBenefitCost)
    );
    let myCostContent = (
        <MajorMedicalBenefitCostButton majorMedicalBenefitCost={plan?.majorMedicalBenefitCost}>
            Your Cost
        </MajorMedicalBenefitCostButton>
    );
    if (isChunkPlan || isInIchraPathway) {
        formattedPlanCost = formatCurrency(cost, {
            includeDollarSign: false,
            preserveDecimal: true,
        });
        if (plan?.isMediShare) {
            myCostContent = (
                <React.Fragment>
                    <span>Your Cost</span>
                    <IchraMediShareMyCostTooltip planCost={cost} />
                </React.Fragment>
            );
        } else {
            yourCost = isFlowSummary
                ? (plan?.majorMedicalBenefitCost?.postTaxTotalPlanCost as number)
                : plan?.majorMedicalBenefitCost?.ichraMajorMedicalBenefitCost ?? 0;
            myCostContent = (
                <React.Fragment>
                    <span>{isFlowSummary ? 'Plan Cost' : 'Your Cost'}</span>
                    {!isFlowSummary && (
                        <ReimbursementMyCostTooltip
                            memberCost={yourCost}
                            planCost={cost}
                            reimbursement={reimbursementAmount ?? reimbursement}
                        />
                    )}
                </React.Fragment>
            );
        }
    }
    const was = getWasText(isInIchraPathway, cost, costWithCredit);

    const taxSavingsTooltipText =
        'This is an Off-Exchange plan. If you enroll in this plan, you may be eligible to pay for it entirely with tax-free dollars.';
    const taxSavings = (
        <Stack direction="row" fontSize="small" spacing={0.5}>
            <AttachMoney fontSize="small" />
            <span>Potential Tax Savings</span>
            &nbsp;
            <InformationIconTooltip title={taxSavingsTooltipText} variant="secondary" />
        </Stack>
    );

    return (
        <Grid
            alignItems="center"
            className="text-center"
            container
            flexWrap={{ md: 'nowrap', xs: 'wrap' }}
            justifyContent="space-between"
            spacing={2}
        >
            <Grid className="text-left" item md xs={12}>
                {hasValue(carrier) && <SmallText>{carrier}</SmallText>}
                <Typography color={HEADER_FONT_COLOR} marginTop={0} variant="h3">
                    {name}{' '}
                    {isChunkPlan && !hidePreviouslySelected && (
                        <Chip
                            color="default"
                            label={<span className="font-italic">Previously Selected</span>}
                            size="medium"
                        />
                    )}
                </Typography>
                <Grid container item spacing={0.5} xs={3}>
                    {showTaxSavings && (
                        <Grid item xs={12}>
                            <Chip color="secondary" label={taxSavings} size="small" />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <HsaEligibleChip isHsaEligible={plan?.isHsaEligible ?? false} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid alignItems="center" container item justifyContent="flex-end" md="auto" xs={12}>
                {canShopForMember && !hidePlanCost && (
                    <div className="text-center mx-4 mb-1">
                        {was}
                        <SmallText
                            as={Row}
                            className="justify-content-end px-2"
                            data-cy={`${getDataCyPathwayPlanPrefix(dataCyPrefix)}plan-cost`}
                        >
                            <SmallText className="text-muted mr-1">
                                {getCostLabel(plan?.isMediShare)}:
                            </SmallText>
                            <BlackText>${formattedPlanCost} / mo</BlackText>
                        </SmallText>
                    </div>
                )}
                {!hideYourCost && (
                    <div
                        className="text-center"
                        data-cy={`your-${getDataCyPathwayPlanPrefix(dataCyPrefix)}cost`}
                    >
                        {!isFlowSummary ? (
                            <React.Fragment>
                                <MyCostText>{myCostContent}</MyCostText>
                                <MyCostAmount>
                                    <DollarText>$</DollarText>
                                    <span>
                                        {isInIchraPathway
                                            ? formatCurrency(yourCost, {
                                                  includeDollarSign: false,
                                                  preserveDecimal: true,
                                              })
                                            : yourCost}
                                    </span>
                                    <SmallText>/ mo</SmallText>
                                </MyCostAmount>
                            </React.Fragment>
                        ) : (
                            <DollarText>
                                {myCostContent}: <b>${yourCost}/ mo</b>
                            </DollarText>
                        )}
                    </div>
                )}
            </Grid>
        </Grid>
    );
};

const BlackText = styled.span`
    color: black;
`;

const DollarText = styled.sup`
    font-size: 16px;
`;

const SmallText = styled.span`
    font-size: 12px;
`;

const MyCostText = styled.div.attrs(() => ({
    className: 'm-0 p-0',
}))`
    font-weight: 600;
`;
const MyCostAmount = styled.p.attrs(() => ({
    className: 'm-0 p-0 text-primary',
}))`
    font-size: 30px;
    font-weight: 600;
`;

export default hot(module)(PlanCardHeader);
