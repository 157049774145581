import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import { clearAccountSnapshot } from 'actions/clear';
import {
    GET_ACCOUNT_SNAPSHOT_ACTION,
    getAccountSnapshot,
} from 'actions/payment/getAccountSnapshot';
import { setupInPayments } from 'actions/payment/setupInPayments';
import PaymentsApi from 'api/generated/PaymentsApi';
import ProfileAttribute from 'components/ProfileAttribute';
import Skeleton from 'components/Skeleton';
import Typography from 'components/Typography';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import 'pages/payments/DebitCardInfo.css';
import PaymentsHeader from 'pages/payments/PaymentsHeader';
import React, { useCallback, useEffect, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { formatCurrency } from 'utilities/format';
import { hasValue } from 'utilities/index';

// https://www.marqeta.com/docs/developer-guides/using-marqeta-js
const marqetaConfig = (textColor: string) => ({
    component: {
        showPan: {
            cardCvv: {
                domId: 'mq-card-cvv',
                styles: {
                    span: {
                        color: textColor,
                        'font-family': 'Arial',
                    },
                },
            },
            cardExp: {
                domId: 'mq-card-exp',
                format: true,
                styles: {
                    span: {
                        color: textColor,
                        'font-family': 'Arial',
                    },
                },
            },
            cardPan: {
                domId: 'mq-card-pan',
                format: false,
                styles: {
                    span: {
                        color: textColor,
                        'font-family': 'Arial',
                    },
                },
            },
        },
    },
});

const PaymentsPage = () => {
    const dispatch = useThunkDispatch();
    const { userId } = useUserProps();
    const {
        isLoading,
        paymentsInfo: { accountSnapshotDto, canSetupInPayments },
    } = useSelector((state: AppStore) => ({
        isLoading: hasApiActivity(state, GET_ACCOUNT_SNAPSHOT_ACTION),
        paymentsInfo: state.paymentsInfo,
    }));
    const theme = useTheme();
    const [isCardInfoShown, setIsCardInfoShown] = useState(false);
    const onClick = async () => {
        setIsCardInfoShown(true);
        const response = await new PaymentsApi().getMarqetaToken(userId);
        const token = response.data;
        window.marqeta.bootstrap({
            ...marqetaConfig(theme.palette.text.primary),
            clientAccessToken: token,
        });
    };

    useEffect(() => {
        dispatch(getAccountSnapshot(userId));
        return () => {
            dispatch(clearAccountSnapshot());
        };
    }, [dispatch, userId]);

    const onSetupInPaymentsClick = useCallback(async () => {
        await dispatch(setupInPayments(userId));
    }, [dispatch, userId]);

    const shouldHideButton = isCardInfoShown || !hasValue(accountSnapshotDto?.debitLastFour);

    let content = (
        <React.Fragment>
            <ProfileAttribute label="Account Application Status">
                {accountSnapshotDto?.accountApplicationStatus}
            </ProfileAttribute>
            <Stack alignContent="center" direction="row" justifyContent="space-between" ml={2}>
                <Typography color={theme.palette.muted.main} fontWeight="bold" variant="subtitle2">
                    Debit Card Number
                </Typography>
                {hasValue(accountSnapshotDto?.debitLastFour) ? (
                    <div data-private id="mq-card-pan">
                        {!isCardInfoShown && `XXXX XXXX XXXX ${accountSnapshotDto?.debitLastFour}`}
                    </div>
                ) : (
                    <div>No Card Issued</div>
                )}
            </Stack>
            <hr />
            <Stack alignContent="center" direction="row" justifyContent="space-between" ml={2}>
                <Typography color={theme.palette.muted.main} fontWeight="bold" variant="subtitle2">
                    Exp
                </Typography>
                <div data-private id="mq-card-exp">
                    {!isCardInfoShown && 'XX/XX'}
                </div>
            </Stack>
            <hr />
            <Stack alignContent="center" direction="row" justifyContent="space-between" ml={2}>
                <Typography color={theme.palette.muted.main} fontWeight="bold" variant="subtitle2">
                    CVV
                </Typography>
                <div data-private id="mq-card-cvv">
                    {!isCardInfoShown && 'XXX'}
                </div>
            </Stack>
            <hr />
            <ProfileAttribute data-private label="Account Number">
                {hasValue(accountSnapshotDto?.accountNumber)
                    ? accountSnapshotDto?.accountNumber
                    : 'No Account'}
            </ProfileAttribute>
            <ProfileAttribute label="Routing Number">
                {accountSnapshotDto?.routingNumber}
            </ProfileAttribute>
            <ProfileAttribute label="Current Balance">
                {formatCurrency(accountSnapshotDto?.currentBalance, {
                    emptyIfNaN: true,
                    includeDollarSign: true,
                    preserveDecimal: true,
                })}
            </ProfileAttribute>
            <ProfileAttribute label="Available Balance">
                {formatCurrency(accountSnapshotDto?.availableBalance, {
                    emptyIfNaN: true,
                    includeDollarSign: true,
                    preserveDecimal: true,
                })}
            </ProfileAttribute>
        </React.Fragment>
    );

    if (!hasValue(accountSnapshotDto) && canSetupInPayments) {
        content = <p>Use the action button to setup this member in Payments</p>;
    }

    if (!hasValue(accountSnapshotDto) && !canSetupInPayments) {
        content = (
            <p>
                This member does not meet the criteria to be setup in Payments. In order to setup a
                member in Payments, the member must have a User Status of Active, Member Verified
                Info and SSN, Valid Home Address (PO Box addresses are not accepted), and an
                Enrolled or Effective Plan
            </p>
        );
    }

    let button = (
        <Button
            onClick={onClick}
            sx={{ visibility: shouldHideButton ? 'hidden' : 'visible' }}
            variant="outlined"
        >
            Show Card Numbers
        </Button>
    );

    if (canSetupInPayments) {
        button = (
            <Button onClick={onSetupInPaymentsClick} variant="outlined">
                Setup In Payments
            </Button>
        );
    }

    return (
        <Paper>
            {isLoading ? (
                <React.Fragment>
                    <Skeleton />
                    <hr />
                    <Skeleton count={5} />
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <PaymentsHeader button={button} />
                    <hr />
                    {content}
                </React.Fragment>
            )}
        </Paper>
    );
};

export default hot(module)(PaymentsPage);

type Marqeta = {
    bootstrap: (config: unknown) => void;
};

declare global {
    // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
    interface Window {
        marqeta: Marqeta;
    }
}
