import { Card, CardContent } from '@mui/material';
import { GET_PATHWAY_BLUEPRINT_DATA_FOR_USER_ACTION } from 'actions/pathwayBlueprint/getPathwayBlueprintDataForUser';
import { LIST_ANCILLARY_BENEFITS_ACTION } from 'actions/teamBenefit/listAncillaryBenefits';
import { PathwayTypes, TaskItems, UserStatus } from 'api/generated/enums';
import Skeleton from 'components/Skeleton';
import { push } from 'connected-react-router';
import useModalState from 'hooks/useModalState';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import { completedTasksSelector } from 'pages/dashboard/dashboardSelectors';
import BenefitSelectionModal from 'pages/dashboard/medicalBenefitSelection/BenefitSelectionModal';
import TaskListItem from 'pages/dashboard/taskLists/TaskListItem';
import React from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { SHOP_PATH } from 'routers/routes';
import { hasApiActivity } from 'selectors/activity';

const BenefitSelectionContent = () => {
    const dispatch = useThunkDispatch();
    const { user, yearlyUserInfo } = useUserProps();
    const { isMedicalBenefitSelectionComplete, shoppingOverride, isLoading } = useSelector(
        (state: AppStore) => {
            const isFlatValue = ([PathwayTypes.Flat, PathwayTypes.IchraFlat] as (
                | PathwayTypes
                | undefined
            )[]).includes(state.pathwayUser?.pathwayType);
            const isRenewing = user?.status === UserStatus.Renewing;
            return {
                isFlat: isFlatValue,
                isLoading: hasApiActivity(
                    state,
                    GET_PATHWAY_BLUEPRINT_DATA_FOR_USER_ACTION,
                    LIST_ANCILLARY_BENEFITS_ACTION
                ),
                isMedicalBenefitSelectionComplete: completedTasksSelector(state).some((x) =>
                    isRenewing
                        ? x.taskItemId === TaskItems.BenefitSelection_Renewing
                        : x.taskItemId === TaskItems.BenefitSelection
                ),
                pathwayUser: state.pathwayUser,
                shoppingOverride: isFlatValue && yearlyUserInfo?.allowShopping,
            };
        }
    );

    const {
        closeModal: closeBenefitSelectionModal,
        isVisible: isBenefitSelectionModalVisible,
        openModal: openBenefitSelectionModal,
    } = useModalState();

    const action = () =>
        shoppingOverride ? dispatch(push(SHOP_PATH)) : openBenefitSelectionModal();

    let majorMedicalButtonText = 'Start';
    const title = 'Select Major Medical Benefits';
    if (shoppingOverride) {
        majorMedicalButtonText = 'Shop';
    } else if (isMedicalBenefitSelectionComplete) {
        majorMedicalButtonText = 'View Pathway';
    }
    return (
        <Skeleton count={1} height={100} isEnabled={isLoading}>
            <Card>
                <CardContent>
                    <TaskListItem
                        action={action}
                        buttonText={majorMedicalButtonText}
                        complete={isMedicalBenefitSelectionComplete}
                        data-cy="medical-benefit-selection-task"
                        description="Select your major medical benefits for the year."
                        enabled
                        hideTopSeparator
                        noPadding
                        showButton
                        showButtonWhenComplete
                        title={title}
                    >
                        {isBenefitSelectionModalVisible && (
                            <BenefitSelectionModal onClose={closeBenefitSelectionModal} />
                        )}
                    </TaskListItem>
                </CardContent>
            </Card>
        </Skeleton>
    );
};

export default hot(module)(BenefitSelectionContent);
