import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { GET_BENEFITS_ACTION, getBenefits } from 'actions/benefits/getBenefits';
import { getHouseholdMembers } from 'actions/householdMember/getHouseholdMembers';
import { GET_USER_PROFILE_ACTION } from 'actions/user/getUserProfile';
import { PlanStateIds } from 'api/generated/enums';
import { CreateSelectedPlans, EditUser, ViewTeamBenefit } from 'api/generated/permissions';
import PageHeader from 'components/PageHeader';
import PageSectionWrapper from 'components/PageSectionWrapper';
import PlanModal from 'components/planModal/PlanModal';
import RemovePlanModal from 'components/RemovePlanModal';
import Select from 'components/Select';
import Skeleton from 'components/Skeleton';
import TableHeader from 'components/TableHeader';
import TeamManagementWrapper from 'components/TeamManagementWrapper';
import TeamManagementContext from 'contexts/TeamManagementContext';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import { hidePlanModal, hideRemovePlanModal, showPlanModal } from 'pages/benefits/benefitsActions';
import { hasSomeSelectedPlansWithState } from 'pages/benefits/benefitsSelectors';
import IndividualAncillaryBenefits from 'pages/benefits/IndividualAncillaryBenefits';
import MajorMedicalBenefits from 'pages/benefits/MajorMedicalBenefits';
import MemberTeamBenefits from 'pages/benefits/MemberTeamBenefits';
import { setSelectedYear } from 'pages/profile/profileActions';
import React, { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { generatePath, Link, RouteComponentProps } from 'react-router-dom';
import { AppStore } from 'reducers/appReducer';
import { PEOPLE_EDIT_PATH, PROFILE_EDIT_PATH, TEAMS_PEOPLE_EDIT_PATH } from 'routers/routes';
import { hasSomePermissions, IUserProps } from 'selectors';
import { hasApiActivity } from 'selectors/activity';
import { hasContents, hasValue } from 'utilities';
import { isBasicInfoStatus } from 'utilities/people';
import { getYears } from 'utilities/year';

const SkeletonWrapper = ({ children }: React.PropsWithChildren<unknown>) => (
    <Grid item p={1} sm={6} xs={12}>
        {children}
    </Grid>
);

export const BenefitsPageMessage = ({ children }: { children: ReactNode }) => (
    <Grid item xs={12}>
        <Card className="mt-3 text-center">
            <CardContent>
                <Typography variant="h3">{children}</Typography>
            </CardContent>
        </Card>
    </Grid>
);

const IsBasicMessage = ({ editQuoteInfoPath }: { editQuoteInfoPath: string }) => (
    <BenefitsPageMessage>
        Census information is required to add plans for a member.{' '}
        <Link to={editQuoteInfoPath}>Enter additional information.</Link>
    </BenefitsPageMessage>
);

const NoBenefitsMessage = ({ selectedYear }: { selectedYear: string }) => (
    <BenefitsPageMessage>
        You do not have any individual benefits yet with Remodel Health for {selectedYear}. Once you
        have selected benefits for {selectedYear}, you will be able to view them here!
    </BenefitsPageMessage>
);

const benefitsPageSelector = (
    _match: RouteComponentProps['match'],
    { hasUser, userProfile }: IUserProps
) => (state: AppStore) => {
    const props = {
        canAddMemberPlans: hasSomePermissions(state, CreateSelectedPlans),
        canViewTeamBenefits: hasSomePermissions(state, ViewTeamBenefit),
        hasInProgressPlans: hasSomeSelectedPlansWithState(state, PlanStateIds.Submitted),
        isBasicMessageVisible: false,
        isLoading: hasApiActivity(state, GET_USER_PROFILE_ACTION, GET_BENEFITS_ACTION),
        isPlanModalVisible: state.benefitsState.isPlanModalVisible,
        isRemovePlanModalVisible: state.benefitsState.isRemovePlanModalVisible,
        memberBenefits: state.memberBenefits,
        selectedYear: state.profileState.selectedYear,
        targetBenefit: state.benefitsState.targetBenefit,
    };

    if (hasUser) {
        props.isBasicMessageVisible =
            isBasicInfoStatus(userProfile) &&
            props.canAddMemberPlans &&
            state.login.up.includes(EditUser);
    }

    return props;
};

const BenefitsPage = ({ match }: RouteComponentProps) => {
    const dispatch = useThunkDispatch();
    const userProps = useUserProps();
    const { isTeamManagementPage } = useContext(TeamManagementContext);
    const { teamId } = useTeamProps();
    const { hasUser, hasUserIdUrlParam, isCurrent, userId } = userProps;
    const {
        canAddMemberPlans,
        canViewTeamBenefits,
        hasInProgressPlans,
        isLoading,
        isPlanModalVisible,
        isRemovePlanModalVisible,
        memberBenefits,
        selectedYear,
        isBasicMessageVisible,
        targetBenefit,
    } = useSelector(benefitsPageSelector(match, userProps));
    const [hasCompletedRequests, setHasCompletedRequests] = useState(false);
    useEffect(() => {
        if (!hasValue(selectedYear) && hasUser && hasValue(memberBenefits.year)) {
            dispatch(setSelectedYear(memberBenefits.year.toString()));
        }
    }, [dispatch, hasUser, memberBenefits.year, selectedYear]);
    useEffect(() => {
        const getData = async () => {
            const hhmRequest = dispatch(getHouseholdMembers(userId, undefined, true));
            let year: number | undefined;
            if (hasValue(selectedYear)) {
                year = Number(selectedYear);
            }
            const memberBenefitsRequest = dispatch(getBenefits(userId, year));
            await Promise.all([hhmRequest, memberBenefitsRequest]);
            setHasCompletedRequests(true);
        };
        getData();
    }, [dispatch, selectedYear, userId]);
    const items = useMemo(
        () => [
            {
                dataCy: 'add-plan',
                isVisible: canAddMemberPlans,
                onClick: () => dispatch(showPlanModal()),
                text: 'Add Plan',
            },
        ],
        [canAddMemberPlans, dispatch]
    );
    const editQuoteInfoPath = useMemo(() => {
        if (isTeamManagementPage) {
            return generatePath(TEAMS_PEOPLE_EDIT_PATH, { teamId, userId });
        } else if (hasUserIdUrlParam) {
            return generatePath(PEOPLE_EDIT_PATH, { teamId, userId });
        }
        return PROFILE_EDIT_PATH;
    }, [hasUserIdUrlParam, isTeamManagementPage, teamId, userId]);
    const notBasicContent = hasContents(memberBenefits.selectedPlans) ? (
        <React.Fragment>
            <MajorMedicalBenefits />
            <IndividualAncillaryBenefits />
        </React.Fragment>
    ) : (
        <Grid alignContent="center" className="mb-4" container item xs={12}>
            <NoBenefitsMessage selectedYear={selectedYear} />
        </Grid>
    );
    const onSelectedYearChange = useCallback(
        ({ target: { value } }) => {
            dispatch(setSelectedYear(value));
            dispatch(getBenefits(userId, value));
        },
        [dispatch, userId]
    );
    const years = useMemo(() => getYears(() => 1).map((x) => ({ name: `${x}` })), []);
    const totalBenefits =
        Number(memberBenefits.selectedPlans?.length ?? 0) +
        Number(memberBenefits.userTeamBenefitTermDetails?.length ?? 0);
    const label = useMemo(
        () => `Showing ${totalBenefits} Benefit${totalBenefits !== 1 ? 's' : ''}`,
        [totalBenefits]
    );
    const onSubmit = async () => dispatch(getBenefits(userId, Number(selectedYear)));

    const benefitsContent = (
        <React.Fragment>
            <PageSectionWrapper>
                <Grid container direction="column">
                    {isCurrent && (
                        <PageHeader ml={2} variant="h4">
                            My Benefits
                        </PageHeader>
                    )}
                    <TableHeader disableDropdown={isLoading} items={items}>
                        <Skeleton height={56} isEnabled={isLoading} width={85}>
                            <Select
                                data-cy="selected-benefits-page-year"
                                items={years}
                                name="selectedYear"
                                onChange={onSelectedYearChange}
                                optionText="name"
                                optionValue="name"
                                sx={{ ml: { sm: 2 } }}
                                value={selectedYear}
                            />
                        </Skeleton>
                        <Skeleton isEnabled={isLoading} width={400}>
                            <Grid item>
                                <Typography noWrap>{label}</Typography>
                            </Grid>
                        </Skeleton>
                    </TableHeader>
                </Grid>
            </PageSectionWrapper>
            <Grid className="px-3" container>
                <Skeleton
                    count={4}
                    height="450px"
                    isEnabled={isLoading || !hasCompletedRequests || !hasUser}
                    Wrapper={SkeletonWrapper}
                >
                    {hasInProgressPlans && !isTeamManagementPage && (
                        <Grid item xs={12}>
                            <Alert variant="success">
                                <Typography variant="h4">Submitted for Enrollment!</Typography>
                                <p>We will send you an email once you&apos;ve been enrolled.</p>
                            </Alert>
                        </Grid>
                    )}
                    {isBasicMessageVisible ? (
                        <Grid alignContent="center" className="mb-4" container item xs={12}>
                            <IsBasicMessage editQuoteInfoPath={editQuoteInfoPath} />
                        </Grid>
                    ) : (
                        notBasicContent
                    )}
                    {(isCurrent || canViewTeamBenefits) && <MemberTeamBenefits />}
                </Skeleton>
            </Grid>
        </React.Fragment>
    );

    return (
        <React.Fragment>
            {isRemovePlanModalVisible && (
                <RemovePlanModal
                    onClose={() => dispatch(hideRemovePlanModal())}
                    onSubmit={onSubmit}
                    plan={targetBenefit}
                    userId={userId}
                />
            )}
            {isPlanModalVisible && (
                <PlanModal
                    onClose={() => dispatch(hidePlanModal())}
                    onSubmit={onSubmit}
                    planToEdit={targetBenefit}
                    userId={userId}
                />
            )}
            <TeamManagementWrapper isTeamManageMentPage={isTeamManagementPage}>
                {benefitsContent}
            </TeamManagementWrapper>
        </React.Fragment>
    );
};

export default hot(module)(BenefitsPage);
