import { ChangeTypes } from 'api/generated/enums';
import { ISelectedPlanComparison } from 'api/generated/models';
import Icon from 'components/Icon';
import React from 'react';
import { hot } from 'react-hot-loader';
import { stringToFloatAbs } from 'utilities';
import { formatCurrency } from 'utilities/format';

export const UpArrow = () => (
    <Icon prefix="mdi" variant="danger">
        menu-up
    </Icon>
);
export const DownArrow = () => (
    <Icon prefix="mdi" variant="success">
        menu-down
    </Icon>
);
export const RightArrow = () => (
    <Icon prefix="mdi" variant="info">
        menu-right
    </Icon>
);

const Comparison = ({
    comparison: { changeType, difference, name },
}: {
    comparison: ISelectedPlanComparison;
}) => {
    switch (changeType) {
        case ChangeTypes.Bad:
            return (
                <span>
                    <UpArrow /> {name} has increased by{' '}
                    {formatCurrency(stringToFloatAbs(difference))}
                </span>
            );
        case ChangeTypes.Good:
            return (
                <span>
                    <DownArrow /> {name} has decreased by{' '}
                    {formatCurrency(stringToFloatAbs(difference))}
                </span>
            );
        case ChangeTypes.Neutral:
            return (
                <span>
                    <RightArrow /> {name} has changed {difference}
                </span>
            );
        default:
            return <React.Fragment></React.Fragment>;
    }
};

export default hot(module)(Comparison);
