import { CheckCircleOutline, HelpOutline } from '@mui/icons-material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    CircularProgress,
    Stack,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import { listProvidersForHousehold } from 'actions/provider/listProvidersForHousehold';
import {
    GET_PROVIDERS_IN_PLAN_NETWORK_ACTION,
    getIdeonProvidersInPlanNetwork,
} from 'actions/providerSearch/getProvidersInPlanNetwork';
import { ProviderSource, UserStatus } from 'api/generated/enums';
import { IMarketplacePlanDto, IProvider, IProviderPlan } from 'api/generated/models';
import Tooltip from 'components/Tooltip';
import { getIdeonProviderName } from 'components/creatableAutocomplete/useIdeonProviderAutocomplete';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import { ShoppingContext } from 'pages/shop/shopPageUtilities';
import { IShoppingPlan } from 'pages/shop/shopping';
import React, { useContext, useEffect } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { AppStore } from 'reducers/appReducer';
import { SHOP_PATH } from 'routers/routes';
import { hasApiActivity } from 'selectors/activity';
import { arrayHasValue, hasValue } from 'utilities/index';

const PROVIDER_DISCLAIMER_TEXT =
    'Due to the ever-changing nature of healthcare we cannot guarantee the above provider will or will not be covered during your period of coverage.';
const PROVIDER_COVERAGE_NOT_DETERMINED_TEXT =
    'We were unable to determine the coverage status of this provider. Please contact their office directly to confirm if this plan is in-network.';

const providerStatusLoading = (
    <Tooltip title="This can take a while...">
        <CircularProgress size="20px" />
    </Tooltip>
);

const providerToolTip = (showIcon: boolean, providerName?: string) => {
    const icon = showIcon ? (
        <CheckCircleOutline color="success" />
    ) : (
        <HelpOutline color="disabled" />
    );
    return (
        <Tooltip
            title={
                <div>
                    <Typography fontSize="x-small">
                        <React.Fragment>
                            {providerName}: {showIcon ? 'Covered' : ' Coverage Status Unknown'}
                        </React.Fragment>
                    </Typography>
                    <Typography fontSize="x-small" mt={1}>
                        {showIcon ? (
                            <React.Fragment>
                                <strong>Disclaimer</strong>
                                {` - ${PROVIDER_DISCLAIMER_TEXT}`}
                            </React.Fragment>
                        ) : (
                            PROVIDER_COVERAGE_NOT_DETERMINED_TEXT
                        )}
                    </Typography>
                </div>
            }
        >
            {icon}
        </Tooltip>
    );
};

const PlanCardProviders = ({
    isIchraFlow,
    plan,
}: {
    isIchraFlow?: boolean;
    plan: Partial<IShoppingPlan>;
    providers?: IProvider[];
}) => {
    const dispatch = useThunkDispatch();
    const { userId, memberVerifiedInfo, user } = useUserProps();
    const {
        hhProviders,
        ideonProviderPlans,
        isLoading,
        providersInPlanNetwork,
        selectedShoppingProviders,
    } = useSelector((state: AppStore) => ({
        hhProviders: state.householdProviders,
        ideonProviderPlans: state.ideonProviderPlans,
        isLoading: hasApiActivity(state, GET_PROVIDERS_IN_PLAN_NETWORK_ACTION),
        providersInPlanNetwork: state.ideonProvidersInPlanNetworks.find(
            (x) => x.planId === ((plan as IMarketplacePlanDto).id as string)
        ),
        selectedShoppingProviders: state.shoppingState.selectedProviders,
        selectedYear: state.shoppingState.selectedYear,
    }));
    const { isProvidersExpanded, setIsProvidersExpanded } = useContext(ShoppingContext);
    const location = useLocation();
    const isShopping = location.pathname.includes(SHOP_PATH);
    const hasIchraFlowProviders =
        isIchraFlow && (arrayHasValue(selectedShoppingProviders) || arrayHasValue(hhProviders));
    const hasShoppingProviders = isShopping && arrayHasValue(selectedShoppingProviders);
    const hasHhProviders = !isShopping && arrayHasValue(hhProviders);
    const hasSomeProviders = hasIchraFlowProviders || hasShoppingProviders || hasHhProviders;
    const marketplacePlan = plan as IMarketplacePlanDto;
    useEffect(() => {
        if (!isShopping && !isIchraFlow) {
            dispatch(listProvidersForHousehold(userId, memberVerifiedInfo?.year));
        }
    }, [dispatch, isIchraFlow, isShopping, memberVerifiedInfo?.year, userId]);

    const providerIds: string[] = [];
    const filteredProviders = hhProviders.filter(
        (element, index, providers) =>
            index ===
            providers.findIndex(
                (provider) => provider.nationalProviderId === element.nationalProviderId
            )
    );
    const providerContent = filteredProviders.map((provider) => {
        const key = provider.providerId;
        if (!providerIds.contains(provider.providerId)) {
            providerIds.push(provider.providerId);
            const hasNpi = hasValue(provider.nationalProviderId);
            const isInNetwork = hasValue(
                providersInPlanNetwork?.providers?.find(
                    (inNetworkProvider) =>
                        inNetworkProvider.id.toString() === provider.nationalProviderId
                )
            );
            const showIcon = isInNetwork && hasNpi;
            return (
                <Stack alignItems="center" direction="row" key={key} marginY={1}>
                    {isLoading ? providerStatusLoading : providerToolTip(showIcon, provider.name)}

                    <Typography fontWeight="bold" ml={1} variant="body2">
                        {provider.name}
                    </Typography>
                </Stack>
            );
        }
        return <React.Fragment key={key} />;
    });

    const shoppingProviderContent = selectedShoppingProviders.map((provider, index) => {
        const isCustomProvider = provider.providerSource === ProviderSource.User;
        let providerPlans: IProviderPlan[] = [];
        let ideonProviderHasError = false;
        let isLoadingPlans = false;
        if (!isCustomProvider && hasValue(provider.id)) {
            providerPlans = ideonProviderPlans[provider.id]?.providerPlans ?? [];
            ideonProviderHasError = ideonProviderPlans[provider.id]?.hasError ?? false;
            isLoadingPlans = !hasValue(ideonProviderPlans[provider.id]?.providerPlans);
        }
        const isInNetwork = hasValue(
            providerPlans?.find((x: IProviderPlan) => x.id === marketplacePlan.id)
        );
        const showIcon = isInNetwork && !ideonProviderHasError;

        return (
            <Stack
                alignItems="center"
                direction="row"
                key={provider.id?.toString() ?? 'custom' + index.toString()}
                marginY={1}
            >
                {isLoadingPlans
                    ? providerStatusLoading
                    : providerToolTip(showIcon && !isCustomProvider, provider.presentationName)}
                <Typography fontWeight="bold" ml={1} variant="body2">
                    {getIdeonProviderName(provider)}
                </Typography>
            </Stack>
        );
    });

    const handleProviderToggle = () => {
        setIsProvidersExpanded?.(!isProvidersExpanded);
        if (!hasValue(providersInPlanNetwork) && !hasShoppingProviders) {
            dispatch(
                getIdeonProvidersInPlanNetwork(
                    (plan as IMarketplacePlanDto).id,
                    memberVerifiedInfo?.year,
                    hhProviders
                        .filter((p) => hasValue(p.nationalProviderId))
                        .map((p) => Number(p.nationalProviderId as string))
                )
            );
        }
    };

    const shouldShow = isIchraFlow || isShopping || user?.status !== UserStatus.Renewing;
    const providerElements = isShopping || isIchraFlow ? shoppingProviderContent : providerContent;
    return hasSomeProviders && shouldShow ? (
        <Accordion
            expanded={isProvidersExpanded}
            onClick={handleProviderToggle}
            sx={{
                border: '1px solid #e3eaef',
                borderRadius: '0.25rem',
                boxShadow: 'none',
                marginTop: 1,
                maxWidth: '20rem',
            }}
        >
            <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography fontWeight="bold" variant="body2">
                    Provider Coverage
                </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ borderTop: '1px solid #e3eaef' }}>
                {providerElements}
            </AccordionDetails>
        </Accordion>
    ) : (
        <React.Fragment />
    );
};

export default hot(module)(PlanCardProviders);
