import { TaskItems, UserStatus } from 'api/generated/enums';
import { taskListTypes } from 'constants/tasks';
import useUserProps from 'hooks/useUserProps';
import KeepOrChangeBenefitsModal from 'pages/dashboard/taskLists/member/tasks/keepOrChange/KeepOrChangeBenefitsModal';
import TaskListItem from 'pages/dashboard/taskLists/TaskListItem';
import React, { useCallback, useState } from 'react';
import { hot } from 'react-hot-loader';

type IKeepOrChangeBenefitsTaskProps = {
    complete: boolean | undefined;
    enabled: boolean | undefined;
};

// TODO CH11008: Original status
// [
//     UserStatus.InvitedToRenew,
//     UserStatus.Keeping,
//     UserStatus.ReadyToShop,
//     UserStatus.Shopping,
//     UserStatus.ReadyForReview,
// ]
const USER_STATUSES_ALLOWED_TO_CHANGE = [UserStatus.Renewing];
const KeepOrChangeBenefitsTask = ({ complete, enabled }: IKeepOrChangeBenefitsTaskProps) => {
    const [showKeepOrChangeModal, setShowKeepOrChangeModal] = useState(false);
    const { user } = useUserProps();
    const isInUserStatuses = USER_STATUSES_ALLOWED_TO_CHANGE.contains(user?.status);
    const closeKeepOrChangeModal = useCallback(() => setShowKeepOrChangeModal(false), []);
    const openKeepOrChangeModal = useCallback(() => setShowKeepOrChangeModal(true), []);
    return (
        <TaskListItem
            action={openKeepOrChangeModal}
            buttonText="Decide"
            complete={complete}
            data-cy="keep-change-benefits-task"
            description="Choose to keep or change your benefits."
            enabled={enabled}
            showButton={enabled && isInUserStatuses}
            showButtonWhenComplete
            taskId={TaskItems.KeepOrChangeBenefits_Renewing}
            taskType={taskListTypes.User}
            title="Select Major Medical Benefits"
        >
            {showKeepOrChangeModal && <KeepOrChangeBenefitsModal onHide={closeKeepOrChangeModal} />}
        </TaskListItem>
    );
};

export default hot(module)(KeepOrChangeBenefitsTask);
