import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Typography } from '@mui/material';
import { PlanTypeIds } from 'api/generated/enums';
import { IComparedSelectedPlan, ISelectedPlan } from 'api/generated/models';
import InformationIconTooltip from 'components/InformationIconTooltip';
import useUserProps from 'hooks/useUserProps';
import { getAreMarketplaceRatesAvailable } from 'pages/dashboard/taskLists/member/tasks/keepOrChange/keepOrChangeSelectors';
import { LabelValue } from 'pages/shop/PlanCard';
import React from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { formatCurrency } from 'utilities/format';
import { isIchraPathwayType } from 'utilities/pathways';
import { getCostLabel, isMediShare } from 'utilities/selectedPlan';

const PLAN_COST_INFO_TOOLTIP =
    "These rates are quotes from the marketplace and aren't guaranteed to be your final premium; your final premium could be higher or lower";

const KeepOrChangeBenefitCardCost = ({
    selectedPlan,
}: {
    selectedPlan: IComparedSelectedPlan | ISelectedPlan;
}) => {
    const { planTypeId, planPremiumWithCredits } = selectedPlan;
    const { year } = useUserProps();
    const { areMarketplaceRatesAvailable, isIchraPathway } = useSelector((state: AppStore) => ({
        areMarketplaceRatesAvailable: getAreMarketplaceRatesAvailable(state, year),
        isIchraPathway: isIchraPathwayType(state.pathwayUser?.pathwayType),
    }));
    if (planPremiumWithCredits) {
        const isMarketplace = planTypeId === PlanTypeIds.Marketplace;
        const isMediSharePlanType = isMediShare(selectedPlan);
        const label = [PlanTypeIds.Ancillary, PlanTypeIds.CustomAncillary].includes(planTypeId)
            ? 'Estimated Cost'
            : `Listed ${getCostLabel(isMediSharePlanType)}`;
        return (
            <LabelValue
                label={
                    <React.Fragment>
                        {label}{' '}
                        {isMarketplace && areMarketplaceRatesAvailable && (
                            <InformationIconTooltip title={PLAN_COST_INFO_TOOLTIP} />
                        )}
                    </React.Fragment>
                }
            >
                <Typography fontSize="small" fontWeight="bold">
                    <React.Fragment>
                        {formatCurrency(planPremiumWithCredits, {
                            preserveDecimal: isIchraPathway,
                        })}
                        /mo
                    </React.Fragment>
                </Typography>
                {isMarketplace && !areMarketplaceRatesAvailable && (
                    <HelpOutlineIcon color="secondary" />
                )}
            </LabelValue>
        );
    }
    return <React.Fragment />;
};

export default hot(module)(KeepOrChangeBenefitCardCost);
