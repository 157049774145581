import { Grid, Typography } from '@mui/material';
import { LIST_VISIBLE_FLOWS_ACTION } from 'actions/taskFlows/listVisibleFlows';
import { GET_HOUSEHOLD_ACTION } from 'actions/user/getHousehold';
import { GET_USER_PROFILE_ACTION } from 'actions/user/getUserProfile';
import { GroupLevels } from 'api/generated/enums';
import Skeleton from 'components/Skeleton';
import { groupLevelNames } from 'constants/groupLevels';
import { useRhEmail } from 'hooks/useRhEmail';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import { setIchraFlowHasErrors } from 'pages/dashboard/ichraTaskFlow/ichraFlowActions';
import {
    IIchraEnrollmentTaskFlowContent,
    MAX_WIDTH,
    ichraFlowSkeletonRowProps,
} from 'pages/dashboard/ichraTaskFlow/ichraFlowUtilities';
import IchraFlowButtons from 'pages/dashboard/ichraTaskFlow/taskFlowPages/components/IchraFlowButtons';
import IchraFlowHouseholdMemberSummary from 'pages/dashboard/ichraTaskFlow/taskFlowPages/components/IchraFlowHouseholdMemberSummary';
import IchraFlowTitle from 'pages/dashboard/ichraTaskFlow/taskFlowPages/components/IchraFlowTitle';
import React, { useEffect } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasCompletedRequests } from 'selectors/activity';
import { formatCurrency } from 'utilities/format';
import { hasValue } from 'utilities/index';

const ReimbursementSummary = ({
    activeReimbursement,
    coverageLevel,
}: {
    activeReimbursement: number;
    coverageLevel: GroupLevels;
}) => (
    <React.Fragment>
        <Typography>
            Based on the info you provided and who you are seeking coverage for, here&apos;s the
            maximum monthly reimbursement amount your employer is offering you:
        </Typography>
        <Typography marginX="auto" variant="h4">
            {formatCurrency(activeReimbursement, { preserveDecimal: true })}/mo
        </Typography>
        <Typography marginBottom={0} marginX="auto" textAlign="center" variant="h5">
            Household: {groupLevelNames[Number(coverageLevel)]}
            <IchraFlowHouseholdMemberSummary />
        </Typography>
        <Typography marginTop={2}>
            This can be used to pay for health insurance plan premiums. In the next step,
            you&apos;ll explore your benefits options and what your plan costs would look like with
            the reimbursement amount you have available.
        </Typography>
    </React.Fragment>
);

const CustomReimbursementSummary = () => (
    <React.Fragment>
        <Typography>
            Due to unique circumstances with your household information, we&apos;d like to connect
            you with one of our licensed advisors to review your reimbursement amount and plan
            options.
        </Typography>
        <Typography>
            Please click &quot;Continue&quot;, and you&apos;ll be able to schedule time with an
            advisor in the next step.
        </Typography>
    </React.Fragment>
);

const YourReimbursementAmountPage = ({ currentTask }: IIchraEnrollmentTaskFlowContent) => {
    const dispatch = useThunkDispatch();
    const { flowHasErrors, hasFinishedLoading } = useSelector((state: AppStore) => ({
        flowHasErrors: state.ichraFlowState.hasErrors,
        hasFinishedLoading: hasCompletedRequests(
            state,
            GET_USER_PROFILE_ACTION,
            LIST_VISIBLE_FLOWS_ACTION,
            GET_HOUSEHOLD_ACTION
        ),
    }));
    const { activeWageUp, yearlyUserInfo } = useUserProps();
    const activeReimbursement = activeWageUp?.reimbursement;
    const hasCustomReimbursement = activeWageUp?.isReimbursementCustom;
    const coverageLevel = yearlyUserInfo?.majorMedicalCoverageLevel;
    const reimbursementContent = hasCustomReimbursement ? (
        <CustomReimbursementSummary />
    ) : (
        <ReimbursementSummary
            activeReimbursement={activeReimbursement as number}
            coverageLevel={coverageLevel as GroupLevels}
        ></ReimbursementSummary>
    );
    const emailAddress = useRhEmail();

    const errorContent = (
        <React.Fragment>
            <Typography marginX="auto">
                There was an error getting your reimbursement amount.
            </Typography>
            <Typography marginX="auto">
                To resolve this, please reach out to{' '}
                <a href={`mailto:${emailAddress}`}>{emailAddress}</a>.
            </Typography>
        </React.Fragment>
    );

    useEffect(() => {
        const hasErrors =
            hasFinishedLoading && (!hasValue(activeReimbursement) || !hasValue(coverageLevel));

        dispatch(setIchraFlowHasErrors(hasErrors));
    }, [activeReimbursement, coverageLevel, dispatch, hasFinishedLoading]);

    return (
        <Grid container direction="column" gap={3} maxWidth={MAX_WIDTH}>
            <IchraFlowTitle title="Your Reimbursement Amount" />
            <Skeleton
                isEnabled={!hasFinishedLoading}
                rowProps={ichraFlowSkeletonRowProps}
                structure={[
                    [
                        {
                            columnProps: { flex: 1 },
                            count: 1,
                            height: 40,
                            sx: { mb: 3 },
                            width: '100%',
                        },
                    ],
                    [
                        {
                            columnProps: { alignItems: 'center', flex: 1 },
                            count: 1,
                            height: 150,
                            sx: { mb: 3 },
                            width: 250,
                        },
                    ],
                    [
                        {
                            columnProps: { flex: 1 },
                            count: 1,
                            height: 40,
                            sx: { mb: 1 },
                            width: '100%',
                        },
                    ],
                ]}
            >
                {flowHasErrors ? errorContent : reimbursementContent}
            </Skeleton>
            <IchraFlowButtons
                currentTaskId={currentTask.globalId}
                hideContinueButton={flowHasErrors}
                isLoading={!hasFinishedLoading}
            />
        </Grid>
    );
};

export default hot(module)(YourReimbursementAmountPage);
