import Grid from '@mui/material/Grid';
import { PlanTypeIds } from 'api/generated/enums';
import MajorMedicalBenefitCost from 'components/majorMedicalBenefitCostBreakdownModal/MajorMedicalBenefitCost';
import orderBy from 'lodash/orderBy';
import BenefitCards from 'pages/benefits/BenefitCards';
import BenefitSectionHeader from 'pages/benefits/BenefitSectionHeader';
import React from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasValue } from 'utilities';

const MajorMedicalBenefits = () => {
    const { selectedPlans, memberBenefits } = useSelector((state: AppStore) => ({
        memberBenefits: state.memberBenefits,
        selectedPlans: orderBy(state.memberBenefits.selectedPlans, [
            'planStateId',
            'planTypeId',
            'coverageStartDate',
        ]).filter(
            (x) => ![PlanTypeIds.Ancillary, PlanTypeIds.CustomAncillary].contains(x.planTypeId)
        ),
    }));

    const shouldDisplayTotalMmbCost =
        hasValue(memberBenefits.majorMedicalBenefitCost?.postTaxTotalPlanCost) &&
        (hasValue(memberBenefits.majorMedicalBenefitCost?.reimbursementAmount) ||
            hasValue(memberBenefits.majorMedicalBenefitCost?.preTaxWagePlus));

    if (selectedPlans.length) {
        return (
            <Grid container item xs={12}>
                <Grid item xs={12}>
                    <BenefitSectionHeader>
                        Major Medical Benefits
                        {shouldDisplayTotalMmbCost && <MajorMedicalBenefitCost />}
                    </BenefitSectionHeader>
                </Grid>
                <BenefitCards selectedPlans={selectedPlans} />
            </Grid>
        );
    }
    return <React.Fragment />;
};

export default hot(module)(MajorMedicalBenefits);
