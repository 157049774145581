import { PathwayTypes } from 'api/generated/enums';
import { IPathway, IPathwayRecommendedPlan } from 'api/generated/models';
import {
    CLEAR_PATHWAY_INPUTS_ACTION,
    SET_PATHWAY_INPUTS_ACTION,
    SET_PATHWAY_MODAL_PROPS_ACTION,
} from 'pages/pathwayBlueprint/pathwayModalActions';
import { IFormErrors } from 'utilities/forms';
import { hasValue } from 'utilities/index';
import {
    getIsPathwayOfType,
    isCappedCostIchraPathwayType,
    isExchangeBenchmarkPathwayType,
    isHealthcareSharingPathwayType,
    isIchraExchangeBenchmarkPathwayType,
    requiresCoverageLevelInputs,
    requiresPlan,
} from 'utilities/pathways';
import { array, object, string } from 'yup';

export const DISPLAY_NAME_OVERRIDE_TEXT_MAX_LENGTH = 255;
export const EXTRA_PATHWAY_BENEFIT_TEXT_MAX_LENGTH = 1000;
const MAX_PATHWAY_NAME_LENGTH = 255;
const MAX_PATHWAY_DESCRIPTION_LENGTH = 500;
const isRequiredWhenPathwayTypeIsParentOrSpouse = [
    'pathwayType',
    {
        is: (x: string) => getIsPathwayOfType(x, PathwayTypes.Spouse, PathwayTypes.Parent),
        then: string().required(),
    },
] as const;
const isRequiredWhenPathwayTypeHasExchangeBenchmark = [
    'pathwayType',
    {
        is: isExchangeBenchmarkPathwayType,
        then: string().required(),
    },
] as const;
const isRequiredWhenPathwayTypeRequiresCoverageLevelInputs = [
    'pathwayType',
    {
        is: requiresCoverageLevelInputs,
        then: string().required(),
    },
] as const;
const isRequiredWhenPathwayTypeIsMedicare = [
    'pathwayType',
    {
        is: (x: string) => getIsPathwayOfType(x, PathwayTypes.Medicare, PathwayTypes.IchraMedicare),
        then: string().required(),
    },
] as const;
const isRequiredWhenPathwayTypeIsIchraAgeBanded = [
    ['pathwayType', 'ichraClassId'] as string[],
    {
        is: (x: string, y: string) =>
            getIsPathwayOfType(x, PathwayTypes.IchraAgeBanded) && !hasValue(y),
        then: string().required(),
    },
] as const;
const isRequiredWhenPathwayTypeIsCappedIchra = [
    'pathwayType',
    {
        is: isCappedCostIchraPathwayType,
        then: string().required(),
    },
] as const;
const isRequiredWhenPlanIsRequired = [
    'pathwayType',
    {
        is: requiresPlan,
        then: string().required(),
    },
] as const;
const isRequiredWhenPathwayTypeIsIchraExchange = [
    'pathwayType',
    {
        is: isIchraExchangeBenchmarkPathwayType,
        then: string().required(),
    },
] as const;

export const schema = object({
    additionalDependentKicker: string().label('Additional Dependent Kicker'),
    ageBandSize: string()
        .when(...isRequiredWhenPathwayTypeIsIchraAgeBanded)
        .label('Age Bands'),
    ahpOption: string()
        .when('pathwayType', {
            is: isHealthcareSharingPathwayType,
            then: string().required(),
        })
        .label('Benchmark Program'),
    automaticApprovalCap: string()
        .when(...isRequiredWhenPathwayTypeIsParentOrSpouse)
        .label('Automatic Approval Cap'),
    capType: string()
        .when(...isRequiredWhenPlanIsRequired)
        .label('Cap Type'),
    chipEstimate: string().label('Chip Estimate'),
    county: string()
        .trim()
        .when(...isRequiredWhenPathwayTypeHasExchangeBenchmark)
        .label('County'),
    dependentKickerThreshold: string()
        .when(...isRequiredWhenPathwayTypeIsIchraAgeBanded)
        .label('Dependent Kicker Threshold'),
    displayNameOverride: string()
        .trim()
        .max(DISPLAY_NAME_OVERRIDE_TEXT_MAX_LENGTH)
        .label('Display Name Override'),
    ec: string()
        .when(...isRequiredWhenPathwayTypeRequiresCoverageLevelInputs)
        .label('EC'),
    ee: string()
        .when(...isRequiredWhenPathwayTypeRequiresCoverageLevelInputs)
        .label('EE'),
    ef: string()
        .when(...isRequiredWhenPathwayTypeRequiresCoverageLevelInputs)
        .label('EF'),
    employerCoversEeOnly: string()
        .when('pathwayType', {
            is: (x: string) => isCappedCostIchraPathwayType(x),
            then: string().required(),
        })
        .label('Employer Covers EE Only'),
    es: string()
        .when(...isRequiredWhenPathwayTypeRequiresCoverageLevelInputs)
        .label('ES'),
    extraBenefitText: string()
        .trim()
        .max(EXTRA_PATHWAY_BENEFIT_TEXT_MAX_LENGTH)
        .label('Extra Pathway Benefit Text'),
    ichraBenchmarkType: string()
        .when(...isRequiredWhenPathwayTypeIsCappedIchra)
        .label('ICHRA Benchmark Type'),
    ichraClassId: string().label('ICHRA Class'),
    marketplaceCarrier: string()
        .when(...isRequiredWhenPathwayTypeHasExchangeBenchmark)
        .label('Benchmark Carrier'),
    marketplacePlanId: string()
        .when(...isRequiredWhenPathwayTypeHasExchangeBenchmark)
        .label('Benchmark Plan'),
    medicaidEstimate: string()
        .when('pathwayType', {
            is: (x: string) => getIsPathwayOfType(x, PathwayTypes.Medicaid),
            then: string().required(),
        })
        .label('Medicaid Cost Estimate'),
    pathwayName: string()
        .trim()
        .required()
        .max(MAX_PATHWAY_NAME_LENGTH)
        .label('Pathway Name'),
    pathwayRecommendedPlans: array().of(
        object({
            benchmarkType: string()
                .when(...isRequiredWhenPathwayTypeIsIchraExchange)
                .label('Recommended Plan Type'),
            description: string().max(MAX_PATHWAY_DESCRIPTION_LENGTH),
            issuerName: string()
                .when(...isRequiredWhenPathwayTypeIsIchraExchange)
                .label('Recommended Plan Carrier'),
            order: string(),
            planId: string()
                .when(...isRequiredWhenPathwayTypeIsIchraExchange)
                .label('Recommended Plan'),
            planLabel: string(),
        })
    ),
    pathwayType: string()
        .required()
        .label('Pathway Type'),
    perMemberMedicareCostEstimate: string()
        .when(...isRequiredWhenPathwayTypeIsMedicare)
        .when(...isRequiredWhenPathwayTypeIsIchraAgeBanded)
        .label('Per Member Medicare Estimate'),
    sixtyFourEc: string()
        .when(...isRequiredWhenPathwayTypeIsIchraAgeBanded)
        .label('64+ EC'),
    sixtyFourEe: string()
        .when(...isRequiredWhenPathwayTypeIsIchraAgeBanded)
        .label('64+ EE'),
    sixtyFourEf: string()
        .when(...isRequiredWhenPathwayTypeIsIchraAgeBanded)
        .label('64+ EF'),
    sixtyFourEs: string()
        .when(...isRequiredWhenPathwayTypeIsIchraAgeBanded)
        .label('64+ ES'),
    startingWageUp: string()
        .when(...isRequiredWhenPathwayTypeIsParentOrSpouse)
        .label('Starting Wage+'),
    useRestrategizedRenewalFlow: string()
        .when(...isRequiredWhenPlanIsRequired)
        .label('Use Restrategized Renewal Flow'),
    wageUpCap: string().label('Wage+ Cap'),
    waivedContribution: string()
        .when('pathwayType', {
            is: (x: string) => getIsPathwayOfType(x, PathwayTypes.Waived),
            then: string().required(),
        })
        .label('Waived Contribution'),
    zipCode: string()
        .trim()
        .when(...isRequiredWhenPathwayTypeHasExchangeBenchmark)
        .label('Zip Code'),
});

export type IPathwayModalState = {
    isModalVisible?: boolean;
    pathwayInputs?: {
        county: string;
        errors?: IFormErrors<typeof schema>;
        ichraBenchmarkType: string;
        marketplaceCarrier: string;
        marketplacePlanId: string;
        pathwayRecommendedPlans?: IPathwayRecommendedPlan[];
        zipCode: string;
    };
    selectedItem?: IPathway;
};
const initialState: IPathwayModalState = {
    isModalVisible: false,
    pathwayInputs: {
        county: '',
        errors: null,
        ichraBenchmarkType: '',
        marketplaceCarrier: '',
        marketplacePlanId: '',
        pathwayRecommendedPlans: undefined,
        zipCode: '',
    },
    selectedItem: undefined,
};

export const pathwayModalState = (
    state = initialState,
    action: { data: IPathwayModalState | IPathwayModalState['pathwayInputs']; type: string }
): IPathwayModalState => {
    if (action.type === SET_PATHWAY_MODAL_PROPS_ACTION) {
        return {
            ...state,
            ...action.data,
        };
    }
    if (action.type === SET_PATHWAY_INPUTS_ACTION) {
        return {
            ...state,
            pathwayInputs: ({
                ...state.pathwayInputs,
                ...action.data,
            } as unknown) as IPathwayModalState['pathwayInputs'],
        };
    }
    if (action.type === CLEAR_PATHWAY_INPUTS_ACTION) {
        return {
            ...state,
            pathwayInputs: ({
                ...initialState.pathwayInputs,
            } as unknown) as IPathwayModalState['pathwayInputs'],
        };
    }

    return state;
};
