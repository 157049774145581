import { Divider, Grid } from '@mui/material';
import { SEARCH_PLANS_ACTION } from 'actions/marketplacePlan/searchMarketplacePlans';
import { IPathwayRecommendedPlan } from 'api/generated/models';
import ConditionalTooltip from 'components/ConditionalTooltip';
import Select from 'components/Select';
import Skeleton from 'components/Skeleton';
import TextArea from 'components/TextArea';
import Tooltip from 'components/Tooltip';
import Typography from 'components/Typography';
import useThunkDispatch from 'hooks/useThunkDispatch';
import { PLAN_LISTING_ITEMS } from 'pages/pathwayBlueprint/MarketplacePathwayInputs';
import { setPathwayInputs } from 'pages/pathwayBlueprint/pathwayModalActions';
import { schema } from 'pages/pathwayBlueprint/pathwayModalState';
import React, { useEffect, useMemo, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { uniqueMarketplaceCarriersSelector } from 'selectors/marketplace';
import { arrayHasValue, hasValue } from 'utilities';
import { IFormErrors } from 'utilities/forms';
import { getPlansByCarrier } from 'utilities/marketplacePlan';

type IPathwayInputs = {
    county: string;
    errors?: IFormErrors<typeof schema>;
    ichraBenchmarkType: string;
    marketplaceCarrier: string;
    marketplacePlanId: string;
    pathwayRecommendedPlans?: IPathwayRecommendedPlan[];
    zipCode: string;
};

const IchraRecommendedPlanInputs = () => {
    const dispatch = useThunkDispatch();
    const { carriers, isLoadingMarketplacePlans, marketplacePlans, pathwayInputs } = useSelector(
        (state: AppStore) => ({
            carriers: uniqueMarketplaceCarriersSelector(state),
            isLoadingMarketplacePlans: hasApiActivity(state, SEARCH_PLANS_ACTION),
            marketplacePlans: state.marketplacePlans,
            pathwayInputs: state.pathwayModalState.pathwayInputs,
        })
    );
    const {
        county,
        errors,
        ichraBenchmarkType,
        marketplaceCarrier,
        marketplacePlanId,
        pathwayRecommendedPlans,
        zipCode,
    } = pathwayInputs ?? {};

    const [recommendedPlanInput, setRecommendedPlanInput] = useState<
        Partial<IPathwayInputs> | undefined
    >(pathwayInputs);

    const hasDataToSearchPlans =
        hasValue(zipCode) && hasValue(county) && hasValue(ichraBenchmarkType);

    // TODO 20092: Remove this logic once we complete the chore to convert carrier names with carrier ids
    const getCarrierId = (carrierName: string, isBenchmark: boolean, benchmarkType?: string) => {
        // TODO: Will remove with 22139
        console.log(isBenchmark);
        console.log(benchmarkType);

        return carriers.find((c) => c.name === carrierName)?.id;
    };

    const plansByCarrier = useMemo(() => getPlansByCarrier(marketplacePlans, marketplaceCarrier), [
        marketplaceCarrier,
        marketplacePlans,
    ]);

    const handleChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        index: number
    ) => {
        const updatedPathwayInputs = {
            ...pathwayInputs,
            pathwayRecommendedPlans: pathwayInputs?.pathwayRecommendedPlans?.map((plan, i) =>
                i === index ? { ...plan, [event.target.name]: event.target.value } : plan
            ),
        };

        setRecommendedPlanInput(updatedPathwayInputs);
    };

    const handleBlur = () => {
        dispatch(setPathwayInputs(recommendedPlanInput));
    };

    useEffect(() => {
        // Update Benchmark Recommended Plan
        const planDetails = arrayHasValue(plansByCarrier)
            ? plansByCarrier.find((pbc) => pbc.id === marketplacePlanId)
            : pathwayRecommendedPlans?.[0]?.planDetails ?? null;

        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const updatedPlan = {
            ...pathwayRecommendedPlans?.[0],
            planDetails,
            benchmarkType: ichraBenchmarkType,
            carrierId: getCarrierId(marketplaceCarrier as string, true),
            order: 1,
            planId: marketplacePlanId,
            planName: planDetails?.name,
        } as IPathwayRecommendedPlan;

        const updatedPlans = arrayHasValue(pathwayRecommendedPlans)
            ? [updatedPlan, ...pathwayRecommendedPlans.slice(1)]
            : [updatedPlan];

        setRecommendedPlanInput({ ...recommendedPlanInput, pathwayRecommendedPlans: updatedPlans });
        dispatch(setPathwayInputs({ pathwayRecommendedPlans: updatedPlans }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ichraBenchmarkType, marketplaceCarrier, marketplacePlanId, carriers]);

    return (
        <Grid columnSpacing={2} container rowGap={2}>
            <Grid item width="100%">
                <Divider />
            </Grid>
            <Grid container direction="row" item xs={12}>
                <Typography variant="h4">Recommended Plans</Typography>
            </Grid>
            {recommendedPlanInput?.pathwayRecommendedPlans?.map((plan, index) => (
                <React.Fragment key={plan.order}>
                    <Grid container direction="row" item xs={12}>
                        <Typography variant="h5">
                            Recommended Plan{' '}
                            {arrayHasValue(recommendedPlanInput?.pathwayRecommendedPlans) &&
                            recommendedPlanInput?.pathwayRecommendedPlans?.length > 1
                                ? plan.order
                                : ''}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <ConditionalTooltip
                            isDisabled={index > 0}
                            placement="bottom-end"
                            title="This is always the benchmark type. To update this, update the benchmark type for this pathway"
                        >
                            <Select
                                data-cy="ichra-benchmark-type"
                                defaultText="Choose an ICHRA Type"
                                defaultValue=""
                                disabled={plan.order === 1}
                                errors={errors?.ichraBenchmarkType}
                                items={PLAN_LISTING_ITEMS}
                                label="Recommended Plan Type"
                                name="ichraBenchmarkType"
                                onBlur={handleBlur}
                                onChange={(e) => handleChange(e, index)}
                                optionText="name"
                                optionValue="value"
                                value={plan.benchmarkType}
                            />
                        </ConditionalTooltip>
                    </Grid>
                    <Grid item xs={12}>
                        <Skeleton
                            count={2}
                            height="56px"
                            isEnabled={isLoadingMarketplacePlans || !hasDataToSearchPlans}
                            structure={[
                                [
                                    {
                                        columnProps: { flex: 1, gap: 2 },
                                        count: 2,
                                        height: 56,
                                    },
                                ],
                            ]}
                        >
                            <Grid container item rowGap={2} xs={12}>
                                <Grid item xs={12}>
                                    <ConditionalTooltip
                                        isDisabled={index > 0}
                                        placement="bottom-end"
                                        title="This is always the benchmark carrier. To update this, update the benchmark carrier for this pathway"
                                    >
                                        <Select
                                            data-cy="recommended-carrier"
                                            defaultText="Choose a Carrier"
                                            defaultValue=""
                                            disabled={plan.order === 1}
                                            errors={errors?.marketplaceCarrier}
                                            items={carriers}
                                            label="Recommended Plan Carrier"
                                            name="marketplaceCarrier"
                                            onBlur={handleBlur}
                                            onChange={(e) => handleChange(e, index)}
                                            optionText="name"
                                            optionValue="id"
                                            value={plan.carrierId}
                                        />
                                    </ConditionalTooltip>
                                </Grid>
                                <Grid item xs={12}>
                                    <ConditionalTooltip
                                        isDisabled={index > 0}
                                        placement="bottom-end"
                                        title="This is always the benchmark plan. To update this, update the benchmark plan for this pathway"
                                    >
                                        <Select
                                            data-cy="recommended-plan"
                                            defaultText="Choose a Plan"
                                            defaultValue=""
                                            disabled={plan.order === 1}
                                            errors={errors?.marketplacePlanId}
                                            items={plansByCarrier}
                                            label="Recommended Plan"
                                            name="marketplacePlanId"
                                            onBlur={handleBlur}
                                            onChange={(e) => handleChange(e, index)}
                                            optionText="name"
                                            optionValue="id"
                                            value={plan.planId}
                                        />
                                    </ConditionalTooltip>
                                </Grid>
                                <Grid item xs={12}>
                                    <Tooltip
                                        placement="bottom-end"
                                        title="This will be displayed above the plan as a way to add a short name that differentiates this option relative to the other recommended plans."
                                    >
                                        <TextArea
                                            data-cy="recommended-plan-label"
                                            isOptional
                                            label="Recommended Plan Label"
                                            name="planLabel"
                                            onBlur={handleBlur}
                                            onChange={(e) => handleChange(e, index)}
                                            value={plan.planLabel}
                                        />
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={12}>
                                    <Tooltip
                                        placement="bottom-end"
                                        title="This description will be displayed in a tooltip that the member can hover over next to the Recommended Plan Label"
                                    >
                                        <TextArea
                                            data-cy="recommended-plan-description"
                                            isOptional
                                            label="Recommended Plan Description"
                                            maxLength={500}
                                            minRows={3}
                                            name="description"
                                            onBlur={handleBlur}
                                            onChange={(e) => handleChange(e, index)}
                                            value={plan.description}
                                        />
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </Skeleton>
                    </Grid>
                </React.Fragment>
            ))}
        </Grid>
    );
};

export default hot(module)(IchraRecommendedPlanInputs);
