import { Divider, Grid, Stack, Typography } from '@mui/material';
import { completeTask } from 'actions/taskFlows/completeTask';
import { LIST_VISIBLE_FLOWS_ACTION } from 'actions/taskFlows/listVisibleFlows';
import { listAncillaryBenefits } from 'actions/teamBenefit/listAncillaryBenefits';
import { getHousehold } from 'actions/user/getHousehold';
import { getHouseholdEligibility } from 'actions/user/getHouseholdEligibility';
import { GET_USER_PROFILE_ACTION } from 'actions/user/getUserProfile';
import { EnrollmentStatuses, Tasks, UserTeamBenefitTermDetailStatuses } from 'api/generated/enums';
import {
    IAncillaryBenefitDto,
    IMarketplacePlanDto,
    IPlanInfoDto,
    ISelectedPlansDto,
    SelectedPlansDto,
    TaskDto,
} from 'api/generated/models';
import Skeleton from 'components/Skeleton';
import { RH_TEAM_CARE_EMAIL } from 'constants/teams';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import { ICHRA_LAUNCH_FLOW_INDEX } from 'pages/dashboard/ichraTaskFlow/IchraEnrollmentTaskList';
import {
    IIchraEnrollmentTaskFlowContent,
    MAX_WIDTH,
    getPreviousTask,
    handleAncillaryValues,
} from 'pages/dashboard/ichraTaskFlow/ichraFlowUtilities';
import IchraFlowButtons from 'pages/dashboard/ichraTaskFlow/taskFlowPages/components/IchraFlowButtons';
import IchraFlowTitle from 'pages/dashboard/ichraTaskFlow/taskFlowPages/components/IchraFlowTitle';
import AcceptedAncillary from 'pages/dashboard/ichraTaskFlow/taskFlowPages/submitBenefitsSelectionPage/AcceptedAncillary';
import MajorMedicalCostBreakdown from 'pages/dashboard/ichraTaskFlow/taskFlowPages/submitBenefitsSelectionPage/MajorMedicalCostBreakdown';
import UnacceptedAncillaries from 'pages/dashboard/ichraTaskFlow/taskFlowPages/submitBenefitsSelectionPage/UnacceptedAncillary';
import MarketplacePlanCard from 'pages/shop/MarketplacePlanCard';
import PlanCardHeader from 'pages/shop/PlanCardHeader';
import { IShoppingPlan } from 'pages/shop/shopping';
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { DASHBOARD_PATH } from 'routers/routes';
import { hasApiActivity } from 'selectors/activity';
import { arrayHasValue, hasValue } from 'utilities/index';

const SubmitBenefitSelectionPage = ({ currentTask }: IIchraEnrollmentTaskFlowContent) => {
    const dispatch = useThunkDispatch();
    const { isCurrent, user, userId, yearlyUserInfo } = useUserProps();
    const { ancillariesFromState, flowHasErrors, isLoading, userFlows } = useSelector(
        (state: AppStore) => ({
            ancillariesFromState: state.ancillaryBenefits,
            flowHasErrors: state.ichraFlowState.hasErrors,
            isLoading: hasApiActivity(state, LIST_VISIBLE_FLOWS_ACTION, GET_USER_PROFILE_ACTION),
            userFlows: state.userFlows,
        })
    );
    const [acceptedAncillaries, setAcceptedAncillaries] = useState<IAncillaryBenefitDto[]>();
    const [unacceptedAncillaries, setUnacceptedAncillaries] = useState<IAncillaryBenefitDto[]>();
    const [majorMedicalPlan, setMajorMedicalPlan] = useState<IMarketplacePlanDto>();

    const ichraTasks = userFlows[ICHRA_LAUNCH_FLOW_INDEX]?.tasks as TaskDto[];
    const chooseYourPlanTask = getPreviousTask(
        ichraTasks,
        Tasks.ChooseYourPlan,
        Tasks.SelectBenefits
    );
    let planInfoDto: IPlanInfoDto | undefined;
    const selectedPlansDto = chooseYourPlanTask?.response as SelectedPlansDto;
    const planInfoDtos = selectedPlansDto.planInfoDtos;
    if (hasValue(planInfoDtos)) {
        planInfoDto = planInfoDtos[0];
    }
    const ancillaryBenefitTask = getPreviousTask(
        ichraTasks,
        Tasks.SelectAncillaryBenefits,
        Tasks.SelectBenefits
    );
    const isWaived = (chooseYourPlanTask?.response as ISelectedPlansDto).isWaived;
    const toastSuccessMessage = 'Benefits selections submitted!';
    const toastErrorMessage = 'Failed to submit benefits selections';

    const header = (
        <PlanCardHeader
            carrier={majorMedicalPlan?.issuer?.name}
            cost={majorMedicalPlan?.majorMedicalBenefitCost?.postTaxTotalPlanCost}
            hidePlanCost
            hidePreviouslySelected
            isChunkPlan
            isFlowSummary
            name={majorMedicalPlan?.name}
            plan={majorMedicalPlan as IMarketplacePlanDto}
            reimbursementAmount={majorMedicalPlan?.majorMedicalBenefitCost?.reimbursementAmount}
            showTaxSavings={!hasValue(majorMedicalPlan?.exchange)}
        />
    );

    const handleSubmit = () => {
        dispatch(
            completeTask(
                currentTask.globalId,
                undefined,
                DASHBOARD_PATH,
                toastErrorMessage,
                toastSuccessMessage
            )
        );
    };

    const activeDateYear = user?.activeDate?.getYear();
    const isAdvisementScheduled =
        yearlyUserInfo?.enrollmentStatus?.value === EnrollmentStatuses.AdvisementScheduled;

    useEffect(() => {
        dispatch(listAncillaryBenefits(userId, true));
    }, [dispatch, userId]);

    useEffect(() => {
        dispatch(getHousehold(userId, isCurrent));
    }, [dispatch, isCurrent, userId]);

    useEffect(() => {
        dispatch(getHouseholdEligibility(userId, user?.activeDate?.getYear() as number));
    }, [dispatch, user?.activeDate, userId]);

    useEffect(() => {
        setMajorMedicalPlan(planInfoDto?.plan);
    }, [planInfoDto?.plan]);

    useEffect(() => {
        const updatedAncillaries = handleAncillaryValues(
            ancillaryBenefitTask as TaskDto,
            ancillariesFromState,
            userId
        );
        const desiredAncillaries = updatedAncillaries.filter((da) =>
            [
                UserTeamBenefitTermDetailStatuses.Enrolled,
                UserTeamBenefitTermDetailStatuses.Submitted,
            ].contains(da.statusId)
        );
        const acceptedAncillaryTypes = desiredAncillaries.map((da) => da.teamBenefitTypeId);
        const undesiredAncillaries = updatedAncillaries.filter(
            (ua) =>
                ua.statusId === UserTeamBenefitTermDetailStatuses.Waived &&
                !acceptedAncillaryTypes.contains(ua.teamBenefitTypeId)
        );
        setAcceptedAncillaries(desiredAncillaries);
        setUnacceptedAncillaries(undesiredAncillaries);
    }, [ancillariesFromState, ancillaryBenefitTask, userId]);

    const individualOnly =
        planInfoDto?.householdMembersCovered?.length === (planInfoDto?.isPrimaryCovered ? 0 : 1);

    return (
        <Stack
            direction="column"
            maxWidth={{ lg: MAX_WIDTH, xs: '100%' }}
            minWidth={{ lg: '650px', xs: '100%' }}
            width="100%"
        >
            <IchraFlowTitle title="Submit Benefits Selections" />
            <Skeleton count={1} height={250} isEnabled={isLoading} width="100%">
                {!flowHasErrors ? (
                    <Grid container direction="column" item marginTop={2} rowSpacing={2} xs={12}>
                        <Grid item xs={12}>
                            <Typography variant="h4">ICHRA Major Medical Enrollment</Typography>
                        </Grid>
                        <Grid item>
                            {isWaived ? (
                                <Typography
                                    fontStyle="italic"
                                    fontWeight="400"
                                    marginBottom={4}
                                    variant="h4"
                                >
                                    Waiving ICHRA for {activeDateYear}
                                </Typography>
                            ) : (
                                <Grid
                                    container
                                    direction="column"
                                    gap={3}
                                    item
                                    marginBottom={2}
                                    width="100%"
                                >
                                    <MarketplacePlanCard
                                        hideSelectPlan
                                        householdMembersCovered={
                                            planInfoDto?.householdMembersCovered
                                        }
                                        individualOnly={individualOnly}
                                        isPrimaryCovered={planInfoDto?.isPrimaryCovered}
                                        pathwayPlanCardHeader={header}
                                        plan={
                                            {
                                                ...majorMedicalPlan,
                                                isMarketplace: true,
                                            } as IShoppingPlan
                                        }
                                    />
                                    <MajorMedicalCostBreakdown
                                        majorMedicalBenefitCost={
                                            majorMedicalPlan?.majorMedicalBenefitCost
                                        }
                                    />
                                </Grid>
                            )}
                        </Grid>
                        {arrayHasValue(ancillariesFromState) && (
                            <Grid container item rowSpacing={2} xs={12}>
                                <Grid item xs={12}>
                                    <Divider sx={{ marginBottom: 3 }} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h4">Ancillary Benefits</Typography>
                                </Grid>
                                <Grid columnSpacing={4} container item xs={12}>
                                    {acceptedAncillaries?.map((ancillary) => (
                                        <AcceptedAncillary
                                            ancillary={ancillary}
                                            key={ancillary.teamBenefitTypeId}
                                        />
                                    ))}
                                    {unacceptedAncillaries?.map((ancillary) => (
                                        <UnacceptedAncillaries
                                            ancillary={ancillary}
                                            key={ancillary.teamBenefitTypeId}
                                        />
                                    ))}
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                ) : (
                    <React.Fragment>
                        <Typography marginX="auto">
                            There was an error getting your ICHRA offer details.
                        </Typography>
                        <Typography marginX="auto">
                            To resolve this, please reach out to{' '}
                            <a href={`mailto:${RH_TEAM_CARE_EMAIL}`}>{RH_TEAM_CARE_EMAIL}</a>.
                        </Typography>
                    </React.Fragment>
                )}
            </Skeleton>
            {isAdvisementScheduled && (
                <Typography marginTop={2} marginX="auto" variant="body2">
                    You have an advisement scheduled, please wait to review your selections with
                    your advisor.
                </Typography>
            )}
            <IchraFlowButtons
                currentTaskId={currentTask.globalId}
                forwardButtonTextOverride="Submit"
                handleNext={handleSubmit}
                isDisabled={isAdvisementScheduled}
                isLoading={isLoading}
                showPreviousButton
            />
        </Stack>
    );
};

export default hot(module)(SubmitBenefitSelectionPage);
