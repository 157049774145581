import { Stack, Typography } from '@mui/material/';
import { editTaskStatus } from 'actions/taskFlows/editTaskStatus';
import { Flows, TaskStatuses } from 'api/generated/enums';
import useThunkDispatch from 'hooks/useThunkDispatch';
import {
    IIchraEnrollmentTaskFlowContent,
    MAX_WIDTH,
} from 'pages/dashboard/ichraTaskFlow/ichraFlowUtilities';
import IchraFlowButtons from 'pages/dashboard/ichraTaskFlow/taskFlowPages/components/IchraFlowButtons';
import IchraFlowTitle from 'pages/dashboard/ichraTaskFlow/taskFlowPages/components/IchraFlowTitle';
import React from 'react';
import { hot } from 'react-hot-loader';

const EnterHouseholdInfoPage = ({ currentFlow, currentTask }: IIchraEnrollmentTaskFlowContent) => {
    const dispatch = useThunkDispatch();
    const handleNext = async () => {
        await dispatch(editTaskStatus(currentTask.globalId, TaskStatuses.Acknowledged));
    };
    const isIchraLaunch = currentFlow?.flowId === Flows.IchraLaunch;
    const renderVerbiage = () =>
        isIchraLaunch ? (
            <React.Fragment>
                We need some more information about you and your household to determine your maximum
                reimbursement amount and show you plan options.
                <br />
                <br />
                If you or your employer have provided us with some of this information already,
                please confirm that it is correct and make changes if necessary.
            </React.Fragment>
        ) : (
            <React.Fragment>
                In this step of your open enrollment process, you will be asked to verify important
                household information for {currentFlow?.year}.
                <br />
                <br />
                Please pay special attention to ensure the following items are correct:
                <ul>
                    <li>
                        Address – This should be your current primary residence.{' '}
                        <b>P.O. box addresses are discouraged.</b>
                    </li>
                    <li>
                        Household Members – Please include all members of your household that will
                        need coverage for {currentFlow?.year}.
                    </li>
                </ul>
            </React.Fragment>
        );

    return (
        <Stack gap={3} maxWidth={MAX_WIDTH}>
            <IchraFlowTitle title={`${isIchraLaunch ? 'Enter' : 'Verify'} Household Info`} />
            <Typography marginX="auto">{renderVerbiage()}</Typography>
            <IchraFlowButtons currentTaskId={currentTask?.globalId ?? ''} handleNext={handleNext} />
        </Stack>
    );
};

export default hot(module)(EnterHouseholdInfoPage);
